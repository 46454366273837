import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useState } from "react";
import { tokens } from "../../Theme/theme";
import { useEffect } from "react";
import { Header } from "../global/Header";
import SendIcon from "@mui/icons-material/Send";
import '../../assets/fonts/fonts.css'
import {
  ArrowCircleRightRounded,
  RemoveRedEye,
  ReportOffSharp,
  ReportProblem,
  ViewAgendaSharp,
  ArrowRightAltRounded,
  SendRounded
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import BASE_URL from "../../NetworkConfig/ApiConfig";
const convertUTCToIST = (utcDateTime) => {
  // Define the time zones
  const utcTimeZone = "UTC";
  const istTimeZone = "Asia/Kolkata";

  // Convert UTC time to IST time zone
  const utcDate = new Date(utcDateTime);
  const istDate = utcToZonedTime(utcDate, istTimeZone);

  // Format the IST date and time
  const formattedIST = format(istDate, "yyyy-MM-dd HH:mm:ss");

  return formattedIST;
};
const ParticularUserSendChirp = (props) => {
  const { id } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Unmasked, setUnmasked] = useState(false);
  const [Reported, setReported] = useState(false);
  const [Chirps, setChirps] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, seterror] = useState("Loading.....");
  // const [loading, setloading] = useState(true);
  const FetchChirpDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/chirps/getaparticularusersendchirp/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setChirps(data.response);
      console.log(data);
      console.log(Chirps);
      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchChirpDetails();
  }, []);
  if (!Chirps) {
    return (
      <>
        <Box m={"20px"}>{error}</Box>
      </>
    );
  }
  const handleDateChange = (e) => {
    const { value } = e.target;
    setSelectedDate(value);
  };
  const HandleDateClick = () => {
    // setlocations(null);
    setChirps(null);
    FetchChirpDetails();
  };
  const HandleResetClick = () => {
    // setlocations(null);
    setChirps(null);
    setSelectedDate(null);
    FetchChirpDetails();
  };
  return (
    <>
      <Typography
        gridColumn="span 12"
        gridRow="span 2"
        style={{ fontFamily: 'poppins-reg', fontSize: "12px", marginTop: '5px', marginLeft: '5px', color: "#aaaaaa" }} >
        {Chirps.length} Chirps Sent
      </Typography>
      {Chirps.map((ChirpObject) => {
        return (
          <Box
            gridColumn="span 6"
            gridRow="span 2"
            alignItems="center"
            flexDirection={'column'}
            px="15px"
            py={"8px"}
            backgroundColor={"#ffffff05"}
            borderRadius={"10px"}
          >
            <Box
              display="flex"
              flexDirection={'row'}
              alignItems={'center'}
            >
              <Box
                component={Link}
                style={{ textDecoration: "none", width: "45px", height: "45px", display: 'flex', flexDirection: 'column' }}
                target="_blank"
                to={{
                  pathname: "/admin/particularuserdetails",
                  search: `?userid=${ChirpObject.To._id}`,
                  state: { fromDashboard: true },
                  // console.log(first)
                }}
              >
                <img
                  alt="profile-user"
                  width="45px"
                  height="45px"
                  src={`${ChirpObject.To.image[0]}`}
                  style={{ cursor: "pointer", borderRadius: "50%", border: "5px solid #ffffff10", objectFit: 'cover' }}
                />
                <Box sx={{
                  width: "20px", height: "20px", backgroundColor: "#1d1d1d", borderRadius: '50px',
                  marginTop: "-20px", justifyContent: 'center', display: 'flex', alignItems: 'center',
                }}>
                  <SendRounded style={{ color: "#0ffc03", rotate: "-45deg", fontSize: "11px" }} />
                </Box>
              </Box>
              <Box sx={{ flexDirection: 'column', marginLeft: "10px" }} flex={1}>
                <Box sx={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: "flex-start",
                  display: 'flex'
                }}><Typography style={{
                  fontFamily: 'poppins-mid',
                  fontSize: "13px"
                }}>{ChirpObject.To.buzz_name}</Typography>
                  <Typography style={{
                    borderRadius: "50px",
                    fontFamily: 'poppins-reg',
                    fontSize: "10px",
                    color: "#ffffff90"
                  }} >{format(new Date(ChirpObject.createdAt), "MMM dd, yyyy hh:mma")}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems={"flex-start"}
              flexDirection={'column'}
            >
              <Box sx={{ marginTop: "10px", marginLeft: "5px", display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '100%' }}>
                <Typography
                  style={{
                    fontFamily: 'poppins-reg', fontSize: "12px",
                    paddingTop: "3px",
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingBottom: "3px",
                    borderRadius: "3px",
                  }}>
                  {ChirpObject.Chirp.Chirp}
                </Typography>
                {
                  ChirpObject.Chirp.ReactionReceived != "" && (
                    <Typography style={{
                      borderRadius: "50px",
                      fontFamily: 'poppins-reg',
                      marginTop: "2px",
                      fontSize: "10px",
                      width: "19px",
                      height: "19px",
                      textAlign: 'center',
                      marginLeft: "5px",
                      verticalAlign: 'middle',
                      background: "#ffffff20",
                      border: "1px solid #ffffff20",
                      borderRadius: "50%",
                      color: "#ffffff"
                    }} >{ChirpObject.Chirp.ReactionReceived}</Typography>
                  )
                }


              </Box>
              <Box sx={{ marginTop: "5px", display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '3px' }}>


                <Typography style={{
                  fontFamily: 'poppins-reg', fontSize: "10px",
                  wordBreak: 'break-word',
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "3px",
                  backgroundColor: "#ffffff10",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginRight: "5px",
                  color: '#aaaaaa',
                }}>
                  {ChirpObject.Comments.length} Comments
                </Typography>



                {
                  ChirpObject.Unmasked && (
                    <Typography style={{
                      fontFamily: 'poppins-reg', fontSize: "10px",
                      wordBreak: 'break-word',
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      borderRadius: "3px",
                      backgroundColor: "#ff880010",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginRight: "5px",

                      color: '#ff8800'
                    }}>
                      Unmasked
                    </Typography>
                  )
                }

                {
                  ChirpObject.Reported && (
                    <Typography style={{
                      fontFamily: 'poppins-reg', fontSize: "10px",
                      wordBreak: 'break-word',
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      borderRadius: "3px",
                      border: '1px solid #ff200060',
                      backgroundColor: "#ff200010",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      color: '#ff2000'
                    }}>
                      Reported
                    </Typography>

                  )
                }


              </Box>

            </Box>

            <Box
              display="flex"
              alignItems={"flex-end"}
              flexDirection={'column-reverse'}
              justifyContent="flex-end"
              flex={1}
              alignSelf={'flex-end'}
              mt={"10px"}
              mb={"5px"}
            >
              <Button
                // disabled={imageupload}
                startIcon={<ArrowRightAltRounded />}
                style={{
                  borderRadius: 50,
                  backgroundColor: "#ffffff10",
                  // padding: "18px 36px",
                  fontSize: "12px",
                  fontFamily: 'poppins-reg',
                  alignSelf: 'stretch',
                  textTransform: 'none'
                }}
                variant="contained"
                component={Link}
                to={{
                  pathname: "/admin/particularchirpdetails",
                  search: `?chirpid=${ChirpObject._id}`,
                  state: { fromDashboard: true },
                  // console.log(first)
                }}
              >
                View Details
              </Button>
            </Box>


          </Box>
        );
      })}
    </>
  );
};

export default ParticularUserSendChirp;
