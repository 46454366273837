import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useState } from "react";
import { tokens } from "../../Theme/theme";
import { useEffect } from "react";
import { Header } from "../global/Header";
import SendIcon from "@mui/icons-material/Send";
import {
  ArrowCircleRightRounded,
  CalendarMonth,
  RemoveRedEye,
  ReportOffSharp,
  ReportProblem,
  ViewAgendaSharp,
  ArrowRightAltRounded,
  SendRounded
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { CalendarIcon } from "@mui/x-date-pickers";
import BASE_URL from "../../NetworkConfig/ApiConfig";

const ReceiveConnectionRequests = ({ id }) => {
  console.log(id);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [ConnectionRequests, setConnectionRequests] = useState(null);
  const [Reported, setReported] = useState(false);
  const [Chirps, setChirps] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, seterror] = useState("Loading.....");
  // const [loading, setloading] = useState(true);
  const FetchConnectionRequests = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/connectionrequests/getreceivedconnectionrequests/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      console.log(1);
      setConnectionRequests(data.response);
      console.log(data);
      console.log(Chirps);
      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchConnectionRequests();
  }, []);
  if (!ConnectionRequests) {
    return (
      <>
        <Box m={"20px"}>{error}</Box>
      </>
    );
  }
  //   const handleDateChange = (e) => {
  //     const { value } = e.target;
  //     setSelectedDate(value);
  //   };
  //   const HandleDateClick = () => {
  //     // setlocations(null);
  //     setChirps(null);
  //     FetchChirpDetails();
  //   };
  //   const HandleResetClick = () => {
  //     // setlocations(null);
  //     setChirps(null);
  //     setSelectedDate(null);
  //     FetchChirpDetails();
  //   };

  return (
    <>
    <Typography
      gridColumn="span 12"
      gridRow="span 2"
      style={{ fontFamily: 'poppins-reg', fontSize: "12px", marginTop: '5px', marginLeft: '5px', color: "#aaaaaa" }} >
      {ConnectionRequests.length} Chat Requests Received
    </Typography>
    {ConnectionRequests.map((ConnectionRequest) => {
      if(ConnectionRequest.To == null || ConnectionRequest.From == null){
        return
      }
      return (
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          alignItems="center"
          flexDirection={'column'}
          px="15px"
          py={"8px"}
          backgroundColor={"#ffffff05"}
          borderRadius={"10px"}
        >
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Box
              component={Link}
              style={{ textDecoration: "none", width: "45px", height: "45px", display: 'flex', flexDirection: 'column' }}
              target="_blank"
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${ConnectionRequest.From._id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <img
                alt="profile-user"
                width="45px"
                height="45px"
                src={`${ConnectionRequest.From.image[0]}`}
                style={{ cursor: "pointer", borderRadius: "50%", border: "5px solid #ffffff10", objectFit: 'cover' }}
              />
              <Box sx={{
                width: "20px", height: "20px", backgroundColor: "#1d1d1d", borderRadius: '50px',
                marginTop: "-20px", justifyContent: 'center', display: 'flex', alignItems: 'center',
              }}>
                <SendRounded style={{ color: "#9403fc", rotate: "135deg", fontSize: "11px" }} />
              </Box>
            </Box>
            <Box sx={{ flexDirection: 'column', marginLeft: "10px" }} flex={1}>
              <Box sx={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: "flex-start",
                display: 'flex'
              }}><Typography style={{
                fontFamily: 'poppins-mid',
                fontSize: "13px"
              }}>{ConnectionRequest.From.buzz_name}</Typography>
                <Typography style={{
                  borderRadius: "50px",
                  fontFamily: 'poppins-reg',
                  fontSize: "10px",
                  color: "#ffffff90"
                }} >{format(new Date(ConnectionRequest.createdAt), "MMM dd, yyyy hh:mma")}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems={"flex-start"}
            flexDirection={'column'}
          >
            <Box sx={{ marginTop: "10px", marginLeft: "5px", display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '100%' }}>
              <Typography
                style={{
                  fontFamily: 'poppins-reg', fontSize: "12px",
                  paddingTop: "3px",
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingBottom: "3px",
                  borderRadius: "3px",
                }}>
                {ConnectionRequest.Text}
              </Typography>

            </Box>
            <Box sx={{ marginTop: "5px", display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '3px' }}>

            {(() => {
                  if (ConnectionRequest.Accepted) {
                    console.log(1);
                    return (
                      <Typography style={{
                        fontFamily: 'poppins-reg', fontSize: "10px",
                        wordBreak: 'break-word',
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        borderRadius: "3px",
                        border: '1px solid #0ffc0320',
                        backgroundColor: "#0ffc0310",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        color: '#0ffc03'
                      }}>
                        Request Accepted
                      </Typography>
                    );
                  } else if (!ConnectionRequest.ToShow) {
                    console.log(2);
                    return (
                      <Typography style={{
                        fontFamily: 'poppins-reg', fontSize: "10px",
                        wordBreak: 'break-word',
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        borderRadius: "3px",
                        border: '1px solid #ffffff20',
                        backgroundColor: "#ffffff05",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        color: '#aaaaaa'
                      }}>
                        Cancelled
                      </Typography>
                    );
                  } else if (ConnectionRequest.Rejected === true) {
                    console.log(3);

                    return (
                      <Typography style={{
                        fontFamily: 'poppins-reg', fontSize: "10px",
                        wordBreak: 'break-word',
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        borderRadius: "3px",
                        border: '1px solid #ff200020',
                        backgroundColor: "#ff200005",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        color: '#ff2000'
                      }}>
                        Request Rejected
                      </Typography>
                    );
                  } // else if (Rejected === null || Rejected === undefined) {
                  //   return AddTheComponentForTheCheck("Rejected", colors.redAccent[200]);
                  // }
                  else {
                    console.log(4);
                    const createdAtDate = new Date(
                      ConnectionRequest.createdAt
                    );
                    const currentDate = new Date();

                    const timeDifference = currentDate - createdAtDate;
                    const hoursDifference = timeDifference / (1000 * 60 * 60);

                    if (hoursDifference <= 24) {
                      return (
                        <Typography style={{
                          fontFamily: 'poppins-reg', fontSize: "10px",
                          wordBreak: 'break-word',
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          borderRadius: "3px",
                          border: '1px solid #ff880020',
                          backgroundColor: "#ff88005",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          color: '#ff8800'
                        }}>
                          Pending
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography style={{
                          fontFamily: 'poppins-reg', fontSize: "10px",
                          wordBreak: 'break-word',
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          borderRadius: "3px",
                          border: '1px solid #9403fc20',
                          backgroundColor: "#9403fc05",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          color: '#9403fc'
                        }}>
                          Request Expired
                        </Typography>
                      );
                    }
                  }
                })()}


            </Box>

          </Box>

        </Box>
      );
    })}
  </>
  );
};

export default ReceiveConnectionRequests;
