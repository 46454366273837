import { Box, IconButton, Tooltip, useTheme, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import { ColorModeContext, tokens } from "../../Theme/theme";
import { Logout } from "@mui/icons-material";
import AdminList from "../AdminRoles/AdminList";
import { Link, useNavigate } from "react-router-dom";
import '../../assets/fonts/fonts.css'
import searchIcon from '../../assets/search.svg'
const Topbar = ({ data , onClick}) => {
  const navigate = useNavigate();

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  const handlelogout = () => {
    localStorage.removeItem("token");
    navigate("/", { replace: true });
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={2} mt={1}>
      {/* SEARCH BAR */}


      <Box
        display="flex"
        // backgroundColor={colors.primary[400]}
        borderRadius="3px"
        alignItems={'center'}
      >

        {
          (isMobileScreen || isTabScreen) && (
            <Box onClick={onClick} sx={{
              width: '40px', height: "40px", background: '#ffffff10', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center',
              border: '1px solid #ffffff10', borderRadius: '10px', marginRight: '20px'
            }}>
              <MenuRoundedIcon style={{}} />

            </Box>

          )
        }

        {
          !isMobileScreen && (
            <Box sx={{
              flexDirection: 'row',
              backgroundColor: "#ffffff10",
              display: 'flex',
              borderRadius: "50px",
              border: "1px solid transparent",
              background:
                "linear-gradient(#ffffff10 0 0) padding-box, linear-gradient(#0b0b0f 0 0) padding-box, linear-gradient(45deg,#ffffff30, #00000000) border-box"
            }}>
              <img
                alt="search_icon"
                width="20px"
                height="20px"
                style={{
                  objectFit: 'contain',
                  filter: 'invert(1) hue-rotate(180deg) brightness(1)',
                  alignSelf: 'center',
                  marginLeft: "15px"
                }}
                src={searchIcon}
              />
              <input placeholder="Search user..."
                multiple={false}
                style={{
                  outline: 'none',
                  width: "350px",
                  backgroundColor: "#aaaaaa00",
                  borderWidth: 0,
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  paddingTop: "10px",
                  fontSize: "16px",
                  paddingBottom: "10px",
                  fontFamily: 'poppins-reg',
                  borderRadius: "100px",
                  color: "white"
                }} />

              <Typography
                color={"#ffffff"}
                fontFamily={"poppins-reg"}
                sx={{
                  cursor: 'pointer',
                  width: 'max-content',
                  paddingLeft: "15px",
                  fontSize: "14px",
                  paddingRight: "15px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#ffffff15",
                  borderRadius: "50px",
                  border: "1px solid #ffffff25",
                  m: "8px",
                  textAlign: 'center',
                }}
              >
                {"Search"}
              </Typography>
            </Box>

          )
        }

      </Box>

      {/* ICONS */}
      <Box display="flex" alignItems={'center'}>
        {/* <Tooltip
          title={theme.palette.mode === "dark" ? "DARK MODE" : "LIGHT MODE"}
        >
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip> */}
        {
          isMobileScreen && isTabScreen && (
            <Box sx={{
              width: '45px', height: "45px", background: '#ffffff10', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center',
              border: '1px solid #ffffff10', borderRadius: '50%', marginRight: '20px'
            }}>
              <img
                alt="search_icon"
                width="20px"
                height="20px"
                style={{
                  objectFit: 'contain',
                  filter: 'invert(1) hue-rotate(180deg) brightness(1)',
                  alignSelf: 'center',
                  marginRight: '3px',
                  marginBottom: '2px'
                }}
                src={searchIcon}
              />

            </Box>
          )}
        {data.Role === "ADMIN" ? (
          <Tooltip title="BUZZ TEAM">
            <IconButton style={{ backgroundColor: "#ffffff20", width: '45px', height: '45px' }} component={Link} to="/admin/adminlist">
              <Diversity3OutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="LOGOUT">
          <IconButton style={{ backgroundColor: "#ffffff20", width: '45px', height: '45px', marginLeft:'10px' }} onClick={handlelogout}>
            <Logout />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Topbar;
