import { Alert, Snackbar } from "@mui/material";
import React from "react";

const SnackbarComponent = ({ open, severity, AlertMessage }) => {
  const [openn, setOpenn] = React.useState(Boolean(open));
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenn(false);
  };
  console.log("hii");
  return (
    <Snackbar open={openn} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {AlertMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
