import { ExpandMoreOutlined, Save } from "@mui/icons-material";
import {
  Add,
  Camera,
  CameraAltOutlined,
  CropOriginal,
  Delete,
  DeleteForever,
  DeleteOutline,
  Update,
} from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";

import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import { useState } from "react";
import { useEffect } from "react";
import SnackbarComponent from "./SnackbarComponent";
import ModifyLocationdetails from "./ModifydataApi";

const FourthRow = ({ data }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [Address, setAddress] = useState("");
  const [Category, setCategory] = useState("");
  const [Description, setDescription] = useState("");
  const [AddressError, setAddressError] = useState(false);
  const [CategoryError, setCategoryError] = useState(false);
  const [DescriptionError, setDescriptionError] = useState(false);
  const [buttonloading, setbuttonloading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const ModifyData = async () => {
    try {
      const response = await ModifyLocationdetails(
        data._id,
        localStorage.getItem("token"),
        {
          Category: Category,
          Address: Address,
          Description: Description,
        }
      );
      if (response.success === true) {
        setbuttonloading(false);
        setseverity("success");
        setAlertMessage("Data Saved Successfully");
        setShowSnackbar(true);
      }
      if (response.success === false) {
        setbuttonloading(false);
        setseverity("error");
        setAlertMessage("INTERNAL SERVER ERROR");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCategoryChange = (e) => {
    setCategoryError(false);
    setCategory(e.target.value);
  };
  const handleAdressChange = (e) => {
    const { value } = e.target;
    setAddress(value);
    if (value.length < 1) {
      setAddressError(true);
      return false;
    }
    setAddressError(false);
    return true;
  };
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
    if (value.length < 1) {
      setDescriptionError(true);
      return false;
    }
    setDescriptionError(false);
    return true;
  };
  useEffect(() => {
    console.log(data);
    if (data) {
      setCategory(data.Category);
      setAddress(data.Address);
      setDescription(data.Description);
    }
  }, []);
  if (data === undefined || data === null) {
    return <Box>NOT GETTING DATA</Box>;
  }

  return (
    <Box>
      <Accordion sx={{ backgroundColor: colors.blueAccent[900] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          CATEGORY,ADDRESS,DESCRIPTION
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display={"grid"}
            gridTemplateColumns="repeat(12,1fr)"
            gridAutoRows={"80px"}
            gap="20px"
          >
            <Box
              gridColumn={"span 12"}
              //   backgroundColor={colors.primary[400]}
              //   gridRow="span 3"
            >
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900] },
                  color: colors.grey[900],
                }}
              >
                <InputLabel
                  sx={{
                    color: colors.blueAccent[800],
                    paddingLeft: 1,
                    fontWeight: "bold",
                  }}
                  id="demo-simple-select-standard-label"
                >
                  CATEGORY
                </InputLabel>
                <Select
                  labelStyle={{ color: "#ff0000" }}
                  sx={{
                    color: "white",
                    ".MuiSelect-select": {
                      color: colors.grey[900],
                      pl: 1,
                      fontWeight: "bold",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={Category}
                  // inputProps={{ input: {  } }}color: colors.grey[900]
                  onChange={handleCategoryChange}
                  label="Age"
                  error={CategoryError}
                >
                  <MenuItem value="">
                    <em>PLEASE SELECT FROM THE FOLLOWING</em>
                  </MenuItem>
                  <MenuItem value={"Pub"}>Pub</MenuItem>
                  <MenuItem value={"Cafe"}>Cafe</MenuItem>
                  <MenuItem value={"QSR"}>QSR</MenuItem>
                </Select>
                {CategoryError ? (
                  <FormHelperText>Please Select a Category</FormHelperText>
                ) : undefined}
              </FormControl>
            </Box>
            <Box
              gridColumn={"span 12"}
              //   backgroundColor={colors.primary[400]}
              //   gridRow="span 3"
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <TextField
                // gridColumn="span 8"
                // gridRow="span 3"
                id=""
                label="Address"
                rows={10}
                //   color={colors.greenAccent[200]}
                //   fullWidth
                error={AddressError}
                helperText={AddressError ? "Enter Address" : null}
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  // marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
                value={Address}
                onChange={handleAdressChange}
              />
            </Box>
            <Box
              gridColumn={"span 12"}
              //   backgroundColor={colors.primary[400]}
              //   gridRow="span 3"
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <TextField
                // gridColumn="span 8"
                // gridRow="span 3"
                id=""
                label="Description"
                rows={10}
                error={DescriptionError}
                helperText={
                  DescriptionError ? "Enter a valid Description" : null
                }
                //   color={colors.greenAccent[200]}
                //   fullWidth
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  // marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
                value={Description}
                onChange={handleDescriptionChange}
              />
            </Box>
          </Box>
          <Box pb={"10px"} display={"flex"} justifyContent="center">
            <Button
              disabled={buttonloading}
              startIcon={<Save />}
              style={{
                borderRadius: 12,
                backgroundColor: colors.greenAccent[500],
                // padding: "18px 36px",
                fontSize: "18px",
                height: "34px",
                marginBottom: "2px",
              }}
              variant="contained"
              component="label"
              onClick={ModifyData}
              //   disabled={false}
            >
              Save Changes
              {/* {buttonloading === true ? buttontext : "ADD THE LOCATION"} */}
              {/* <input hidden accept="image/*" multiple type="file" /> */}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </Box>
  );
};

export default FourthRow;
