import {
  Add,
  Camera,
  CameraAltOutlined,
  CropOriginal,
  Delete,
  DeleteForever,
  DeleteOutline,
  Update,
} from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import { Link } from "react-router-dom";
import { tokens } from "../../Theme/theme";
import { Header } from "../global/Header";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { statedistrict } from "../../data/statedistrict";
import ImageUploading from "react-images-uploading";
import { ColorPicker } from "material-ui-color";
import BASE_URL from "../../NetworkConfig/ApiConfig";
// import { DropzoneArea } from "material-ui-dropzone";

const AddEnabledLocation = () => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const preset_key = "marketing_admin";
  const cloud_name = "dx8qieggt";
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [logoupload, setlogoupload] = useState(false);
  const [Logouploadtext, setLogouploadtext] = useState("Upload Logo");
  const [disable, setdisable] = React.useState(false);
  const [State, setState] = useState("");
  const [Logo, setLogo] = useState(null);
  const [AccentColor, setAccentColor] = React.useState("#000");
  const [StateError, setStateError] = useState(false);
  const [District, setDistrict] = useState("");
  const [DistrictError, setDistrictError] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [LocationName, setLocationName] = useState("");
  const [Lattitude, setLattitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [ReferralCode, setReferralCode] = useState("");
  const [Address, setAddress] = useState("");
  const [Category, setCategory] = useState("");
  const [Description, setDescription] = useState("");
  const [LocationNameError, setLocationNameError] = useState(false);
  const [LattitudeError, setLattitudeError] = useState(false);
  const [LongitudeError, setLongitudeError] = useState(false);
  const [ReferralCodeError, setReferralCodeError] = useState(false);
  const [BuzzEnabled, setBuzzEnabled] = useState(true);
  const [LocationEmail, setLocationEmail] = useState("");
  const [LocationEmailError, setLocationEmailError] = useState(false);
  const [AddressError, setAddressError] = useState(false);
  const [CategoryError, setCategoryError] = useState(false);
  const [DescriptionError, setDescriptionError] = useState(false);
  const [severity, setseverity] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [buttonloading, setbuttonloading] = useState(false);
  const [buttontext, setbuttontext] = useState("");
  const [Images, setImages] = React.useState([]);

  const handleImageUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const uploadedImages = uploadedFiles.map((file) => file);

    setImages((prevImages) => [...prevImages, ...uploadedImages]);
    // if (uploadedFiles.length > 0) {
    //   handleLogochange(Images[0]);
    // }
    console.log(Images);
  };
  const handleLogochange = async (imageupload) => {
    setlogoupload(true);
    setLogouploadtext("UPLOADING....");
    setdisable(true);
    const data = new FormData();
    data.append("file", imageupload);
    data.append("upload_preset", preset_key);
    data.append("cloud_name", cloud_name);
    data.append("folder", "locations");
    // setLogo();
    console.log(preset_key);
    fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
      method: "POST",
      body: data,
    })
      .then((uploadImageToCloudinary) => {
        console.log(uploadImageToCloudinary);
        return uploadImageToCloudinary.json();
      })
      .then((uploadResponse) => {
        const GetImageUrl = uploadResponse.secure_url;
        console.log(GetImageUrl);
        // const files = e.target.files;
        console.log(GetImageUrl);
        setLogo(GetImageUrl);
        setlogoupload(false);
        setLogouploadtext("UPLOAD DONE");
        setdisable(false);
        return true;
      })
      .catch((error) => {
        console.error(error);
        // Handle error
        setlogoupload(false);
        setLogouploadtext("UPLOAD ERROR");
        setdisable(false);
        return false;
      });
    // const base64 = await convertBase64(files[0]);
    // console.log(base64);
    // setLogo(base64);
  };

  const handleStateChange = (event) => {
    setStateError(false);
    setState(event.target.value);
    setDistricts(
      statedistrict.find((state) => state.state === event.target.value)
        .districts
    );
  };
  const handleDistrictChange = (event) => {
    setDistrictError(false);
    setDistrict(event.target.value);
  };
  const handleLocationChange = (e) => {
    const { value } = e.target;
    setLocationName(value);
    if (value.length < 1) {
      setLocationNameError(true);
      return false;
    }
    setLocationNameError(false);
    return true;
  };
  const handleLattitudeChange = (e) => {
    const { value } = e.target;
    setLattitude(value);
    if (value.length < 1) {
      setLattitudeError(true);
      return false;
    }
    setLattitudeError(false);
    return true;
  };
  const handleLongitudeChange = (e) => {
    const { value } = e.target;
    setLongitude(value);
    if (value.length < 1) {
      setLongitudeError(true);
      return false;
    }
    setLongitudeError(false);
    return true;
  };
  const handleRefferalCodeChange = (e) => {
    const { value } = e.target;
    setReferralCode(value);
    // if (value.length < 1) {
    //   setReferralCodeError(true);
    //   return false;
    // }
    // setReferralCodeError(false);
    return true;
  };
  const handleCategoryChange = (e) => {
    setCategoryError(false);
    setCategory(e.target.value);
  };
  const handlebuzzenabled = (e) => {
    setBuzzEnabled(e.target.value);
    // setCategory(e.target.value);
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setLocationEmail(value);
    if (value.length > 0 && EmailValidator.validate(value) === false) {
      setLocationEmailError(true);
      return false;
    }
    setLocationEmailError(false);
    return true;
  };
  const handleAdressChange = (e) => {
    const { value } = e.target;
    setAddress(value);
    if (value.length < 1) {
      setAddressError(true);
      return false;
    }
    setAddressError(false);
    return true;
  };
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
    if (value.length < 1) {
      setDescriptionError(true);
      return false;
    }
    setDescriptionError(false);
    return true;
  };
  const [selectedColor, setSelectedColor] = useState("#000000");

  function handleColorChange(event) {
    setSelectedColor(event.target.value);
    console.log("Selected color: " + event.target.value);
  }

  const formData = new FormData();
  const PostData = async () => {
    setbuttonloading(true);
    const uploadPromises = Images.map(async (file) => {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", preset_key);
      data.append("cloud_name", cloud_name);
      data.append("folder", "locations");

      return fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      )
        .then((uploadImageToCloudinary) => uploadImageToCloudinary.json())
        .then((uploadResponse) => uploadResponse.secure_url)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    });

    // return;
    if (Logo === "" || Logo === undefined || Logo === null) {
      setAlertMessage("Please Upload A Logo");
      setseverity("warning");
      handleClick();
      setbuttonloading(false);
      return false;
    }
    setbuttontext("Checking Logo....");
    if (
      Images === "" ||
      Images === undefined ||
      Images === null ||
      Images.length < 1
    ) {
      setAlertMessage("Please Upload Atleast One Image");
      setseverity("warning");
      handleClick();
      setbuttonloading(false);

      return false;
    }
    setbuttontext("Checking Images....");

    if (LocationName.length < 1) {
      setLocationNameError(true);
      setbuttonloading(false);

      return false;
    }
    setbuttontext("Checking Coordinates....");

    if (Lattitude.length < 1) {
      setLattitudeError(true);
      setbuttonloading(false);

      return false;
    }
    if (Longitude.length < 1) {
      setLongitudeError(true);
      setbuttonloading(false);

      return false;
    }
    if (State.length < 1) {
      setStateError(true);
      setbuttonloading(false);

      return false;
    }
    if (District.length < 1) {
      setDistrictError(true);
      setbuttonloading(false);

      return false;
    }
    if (Category.length < 1) {
      setCategoryError(true);
      setbuttonloading(false);

      return false;
    }
    // if (ReferralCode.length < 1) {
    //   setReferralCodeError(true);
    //   setbuttonloading(false);

    //   return false;
    // }
    if (Address.length < 1) {
      setAddressError(true);
      setbuttonloading(false);

      return false;
    }
    if (Description.length < 1) {
      setDescriptionError(true);
      setbuttonloading(false);

      return false;
    }
    if (
      LocationEmail.length > 0 &&
      EmailValidator.validate(LocationEmail) === false
    ) {
      setLocationEmailError(true);
      setbuttonloading(false);

      return false;
    }

    // return;
    console.log(Images);
    const uploadedImages = await Promise.all(uploadPromises);
    console.log(uploadedImages);
    // return;
    try {
      // console.log("Images", Images.toString());
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/enabledlocation/addMain/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify({
          Logo: Logo,
          Images: uploadedImages,
          LocationName: LocationName,
          AccentColor: selectedColor,
          Lattitude: Lattitude,
          Longitude: Longitude,
          State: State,
          District: District,
          ReferralCode: ReferralCode,
          LocationEmail: LocationEmail,
          Description: Description,
          Category: Category,
          Address: Address,
          BuzzEnabled: BuzzEnabled,
        }),
      });
      setbuttontext("Adding Data....");
      const json = await response.json();
      if (json.success === true) {
        setseverity("success");
        setbuttonloading(false);
        setAlertMessage(json.response);
        setLogo("");
        setImages([]);
        setLocationName("");
        setLattitude("");
        setLongitude("");
        setState("");
        setDistricts([]);
        setDistrict("");

        setReferralCode("");
        setLocationEmail("");
        setAccentColor("#000");
        setCategory("");
        setAddress("");
        setDescription("");
        handleClick();
      }
      if (json.success === false) {
        setseverity("warning");
        setbuttonloading(false);

        setAlertMessage(json.response);
        handleClick();
        return false;
      }
    } catch (error) {
      setbuttonloading(false);
      console.log(error);
    }
  };

  const HandleImagechanges = async (e) => {
    console.log(e.target.files[0]);
    // setLogo();
    const files = e.target.files;
    setImages([...Images, e.target.files[0]]);
    console.log(Images);
  };
  const handleAccentcolorchange = (value) => {
    console.log("onChange=", value);
    setAccentColor(value);
  };
  const deleteFile = (imagename) => {
    setImages((current) => current.filter((im) => im.name !== imagename));
  };
  return (
    <Box m={"20px"}>
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={"Add New Enabled Location"}
          subtitle={"Make A Location Buzz Enabled"}
        />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Button
          disabled={logoupload}
          startIcon={<LocalSeeIcon />}
          style={{
            borderRadius: 12,
            backgroundColor: colors.greenAccent[500],
            // padding: "18px 36px",
            fontSize: "18px",
            height: "34px",
          }}
          variant="contained"
          component="label"
        >
          Upload Logo
          <input
            type="file"
            hidden
            onChange={(e) => handleLogochange(e.target.files[0])}
          />
        </Button>
      </Box>
      <Box mt={2} display={"flex"} justifyContent="center">
        {Logo && (
          <img
            width="100px"
            height="100px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
            src={Logo}
            alt=""
          />
        )}
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows={"80px"}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            error={LocationNameError}
            label="LOCATION NAME"
            //   color={colors.greenAccent[200]}
            //   fullWidth
            helperText={LocationNameError ? "Enter Valid Location Name" : null}
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            value={LocationName}
            onChange={handleLocationChange}
          />
        </Box>
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            label="LATITUDE"
            error={LattitudeError}
            type="number"
            helperText={LattitudeError ? "Enter Lattitude" : null}
            //   color={colors.greenAccent[200]}
            //   fullWidth
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            value={Lattitude}
            onChange={handleLattitudeChange}
          />
        </Box>
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            error={LongitudeError}
            helperText={LongitudeError ? "Enter Longitude" : null}
            label="LONGITUDE"
            //   color={colors.greenAccent[200]}
            //   fullWidth
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            value={Longitude}
            onChange={handleLongitudeChange}
          />
        </Box>
        <Box gridColumn={"span 4"}>
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900] },
              color: colors.grey[900],
            }}
            error={StateError}
          >
            <InputLabel
              sx={{
                color: colors.blueAccent[800],
                paddingLeft: 1,
                fontWeight: "bold",
              }}
              id="demo-simple-select-standard-label"
            >
              Select State
            </InputLabel>
            <Select
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  color: colors.grey[900],
                  pl: 1,
                  fontWeight: "bold",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={State}
              onChange={handleStateChange}
            >
              {statedistrict.map((state) => (
                <MenuItem key={state.code} value={state.state}>
                  {state.state}
                </MenuItem>
              ))}
            </Select>
            {StateError ? <FormHelperText>Error</FormHelperText> : undefined}
          </FormControl>
        </Box>
        <Box gridColumn={"span 4"}>
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900] },
              color: colors.grey[900],
            }}
            error={DistrictError}
          >
            <InputLabel
              sx={{
                color: colors.blueAccent[800],
                paddingLeft: 1,
                fontWeight: "bold",
              }}
              id="demo-simple-select-standard-label"
            >
              Select District
            </InputLabel>
            <Select
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  color: colors.grey[900],
                  pl: 1,
                  fontWeight: "bold",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              // value={}
              value={District}
              onChange={handleDistrictChange}
              disabled={districts.length === 0}
            >
              <MenuItem value="">
                <em>PLEASE SELECT FROM THE FOLLOWING</em>
              </MenuItem>
              {districts.map((district) => (
                <MenuItem key={district} value={district}>
                  {district}
                </MenuItem>
              ))}
            </Select>
            {DistrictError ? (
              <FormHelperText>Select District</FormHelperText>
            ) : undefined}
          </FormControl>
        </Box>
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            label="REFFERAL CODE"
            // error={ReferralCodeError}
            // helperText={ReferralCodeError ? "Enter Refferal Code" : null}
            //   color={colors.greenAccent[200]}
            //   fullWidth
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              //   marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            InputProps={{}}
            value={ReferralCode}
            onChange={handleRefferalCodeChange}
          />
        </Box>
        {/* <Box
          gridColumn={"span 6"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
        >
          <Autocomplete
            multiple
            limitTags={4}
            id="fixed-tags-demo"
            value={value}
            onChange={(event, newValue) => {
              console.log(newValue);
              setValue([...newValue]);
              console.log(value);
            }}
            options={top100Films}
            getOptionLabel={(option) => option.label || ""}
            renderTags={(tagValue, getTagProps) =>
              // console.log(tagValue)
              tagValue.map((option, index) => {
                console.log(option);
                return (
                  <Chip
                    sx={{
                      backgroundColor: colors.blueAccent[800],
                      color: colors.grey[300],
                    }}
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            style={{ width: "100% " }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="VIBES"
                placeholder="ADD A VIBE"
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
              />
            )}
          />
        </Box> */}
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            label="EMAIL"
            error={LocationEmailError}
            helperText={LocationEmailError ? "Enter a valid Email" : null}
            //   color={colors.greenAccent[200]}
            //   fullWidth
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            value={LocationEmail}
            onChange={handleEmailChange}
          />
        </Box>
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
        >
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900] },
              color: colors.grey[900],
            }}
          >
            <InputLabel
              sx={{
                color: colors.blueAccent[800],
                paddingLeft: 1,
                fontWeight: "bold",
              }}
              id="demo-simple-select-standard-label"
            >
              BUZZ ENABLED
            </InputLabel>
            <Select
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  color: colors.grey[900],
                  pl: 1,
                  fontWeight: "bold",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={BuzzEnabled}
              // inputProps={{ input: {  } }}color: colors.grey[900]
              onChange={handlebuzzenabled}
              label="Age"
              // error={CategoryError}
            >
              <MenuItem value="">
                <em>PLEASE SELECT FROM THE FOLLOWING</em>
              </MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          gridColumn={"span 4"}
          //   backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <p style={{ padding: "12px" }}>
            Selected Accent Color:-{selectedColor}
          </p>
          <input
            style={{
              // width: "100%",
              height: "75%",
              // marginTop: 2,
              // backgroundColor: colors.grey[300],
              // input: { color: colors.grey[900] },
              // color: colors.grey[900],
            }}
            type="color"
            value={selectedColor}
            onChange={handleColorChange}
          />
        </Box>
        <Box
          gridColumn={"span 12"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
        >
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900] },
              color: colors.grey[900],
            }}
          >
            <InputLabel
              sx={{
                color: colors.blueAccent[800],
                paddingLeft: 1,
                fontWeight: "bold",
              }}
              id="demo-simple-select-standard-label"
            >
              CATEGORY
            </InputLabel>
            <Select
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  color: colors.grey[900],
                  pl: 1,
                  fontWeight: "bold",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={Category}
              // inputProps={{ input: {  } }}color: colors.grey[900]
              onChange={handleCategoryChange}
              label="Age"
              error={CategoryError}
            >
              <MenuItem value="">
                <em>PLEASE SELECT FROM THE FOLLOWING</em>
              </MenuItem>
              <MenuItem value={"Pub"}>Pub</MenuItem>
              <MenuItem value={"Cafe"}>Cafe</MenuItem>
              <MenuItem value={"QSR"}>QSR</MenuItem>
            </Select>
            {CategoryError ? (
              <FormHelperText>Please Select a Category</FormHelperText>
            ) : undefined}
          </FormControl>
        </Box>
        <Box
          gridColumn={"span 12"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            label="Address"
            rows={10}
            //   color={colors.greenAccent[200]}
            //   fullWidth
            error={AddressError}
            helperText={AddressError ? "Enter Address" : null}
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              // marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            value={Address}
            onChange={handleAdressChange}
          />
        </Box>
        <Box
          gridColumn={"span 12"}
          //   backgroundColor={colors.primary[400]}
          //   gridRow="span 3"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            label="Description"
            rows={10}
            error={DescriptionError}
            helperText={DescriptionError ? "Enter a valid Description" : null}
            //   color={colors.greenAccent[200]}
            //   fullWidth
            variant="standard"
            InputLabelProps={{
              style: {
                color: colors.blueAccent[800],
                paddingLeft: 7,
                fontWeight: "bold",
              },
            }}
            sx={{
              width: "100%",
              // marginTop: 2,
              backgroundColor: colors.grey[300],
              input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
              // borderRadius: "10%",
            }}
            value={Description}
            onChange={handleDescriptionChange}
          />
        </Box>
      </Box>
      <Box mb={2} display={"flex"} justifyContent="center">
        <Box display={"flex"} justifyContent="center">
          <Button
            startIcon={<CropOriginal />}
            style={{
              borderRadius: 12,
              backgroundColor: colors.greenAccent[500],
              // padding: "18px 36px",
              fontSize: "18px",
              height: "34px",
            }}
            variant="contained"
            component="label"
          >
            Upload Images
            <input
              type="file"
              id="files"
              name="files"
              multiple
              hidden
              onChange={(e) => handleImageUpload(e)}
            />
          </Button>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent="space-evenly">
        {Images.length > 0 &&
          Images.map((item, index) => {
            return (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                key={index}
                mb={2}
              >
                <img
                  width="100px"
                  height="100px"
                  style={{ cursor: "pointer" }}
                  src={URL.createObjectURL(item)}
                  alt=""
                />
                <Button
                  startIcon={<DeleteOutline />}
                  style={{
                    borderRadius: 12,
                    backgroundColor: colors.redAccent[500],
                    // padding: "18px 36px",
                    fontSize: "18px",
                    marginTop: "10px",
                    height: "34px",
                  }}
                  variant="contained"
                  type="button"
                  onClick={() => deleteFile(item.name)}
                >
                  delete
                </Button>
              </Box>
            );
          })}
      </Box>
      <Box pb={"10px"} display={"flex"} justifyContent="center">
        {/* <img src="" alt="" /> */}
        <Button
          disabled={buttonloading}
          startIcon={<Add />}
          style={{
            borderRadius: 12,
            backgroundColor: colors.greenAccent[500],
            // padding: "18px 36px",
            fontSize: "18px",
            height: "34px",
            marginBottom: "2px",
          }}
          variant="contained"
          component="label"
          onClick={PostData}
          //   disabled={false}
        >
          {buttonloading === true ? buttontext : "ADD THE LOCATION"}
          {/* <input hidden accept="image/*" multiple type="file" /> */}
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddEnabledLocation;
