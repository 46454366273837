import { Box, IconButton, Tooltip, useTheme, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import { ColorModeContext, tokens } from "../../Theme/theme";
import { Logout } from "@mui/icons-material";
import AdminList from "../AdminRoles/AdminList";
import { Link, useNavigate } from "react-router-dom";
import '../../assets/fonts/fonts.css'
import logo from '../../assets/buzz.png'
import searchIcon from '../../assets/search.svg'
import LineChart from "../Charts/LineChart";
import io from 'socket.io-client';
import BASE_URL from "../../NetworkConfig/ApiConfig";



const LiveCount = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const logo_url = "https://firebasestorage.googleapis.com/v0/b/buzz-dev-1.appspot.com/o/logo%2Fbuzz_logo_background_black.png?alt=media&token=50d3b437-439d-4339-99da-d5ef8d843644"
    const navigate = useNavigate();

    const isMobileScreen = useMediaQuery('(max-width:500px)');
    const isTabScreen = useMediaQuery('(max-width:1200px)')
    const socket = io('https://buzz-admin-panel.vercel.app', {
        transports: ['websocket', 'polling'],
    });
    const [userData, setUserData] = useState({})
    const [activeUsers, setActiveUsers] = useState(0)


    useEffect(()=>{
        FetchDashboardDetails()
    }, [])

    useEffect(() => {

        socket.emit("getActiveUsers", "1")

        socket.on("activeUsersUpdate", (data) => {
            setActiveUsers(data.count)
        })

        socket.on('userCountUpdate', (data) => {
            setUserData(data)
        });

        // Clean up the socket connection
        return () => {
            socket.off('userCountUpdate');
            socket.off('activeUsersUpdate');
        };
    }, []);


    const FetchDashboardDetails = async () => {
        // setloading(true);
        const token = localStorage.getItem("token");
        const response = await fetch(`${BASE_URL}/dashboard/live_details/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
        });
        const data = await response.json();
        if (data.success === true) {
          setUserData(data.response[0])
          return;
        }
        if (data.success === false) {
            alert("please reload")
          return;
        }
      };

      function getUpdatedCount(count){

        if(count.toString().length <= 3){
            return count
        }else if(count.toString().length == 4){
            return count.toString().substring(0, 1) + "," + count.toString().substring(1)

        }else if(count.toString().length == 5){
            return count.toString().substring(0, 2) + "," + count.toString().substring(2)
        }else {
            return count.toString().substring(0, 3) + "," + count.toString().substring(3)
        }

      }



    return (
        <Box display="flex" flexDirection={'column'}>

            <Box sx={{ alignSelf: 'stretch', background: '#ffffff10', height: "120px", flexDirection: 'column-reverse', display: 'flex' }}>

                <img src={logo_url} style={{
                    width: "90px", height: "90px", background: "#0B0B0F", objectFit: 'contain', borderRadius: "100px",
                    border: '8px solid #ffffff10', display: 'flex', alignSelf: 'center', marginBottom: '-45px'
                }} />

                <Typography sx={{
                    fontFamily: 'poppins-reg', marginLeft: isMobileScreen ? "10px" : "50px", fontSize: "14px",
                    position: 'absolute', top: 15
                }}>• Updating Live</Typography>

            </Box>

            <Box sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex', alignSelf: 'stretch', marginTop: '50px' }}>

                <Typography style={{ fontFamily: 'poppins-ebold', marginTop: isMobileScreen ? "50px" : "100px", fontSize: isMobileScreen ? "50px" : "70px", color: "#ffffff" }}>
                    {userData.count ? getUpdatedCount(userData.count) : "0"}</Typography>
                <Typography style={{ fontFamily: 'poppins-reg', fontSize: "14px", color: "#ffffff90" }} >Users Registered</Typography>



                <Box sx={{
                    alignSelf: 'center', border: "1px solid #aaaaaa20", borderRadius: "10px", flexDirection: 'row', display: 'flex', alignItems: 'center',
                    marginTop: "50px", marginX: isTabScreen ? "20px" : "0px"
                }} >
                    <Box sx={{ flexDirection: 'column', display: 'flex', paddingY: "15px", paddingX: isMobileScreen ? "15px" : "40px", alignItems: 'center' }}>

                        <Typography style={{ fontFamily: isMobileScreen ? "poppins-reg" : 'poppins-mid', fontSize: isMobileScreen ? "15px" : "22px", color: "#ffffff" }} >{userData.thisMonth ? userData.thisMonth : "0"}</Typography>
                        <Typography style={{ fontFamily: 'poppins-reg', fontSize: isMobileScreen ? "10px" : "13px", color: "#ffffff70", textAlign: 'center' }} >This Month</Typography>


                    </Box>

                    <Box sx={{ width: "1px", height: "60px", background: "#aaaaaa20", marginX: "10px" }} />

                    <Box sx={{ flexDirection: 'column', display: 'flex', paddingY: "15px", paddingX: isMobileScreen ? "15px" : "40px", alignItems: 'center' }}>

                        <Typography style={{ fontFamily: isMobileScreen ? "poppins-reg" : 'poppins-mid', fontSize:isMobileScreen ? "15px" : "22px", color: "#00ff08" }} >{activeUsers}</Typography>
                        <Typography style={{ fontFamily: 'poppins-reg', fontSize: isMobileScreen ? "10px" : "13px", color: "#ffffff70", textAlign:'center' }} >Active Today</Typography>


                    </Box>
                    <Box sx={{ width: "1px", height: "60px", background: "#aaaaaa20", marginX: "10px" }} />

                    <Box sx={{ flexDirection: 'column', display: 'flex', paddingY: "15px", paddingX: isMobileScreen ? "15px" : "40px", alignItems: 'center' }}>

                        <Typography style={{ fontFamily: isMobileScreen ? "poppins-reg" : 'poppins-mid', fontSize: isMobileScreen ? "15px" : "22px", color: "#ffffff" }} >{userData.today ? userData.today : "0"}</Typography>
                        <Typography style={{ fontFamily: 'poppins-reg', fontSize: isMobileScreen ? "10px" : "13px", color: "#ffffff70", textAlign:'center' }} >Onboarded Today</Typography>


                    </Box>
                </Box>
                {
                    userData.graphData && (
                        <Box
                            sx={{
                                border: "2px solid transparent",
                                borderRadius: "20px",
                                alignSelf:'stretch', marginTop: "40px",
                                marginX: isTabScreen?  "20px" : "200px",
                                marginBottom: "50px",
                                background:
                                    "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
                            }}
                        >
                            <Box
                                mt="25px"
                                p="0 30px"
                                display="flex "
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        fontFamily={"poppins-mid"}
                                        color={colors.grey[100]}
                                    >
                                        Accounts Created
                                    </Typography>
                                </Box>
                            </Box>
                            <Box height="400px" m="-20px 0 0 0">
                                <LineChart
                                    // isDashboard={true}
                                    data={userData.graphData ? userData.graphData.data : []}
                                    color={"paired"}
                                    enableArea={true}
                                />
                            </Box>
                        </Box>
                    )
                }



            </Box>

        </Box>
    );
};

export default LiveCount;
