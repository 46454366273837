import { ExpandMoreOutlined, Save } from "@mui/icons-material";
import {
  Add,
  Camera,
  CameraAltOutlined,
  CropOriginal,
  Delete,
  DeleteForever,
  DeleteOutline,
  Update,
} from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";

import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import { useState } from "react";
import { useEffect } from "react";
import ModifyLocationdetails from "./ModifydataApi";
import SnackbarComponent from "./SnackbarComponent";
import ImageDelete from "./DeleteImagApi";

const FifthRow = ({ data }) => {
  const theme = useTheme();
  const locationid = data._id;
  const colors = tokens(theme.palette.mode);
  const [Images, setImages] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const deleteFile = async (ImageName) => {
    setImages((current) => current.filter((im) => im !== ImageName));

    const response = await ImageDelete(
      data._id,
      localStorage.getItem("token"),
      { imagetobedeleted: ImageName }
    );
    if (response.success === true) {
      //   setbuttonloading(false);
      setseverity("success");
      setAlertMessage(response.response);
      setShowSnackbar(true);
    }
    if (response.success === false) {
      //   setbuttonloading(false);
      setseverity("error");
      setAlertMessage(response.response);
      setShowSnackbar(true);
    }
  };
  useEffect(() => {
    console.log(data);
    if (data) {
      setImages(data.Images);
    }
  }, []);
  if (data === undefined || data === null) {
    return <Box>NOT GETTING DATA</Box>;
  }
  return (
    <Box mb={4}>
      <Box display={"flex"} justifyContent="space-evenly">
        {Images &&
          Images.map((item, index) => {
            return (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                key={index}
                mb={2}
              >
                <Box>
                  <img
                    width="100px"
                    height="100px"
                    style={{ cursor: "pointer" }}
                    src={item}
                    alt=""
                  />
                  <Button
                    startIcon={<DeleteOutline />}
                    style={{
                      borderRadius: 12,
                      backgroundColor: colors.redAccent[500],
                      // padding: "18px 36px",
                      fontSize: "18px",
                      marginTop: "10px",
                      height: "34px",
                    }}
                    variant="contained"
                    type="button"
                    onClick={() => deleteFile(item)}
                  >
                    delete
                  </Button>
                </Box>
              </Box>
            );
          })}
      </Box>
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </Box>
  );
};

export default FifthRow;
