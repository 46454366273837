const BASE_URL = 'https://api.konnxt.com';


export default BASE_URL;



// server {
//     listen 80;
//     server_name 3.108.190.47;

//     location / {
//     proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
//     proxy_set_header Host $host;
//     proxy_set_header Access-Control-Allow-Origin *;

//     proxy_pass http://localhost:6001;

//     proxy_http_version 1.1;
//     proxy_set_header Upgrade $http_upgrade;
//     proxy_set_header Connection "upgrade";
//     }
// }



// server {
//     listen 80;
//     server_name api.konnxt.com;

//     # Redirect all HTTP requests to HTTPS
//     return 301 https://$host$request_uri;
// }

// server {
//     listen 443 ssl;
//     server_name api.konnxt.com;

//     ssl_certificate /etc/letsencrypt/live/api.konnxt.com/fullchain.pem;
//     ssl_certificate_key /etc/letsencrypt/live/api.konnxt.com/privkey.pem;

//     location / {
//         proxy_pass http://localhost:6001;  # Replace with your backend port
//         proxy_set_header Host $host;
//         proxy_set_header X-Real-IP $remote_addr;
//         proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
//         proxy_set_header X-Forwarded-Proto $scheme;
//     }
//}
