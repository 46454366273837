import BASE_URL from "../../../NetworkConfig/ApiConfig";

const ImageDelete = async (id, token, modifieddata) => {
    // const token = localStorage.getItem("token");
    console.log(id)
    const response = await fetch(`${BASE_URL}/enabledlocation/deleteimage/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "auth-token": token,
        },
        body: JSON.stringify(
            modifieddata
        ),
    });
    const json = await response.json();
    console.log(json)
    // if (json.success === true) {
    return json
    // }
}

export default ImageDelete 