import { ExpandMoreOutlined, Save } from "@mui/icons-material";
import {
  Add,
  Camera,
  CameraAltOutlined,
  CropOriginal,
  Delete,
  DeleteForever,
  DeleteOutline,
  Update,
} from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";

import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import { useState } from "react";
import { useEffect } from "react";
import SnackbarComponent from "./SnackbarComponent";
import ModifyLocationdetails from "./ModifydataApi";
import Deletelocationdetailsapi from "./Deletelocationapi";
import { useLocation, useNavigate } from "react-router-dom";

const DeleteAlocation = ({ locationid }) => {
  const navigate = useNavigate();
  const [buttonloading, setbuttonloading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const theme = useTheme();
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const colors = tokens(theme.palette.mode);
  const deletelocation = async () => {
    // navigate("/admin/enabledlocations", { replace: true });
    // return;
    const response = await Deletelocationdetailsapi(
      locationid,
      localStorage.getItem("token")
    );
    if (response.success === true) {
      //   setbuttonloading(false);
      setseverity("success");
      setAlertMessage(response.response);
      setShowSnackbar(true);
      navigate("/admin/enabledlocations", { replace: true });
    }
    if (response.success === false) {
      //   setbuttonloading(false);
      setseverity("error");
      setAlertMessage(response.response);
      setShowSnackbar(true);
    }
  };
  if (locationid === undefined || locationid === null) {
    return;
  }
  return (
    <Box m={"0px"} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} alignSelf={'center'}>
      <Box display={"flex"} justifyContent="center" alignItems={'center'}>
        {/* <img src="" alt="" /> */}
        <Button
          disabled={buttonloading}
          startIcon={<Delete />}
           style={{
              borderRadius: 6,
              backgroundColor: "#db4f4a10",
              border: '2px soild #ffffff20',
              // padding: "18px 36px",
              fontSize: "13px",
              height: "34px",
              color:'#db4f4a',
              textTransform: 'none',
              fontFamily: 'poppins-reg',
              marginLeft: "15px"
            }}
          variant="contained"
          component="label"
          onClick={deletelocation}
          //   disabled={false}
        >
          Delete
          {/* <input hidden accept="image/*" multiple type="file" /> */}
        </Button>
      </Box>
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </Box>
  );
};

export default DeleteAlocation;
