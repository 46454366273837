import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { Header } from "../../global/Header";
import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import FirstRow from "./FirstRow";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import SecondRow from "./SecondRow";
import ThirdRow from "./ThirdRow";
import FourthRow from "./FourthRow";
import LogoChange from "./LogoChange";
import FifthRow from "./FifthRow";
import BASE_URL from "../../../NetworkConfig/ApiConfig";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const EditEnabledLocationDetails = () => {
  let query = useQuery();
  console.log(query.get("location"));
  const [IndividualEnabledLocationState, setIndividualEnabledLocationState] =
    useState(null);
  const [error, seterror] = useState("Loading......");
  const FetchEnabledLocationDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/enabledlocation/fetchparticularmain/${query.get("location")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIndividualEnabledLocationState(data.response.Details);
      //   setUsers(data.response.TotalUserOnLocation);
      console.log(data);
      console.log(IndividualEnabledLocationState);
      console.log(data.response.TotalUserOnLocation);
      // IndividualEnabledLocationDetails.TotalUserOnLocation;
      // setloading(false);
      return;
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    FetchEnabledLocationDetails();
  }, []);
  if (!IndividualEnabledLocationState) {
    //   console.log(IndividualEnabledLocationDetails["TotalUsersCountonLocation"]);
    return <Box>{error}</Box>;
  }
  return (
    <Box m={"20px"}>
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={"Edit A Enabled Location"}
          subtitle={`This Location Was Last Updated on:- ${format(
            new Date(IndividualEnabledLocationState.updatedAt),
            "MM/dd/yyyy"
          )}`}
        />
      </Box>
      {/* <Box>
        <Accordion sx={{ backgroundColor: colors.blueAccent[700] }}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Accordion 1</Typography>
          </AccordionSummary>
          <AccordionDetails> */}
      <LogoChange data={IndividualEnabledLocationState} />
      <Box mt={2}>
        <FirstRow data={IndividualEnabledLocationState} />
      </Box>
      <Box mt={2}>
        <SecondRow data={IndividualEnabledLocationState} />
      </Box>
      <Box mt={2}>
        <ThirdRow data={IndividualEnabledLocationState} />
      </Box>
      <Box mt={2}>
        <FourthRow data={IndividualEnabledLocationState} />
      </Box>
      <Box mt={2} mb={4}>
        <FifthRow data={IndividualEnabledLocationState} />
      </Box>
      {/* hii */}
      {/* </AccordionDetails>
        </Accordion>
      </Box> */}
    </Box>
  );
};

export default EditEnabledLocationDetails;
