import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { useTheme } from "@mui/system";
import { tokens } from "../../Theme/theme";
import { Box } from "@mui/material";
import '../../assets/fonts/fonts.css'

const data = [
  {
    id: "MALE",
    // label: "MALE",
    value: 590,
    // colors: "hsl(199, 70%, 50%)",
  },
  {
    id: "FEMALE",
    // label: "FEMALE",
    value: 596,
    // colors: "hsl(35, 70%, 50%)",
  },
  {
    id: "OTHER",
    // label: "OTHER",
    value: 333,
    // colors: "hsl(53, 70%, 50%)",
  },
];
const PieChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <Box>Loading....</Box>;
  }
  return (
    <ResponsivePie
      theme={{
        tooltip: {
          container: {
            // background: colors.grey[500],
            color: colors.primary[500],
          },
        },
        fontFamily:'poppins-reg'
      }}
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={colors.grey[100]}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      colors={{ scheme: 'purple_orange' }}
      arcLabelsTextColor={"#ffffff"}
      // defs={
      //   [
      //     // {
      //     //   id: "dots",
      //     //   type: "patternDots",
      //     //   background: "inherit",
      //     //   color: "rgba(255, 255, 255, 0.3)",
      //     //   size: 4,
      //     //   padding: 1,
      //     //   stagger: true,
      //     // },
      //     // {
      //     //   id: "lines",
      //     //   type: "patternLines",
      //     //   background: "inherit",
      //     //   color: "rgba(255, 255, 255, 0.3)",
      //     //   rotation: -45,
      //     //   lineWidth: 6,
      //     //   spacing: 10,
      //     // },
      //   ]
      // }
      // fill={[
      //   {
      //     match: {
      //       id: "MALE",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "c",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "go",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "python",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "scala",
      //     },
      //     id: "lines",
      //   },
      //   {
      //     match: {
      //       id: "lisp",
      //     },
      //     id: "lines",
      //   },
      //   {
      //     match: {
      //       id: "elixir",
      //     },
      //     id: "lines",
      //   },
      //   {
      //     match: {
      //       id: "javascript",
      //     },
      //     id: "lines",
      //   },
      // ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: colors.grey[100],
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;
