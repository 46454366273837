import { IconButton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
//import buzzlogo from "../../assets/buzz.png";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { tokens } from "../../Theme/theme";
import StorefrontIcon from "@mui/icons-material/Storefront";
import "react-pro-sidebar/dist/css/styles.css";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import '../../assets/fonts/fonts.css'
import buzzlogo from "../../assets/buzz.png";
import { Link, useNavigate } from "react-router-dom";


import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import {
  ChatBubbleSharp,
  ChatSharp,
  GridViewRounded,
  FlutterDashOutlined,
  PeopleOutlineRounded,
  Map,
  MapsHomeWork,
  MenuOutlined,
  NotificationsActiveOutlined,
  SupervisedUserCircle,
  VerifiedUserRounded,
  KeyboardDoubleArrowRightRounded,
  KeyboardDoubleArrowLeftRounded
} from "@mui/icons-material";
const Item = ({ title, to, icon, selected, setselected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();


  const handlelogout = () => {
    localStorage.removeItem("token");
    navigate("/", { replace: true });
  };

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setselected(title)}
      icon={icon}
    >
      <Typography variant="h6" fontFamily={"poppins-reg"}>{title}</Typography>
      <Link to={to}></Link>
    </MenuItem>
  );
};
const SidebarMobile = ({ data, onClick, mobile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // console.log(Date.to);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [selected, setselected] = useState("Dashboard");
  return (
    <Box

     onClick={onClick}
      sx={{
        position:'fixed',
        height:"100%",
        flex:1,
        width:'100%',
        backgroundColor:"#00000040",
        zIndex:30,
        alignSelf:'stretch',
        "& .pro-sidebar-inner": {
          background: `#121319 !important`,
        },

        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 5px 5px 5px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#ffffff40 !important",

        },
        "& .pro-menu-item.active": {
          color: "#706dea !important",
          borderRadius: '60px',
        },
        "& .pro-sidebar ": {
          height: "100%",
          // position: "relative",
        },

        "& .pro-sidebar-content ": {
          height: "200%",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={mobile ? onClick : () => setisCollapsed(!isCollapsed)}
            icon={isCollapsed ? <KeyboardDoubleArrowRightRounded /> : undefined}
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                alignItems="center"
              // position={"sticky"}
              >

                <Box
                  sx={{
                    justifyItems: 'center',
                    borderRadius: "100%",
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    backgroundColor: "#ffffff08",
                  }} width="50px" height="50px">
                  <img
                    alt="profile-user"
                    width="25px"
                    height="25px"
                    style={{ cursor: "pointer", objectFit: 'contain', }}
                    src={buzzlogo}
                  />
                </Box>
                <Box sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', justifyItems:'flex-start', marginLeft:"10px" }}>
                  <Typography variant="h6" flex={1} color={"#ffffff"} fontFamily={"poppins-mid"}>
                    {data.UserName}
                  </Typography>

                  <Typography
                    fontSize={"11px"}
                    color={"#ffffff90"}
                    fontFamily={"poppins-reg"}
                    pl={0.7}
                    pt={0.1}
                    pb={0.1}
                    pr={0.7}
                    mt={0.1}
                    sx={{backgroundColor:"#ffffff20", alignSelf:'flex-start', justifySelf:'flex-start', width:'max-content', borderRadius:"5px"}}
                  >
                    Buzz {data.Role.charAt(0).toUpperCase() + data.Role.slice(1).toLowerCase()}
                  </Typography>
                </Box>

                <IconButton style={{ position: 'absolute', right: 15 }} onClick={mobile ? onClick : () => setisCollapsed(!isCollapsed)}>
                  <KeyboardDoubleArrowLeftRounded />
                </IconButton>
              </Box>

            )}

            <Box sx={{backgroundColor:"#ffffff20", marginTop:"15px", alignSelf:'stretch', height:"1px"}} />
          </MenuItem>
          
          <Box px={"5px"}>
            <Item
              title={"Dashboard"}
              to="/admin/dashboard"
              icon={<GridViewRounded />}
              selected={selected}
              setselected={setselected}
            />
            {/* //Appointments */}
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Appointments
            </Typography> */}
            <Item
              title={"Users"}
              to="/admin/users"
              icon={<PeopleOutlineRounded />}
              selected={selected}
              setselected={setselected}
            />
            <Item
              title={"Enabled Locations"}
              to="/admin/enabledlocations"
              icon={<StorefrontIcon />}
              selected={selected}
              setselected={setselected}
            />
            <Item
              title={"Live Users"}
              to="/admin/liveusers"
              icon={<PersonPinCircleIcon />}
              selected={selected}
              setselected={setselected}
            />
            <Item
              title={"Chats & Matches"}
              to="/admin/chatsandmatches"
              icon={<ChatBubbleSharp />}
              selected={selected}
              setselected={setselected}
            />
            <Item
              title={"Active Users"}
              to="/admin/activeusers"
              icon={<PermIdentityIcon />}
              selected={selected}
              setselected={setselected}
            />
            <Item
              title={"Chirps"}
              to="/admin/chirps"
              icon={<FlutterDashOutlined />}
              selected={selected}
              setselected={setselected}
            />
            <Item
              title={"Send Notification"}
              to="/admin/notify"
              icon={<NotificationsPausedIcon />}
              selected={selected}
              setselected={setselected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default SidebarMobile;
