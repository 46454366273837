import React from "react";
import { tokens } from "../../Theme/theme";
import Statbox from "../Dashborad/Statbox";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import BarChart from "../Charts/BarChart";
import PieChart from "../Charts/PieChart";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Add, ArrowForwardOutlined, Schedule } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { mockDataLocations } from "../../data/mockdata";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Header } from "../global/Header";
import { useEffect } from "react";
import { useState } from "react";
import '../../assets/fonts/fonts.css'
import BASE_URL from "../../NetworkConfig/ApiConfig";
// function renderDate(date) {
//   console.log(date);
//   if (!date) {
//     return "";
//   }
//   return format(new Date(date), "MM/dd/yyyy");
// }
const EnabledLocations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const calculatePercentageChange = (today, yesterday) => {
    if (yesterday === 0 && today == 0) return 0;
    if (yesterday === 0 && today > 0) return 100;
    if (today == 0) return -100;
    const percentageChange = ((today - yesterday) / yesterday) * 100;
    return percentageChange.toFixed(2); // Limit to 2 decimal places
  };

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   // flex: 0.2,
    // },
    {
      field: "Logo",
      headerName: "Logo",
      flex: 0.1,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        // console.log();

        return (
          <Link
            to={{
              pathname: "/admin/enabledlocationdetails",
              search: `?location=${params.id}`,
              state: { fromDashboard: true },
              // console.log(first)
            }}
          >
            <img
              width="30px"
              height="30px"
              style={{ cursor: "pointer", borderRadius: "50%" }}
              src={`${params.row.Logo}`}
              alt=""
            />
          </Link>
        );
      },
    },
    {
      field: "LocationName",
      headerName: "Location Name",
      flex: 0.3,
      //   flex: 0.3,
    },
    {
      field: "Category",
      headerName: "Category",
      flex: 0.2,
    },
    {
      field: "State",
      headerName: "State",
      flex: 0.3,
    },
    {
      field: "District",
      headerName: "District",
      flex: 0.2,
    },
    {
      field: "createdAt",
      headerName: "Onboard Date",
      flex: 0.3,
      type: "date",
      valueFormatter: (params) => {
        if (params.value) {
          const date = format(new Date(params.value), "MM/dd/yyyy");
          return date;
        } else {
          const date = format(new Date("March 7, 2023"), "MM/dd/yyyy");
          return date;
        }
      },
    },
    {
      field: "ReferralCode",
      headerName: "Referral Code",
      flex: 0.3,
      // type: "date",
      renderCell: (params) => {
        if (params.row.ReferralCode) {
          return params.row.ReferralCode;
        } else {
          return "none";
        }
      },
    },
    {
      field: "id",
      headerName: "",
      // flex: 0.3,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        return (
          <Tooltip title={"View Details"}>
            <Link
              to={{
                pathname: "/admin/enabledlocationdetails",
                search: `?location=${params.id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <IconButton>
                <ArrowForwardOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];
  const [EnabledLocationState, setEnabledLocationState] = useState(null);
  // const [loading, setloading] = useState(true);
  const [error, seterror] = useState("Loading......");
  const FetchEnabledLocationDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/enabledlocation/fetchall/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setEnabledLocationState(data.response);
      console.log(data);
      console.log(EnabledLocationState);
      // setloading(false);
      return;
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchEnabledLocationDetails();
  }, []);
  if (!EnabledLocationState) {
    return <Box>{error}</Box>;
  }
  return (
    <Box m="20px">
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Header
          title={"Enabled Locations"}
          subtitle={"Locations which are buzz enabled"}
        />
        <Button
          startIcon={<Add />}
          style={{
            borderRadius: 6,
            backgroundColor: "#706dea",
            border:'2px soild #ffffff20',
            // padding: "18px 36px",
            fontSize: "13px",
            height: "34px",
            textTransform:'none',
            fontFamily:'poppins-reg'
          }}
          variant="contained"
          component={Link}
          to="/admin/addenabledlocation"

        //   disabled={false}
        >
          Add New Location
        </Button>
      </Box>

      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows={"140px"}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={"span 4"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          justifyContent="center"
        >
          <Statbox
            title={EnabledLocationState.Gettotallocations}
            subtitle="Onboarded Locations"
            icon={
              <StorefrontIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            users={false}
            report={0}
            reportSuffix={"No Data Available"}
          />
        </Box>
        <Box
          gridColumn={"span 4"}
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={EnabledLocationState.GetTodayOnboard}
            subtitle="Today's Onboarded"
            icon={
              <InsertInvitationIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            report={calculatePercentageChange(EnabledLocationState.GetTodayOnboard, EnabledLocationState.GetYesterdayOnboard)}
            reportSuffix={calculatePercentageChange(EnabledLocationState.GetTodayOnboard, EnabledLocationState.GetYesterdayOnboard) > 0 
              ? " more than yesterday" : (calculatePercentageChange(EnabledLocationState.GetTodayOnboard, EnabledLocationState.GetYesterdayOnboard) == 0 
              ? "Same as yesterday" : " less than yesterday")
            }
            users={false}
          />
        </Box>
        <Box
          gridColumn={"span 4"}
          backgroundColor={colors.primary[400]}
          display="flex"
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={EnabledLocationState.finusersforrefferalcode}
            subtitle="Users Acquierd On Enabled Locations"
            icon={
              <GroupAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            users={false}
            report={0}
            reportSuffix={"No Data Available"}
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          style={{ height: "100%", width: "100%" }}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
              border: 'none',
              borderBottom: 'none',
              fontStyle: 'normal',
              textDecoration: 'none',
              fontWeight: 'normal',
              fontSize: '12px',
              color: '#ffffff',
              fontFamily: 'poppins-reg',
            },
            "& .name-column--cell": {
              color: "#ffffff10",
              border: 'none'
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg',
              fontSize: "13px",
              letterSpacing: '0.5px',
              color: '#ffffff',
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#ffffff05",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg'
            },
            "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
              fontFamily: 'poppins-reg'
            },
            "& .MuiCheckbox-root": {
              color: `#ffffff10 !important`,
              border: 'none'
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#ffffff !important`,
              paddingX: "13px",
              background: "#ffffff05",
              border: "1.5px solid #ffffff20",
              borderRadius: "5px",
              marginBottom: "10px",
              marginLeft: "auto", // Push the button to the end
            },
          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //   loading={isLoading || !data}
            columnBuffer={2}
            rowBuffer={2}
            rowThreshold={2}
            columnThreshold={2}
            getRowId={(row) => row._id}
            rows={EnabledLocationState.GetAllLocations || []}
            columns={columns}
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          overflow="hidden"
          display={'flex'}
          flexDirection={'column'}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid #ffffff10`}
            colors={colors.grey[100]}
            marginTop={"10px"}
            pb={"15px"}
          >
            <Typography color={colors.grey[100]} variant="h5" fontFamily={"poppins-mid"}>
              Top Performing Referrals
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{
              backgroundColor:"#ffffff10",
              borderRadius:"5px"
            }}
            overflow={"scroll"}
            flexDirection={'column'}
            colors={colors.grey[100]}>
            {EnabledLocationState.Gettopperformingrefferals.map(
              (refferalcode) => {
                if (
                  refferalcode["ReferralCode"]["_id"] === null ||
                  refferalcode["ReferralCode"]["_id"] === undefined ||
                  refferalcode["ReferralCode"]["_id"] === ""
                ) {
                  return;
                }

                return (
                  <Box
                    // key={`${transaction.txId}-${i}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`1px solid #ffffff10`}
                    p="15px"
                    sx={{
                      textDecoration:'none'
                    }}
                    component={Link}
                    to="https://www.google.com/maps/search/?api=1&query=28.6139,77.2090"
                  >
                    <Box>
                      <Typography
                        color={"#ffffff"}
                        style={{
                          fontSize:"13px",
                          textDecoration:'none'
                        }}
                        fontFamily={"poppins-mid"}
                      >
                        {refferalcode["ReferralCode"]["_id"]}
                      </Typography>
                      {/* <Typography color={colors.grey[100]}>{"lat,lon"}</Typography> */}
                    </Box>
                    <Box color={colors.grey[100]} fontFamily={"poppins-mid"}>
                      {refferalcode["ReferralCode"]["value"]}
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>

        </Box>
      </Box>
    </Box>
  );
};

export default EnabledLocations;
