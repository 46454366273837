import React from "react";
import { useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { tokens } from "../../Theme/theme";
import {
  CircleNotifications,
  LocalSeeOutlined,
  NotificationsOffOutlined,
} from "@mui/icons-material";
import { Header } from "../global/Header";
import CustomizedNotification from "./CustomizedNotification";
import BASE_URL from "../../NetworkConfig/ApiConfig";
const SendNotification = () => {
  const theme = useTheme();
  const preset_key = "marketing_admin";
  const cloud_name = "dx8qieggt";
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);
  const [disable, setdisable] = React.useState(false);
  const [severity, setseverity] = useState("");
  const [imageupload, setimageupload] = useState(false);
  const [currentFile, setCurrentFile] = useState("")
  // const [selectedfield, setselectedfield] = useState("buzz_name");
  const [imageuploadtext, setimageuploadtext] = useState("Upload Image");
  const [AlertMessage, setAlertMessage] = useState("");

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [title, settitle] = useState("");
  const [message, setmessage] = useState("");
  const [image, setimage] = useState("");
  const handletitlechange = async (e) => {
    settitle(e.target.value);
  };
  const handlemessagechange = async (e) => {
    setmessage(e.target.value);
  };
  const handleImageChange = async (imageupload) => {
    setCurrentFile(imageupload.name)
    setimageupload(true);
    setimageuploadtext("uploading image...");
    setdisable(true);
    const data = new FormData();
    data.append("file", imageupload);
    data.append("upload_preset", preset_key);
    data.append("cloud_name", cloud_name);
    data.append("folder", "marketing");
    // setLogo();
    console.log(preset_key);
    fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
      method: "POST",
      body: data,
    })
      .then((uploadImageToCloudinary) => {
        console.log(uploadImageToCloudinary);
        return uploadImageToCloudinary.json();
      })
      .then((uploadResponse) => {
        const GetImageUrl = uploadResponse.secure_url;
        console.log(GetImageUrl);
        // const files = e.target.files;
        console.log(GetImageUrl);
        setimage(GetImageUrl);
        setimageupload(false);
        setimageuploadtext("Change Image");
        setdisable(false);
        return true;
      })
      .catch((error) => {
        console.error(error);
        // Handle error
        setimageupload(false);
        setCurrentFile("")
        setimageuploadtext("Upload Image");
        setdisable(false);
        return false;
      });
    // const base64 = await convertBase64(files[0]);
    // console.log(base64);
    // setLogo(base64);
  };
  const formData = new FormData();
  const calculatePayloadSize = (payload) => {
    const jsonString = JSON.stringify(payload);
    const encoder = new TextEncoder();
    const encodedPayload = encoder.encode(jsonString);
    const payloadSizeInBytes = encodedPayload.length / (1024 * 1024);
    return payloadSizeInBytes;
  };

  const SendNotification = async () => {
    console.log("--------------", image);
    setdisable(true);
    const payload = {
      title: title,
      message: message,
      image: image,
    };
    console.log(calculatePayloadSize(payload));
    const token = localStorage.getItem("token");
    console.log(image);
    const response = await fetch(`${BASE_URL}/notify/marketingnotification/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        title: title,
        message: message,
        image: image,
      }),
    });
    const data = await response.json();
    if (data.success === true) {
      //   setlocations(data.response);
      console.log(data);
      setAlertMessage(data.response);
      setseverity("success");
      handleClick();
      setdisable(false);
      //   console.log(locations);
      // setloading(false);
      settitle("");
      setmessage("");
      setimage(null);
      return true;
    }
    if (data.success === false) {
      setAlertMessage("INTERNAL SERVER ERROR");
      setseverity("warning");
      handleClick();
      setdisable(false);
      settitle("");
      setmessage("");
      setimage(null);
    }
  };
  return (
    <Box m={"20px"} paddingBottom={(isMobileScreen || isTabScreen) ? "20px" : "0px"}>
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={"Send Marketing Notifications"}
          subtitle={"Enabled for the Marketing 'PROMOTIONALS'"}
        />
      </Box>

      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gap="20px"
        sx={{
          py: '20px',
          backgroundColor: "#ffffff10",
          border: "1px solid #ffffff10",
          borderRadius: "15px",
          
        }}
      >

        <CustomizedNotification span={(isMobileScreen || isTabScreen) ? "span 12" : "span 6" } />

        <Box
          gridColumn={(isMobileScreen || isTabScreen) ? "span 12" : "span 6" }
          display="flex"
          sx={{
            px: '20px',
            borderLeft: (isMobileScreen || isTabScreen) ? "0px solid #ffffff20" : "1px solid #ffffff20",
            borderTop: (isMobileScreen || isTabScreen) ? "1px solid #ffffff20" : "0px solid #ffffff20",
          }}
          flexDirection={'column'}
        >

          <Typography style={{
            fontFamily: 'poppins-reg',
            fontSize: "16px",
            marginTop: (isMobileScreen || isTabScreen) ? '20px': "10px",
            color: '#ffffff'
          }}>Send Notification to All Buzzers</Typography>

          <TextField
            id=""
            placeholder="Enter notification title..."
            InputLabelProps={{
              style: {
                outline: 'none',
                color: "#ffffff",
                paddingLeft: 7,
                fontFamily: 'poppins-reg',

              },
            }}

            style={{
              outline: 'none',
              borderRadius: '6px',
              marginTop: "50px",
              backgroundColor: "#ffffff10",

            }}
            sx={{
              outline: 'none',
              maxWidth: "450px",
            alignSelf:'stretch',
              input: { color: "#ffffff", fontFamily: 'poppins-reg', px: 2, py: 2 },
              // borderRadius: "10%",
            }}
            value={title}
            onChange={handletitlechange}
          />

          <TextField
            id=""
            placeholder="Enter notification message..."
            InputLabelProps={{
              style: {
                outline: 'none',
                color: "#ffffff",
                paddingLeft: 7,
                fontFamily: 'poppins-reg',
              },
            }}

            style={{
              outline: 'none',
              borderRadius: '6px',
              backgroundColor: "#ffffff10",
            }}
            sx={{
              outline: 'none',
              maxWidth: "450px",
            alignSelf:'stretch',
              marginTop: 2,
              input: { color: "#ffffff", fontFamily: 'poppins-reg', px: 2, py: 2 },
              // borderRadius: "10%",
            }}
            value={message}
            onChange={handlemessagechange}
          />

          <Box display={"flex"} flexDirection={'column'} sx={{ marginTop: '10px' }}>
            <Button
              disabled={imageupload}
              startIcon={<LocalSeeOutlined style={{ fontSize: '13px' }} />}
              style={{
                alignSelf: 'flex-start',
                borderRadius: 5,
                backgroundColor: "#706dea20",
                // padding: "18px 36px",
                fontSize: "13px",
                borderColor: "#706dea",
                fontFamily: 'poppins-reg',
                height: "34px",
                color: '#706dea',
                textTransform: 'none'
              }}
              variant="outlined"
              component="label"
            >
              {imageuploadtext}
              <input
                type="file"
                hidden
                onChange={(e) => handleImageChange(e.target.files[0])}
              />
            </Button>
            {
              currentFile != "" && (
                <Typography style={{
                  fontFamily: 'poppins-reg',
                  fontSize: "13px",
                  color: '#706dea',
                  marginTop: '10px',
                }}>{currentFile}</Typography>
              )
            }

            {image && (
              <img
                width="200px"
                height="100px"
                style={{ cursor: "pointer", borderRadius: "10px", marginTop: '5px', backgroundColor: "#ffffff10", border: '4px solid #ffffff10' }}
                src={image}
                alt=""
              />
            )}

          </Box>

          <Button
            disabled={disable}
            startIcon={<CircleNotifications />}
            style={{
              borderRadius: 5,
              backgroundColor: "#706dea",
              // padding: "18px 36px",
              fontSize: "15px",
              paddingTop: '10px',
              paddingBottom: '10px',
              maxWidth: "450px",
            alignSelf:'stretch',
              marginTop: '50px',
              color: 'white',
              fontFamily: 'poppins-reg',
              textTransform: 'none'
            }}
            onClick={SendNotification}
            component="label"
          >
            Send Notification
          </Button>

        </Box>

      </Box>


      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SendNotification;
