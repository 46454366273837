import { Avatar, Badge, Box } from "@mui/material";
import React from "react";
import { Header } from "../global/Header";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../../NetworkConfig/ApiConfig";

const MeetUpComponent = ({ locationid, showLabel = true }) => {
  console.log("location", locationid);
  const [MeetupState, setMeetupState] = useState(null);
  const Meetupdetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/enabledlocation/meetups/${locationid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setMeetupState(data.response);
      console.log(data.response);
      // IndividualEnabledLocationDetails.TotalUserOnLocation;
      // setloading(false);
      return;
    }
    if (data.success === false) {
      return;
    }
  };
  useEffect(() => {
    Meetupdetails();
  }, []);
  if (!MeetupState) {
    return <Box>{showLabel ? "No Data Available For Meetups On This Location": ""}</Box>;
  }
  return (
    <Box>
      <Header title={"Users Intrested For Meetup"} />
      <Box display={"flex"}>
        {MeetupState.Users.map((Userid) => {
          console.log(Userid);
          return (
            <Box
              component={Link}
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${Userid?.UserId?._id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
              target="_blank"
            >
              <Badge
                color={Userid.Status === "yes" ? "success" : "error"}
                variant="dot"
                //   badgeContent=
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{ m: 2 }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={Userid?.UserId?.image[0]}
                  sx={{ width: 56, height: 56 }}
                />
              </Badge>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MeetUpComponent;
