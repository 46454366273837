import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/system";
import { tokens } from "../../Theme/theme";
import TextField from "@mui/material/TextField";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import buzzlogo from "../../assets/logo.svg";
import login_banner from '../../assets/login_banner.png'
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import '../../assets/fonts/fonts.css'
import BASE_URL from "../../NetworkConfig/ApiConfig";

const theme = createTheme();


const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = React.useState(false);

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  function isOriginalScreen() {

    if (isMobileScreen || isTabScreen) {
      return false
    } else {
      return true
    }
  }

  function getFlex(flex) {
    if (isMobileScreen || isTabScreen) {
      return 1
    } else {
      return flex
    }
  }


  const [severity, setseverity] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("Enter your email and password to login into admin dashboard of buzz.")
  const HandleEmail = (e) => {
    setEmail(e.target.value);
  };
  const HandlePassword = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();

  const formdata = new FormData();
  const [loading, setloading] = useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("token")
    console.log(token)

    if(token != undefined && token != null && token != ""){
      setseverity("success");
      setloading(false);
      navigate("/admin/dashboard", { replace: true });
    }
  }, [])
  
  const OnSubmit = async () => {
    setloading(true);
    try {
      formdata.append("Email", Email);
      formdata.append("Password", Password);
      const response = await fetch(`${BASE_URL}/admin/login/`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          // "auth-token": token,
        },
        body: formdata,
      });
      const json = await response.json();
      if (json.success === true) {
        setseverity("success");
        setAlertMessage(json.response);
        localStorage.setItem("token", json.token);
        setEmail("");
        setPassword("");
        handleClick();
        setloading(false);
        navigate("/admin/dashboard", { replace: true });
        return true;
      }
      if (json.success === false) {
        setseverity("warning");
        setAlertMessage(json.response);
        handleClick();
        setloading(false);

        return false;
      }
    } catch (error) {
      setErrMsg(error.toString())
      setseverity("warning");
      setAlertMessage("Please check your network connection");
      handleClick();
      setloading(false);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "#000000",
        }}
        display="flex"
        flexDirection={"row"}

      >


        <Box
          sx={{
            height: "100%",
            filter: isOriginalScreen() ? 'blur(0px)' : 'blur(70px)',
          }}
          flex={getFlex(0.6)}
        >

          <img
            width="100%"
            height="100%"
            src={login_banner}
            style={{ objectFit: 'cover' }}
          />

        </Box>


        <Box
          sx={{
            height: "100%",
            width: isOriginalScreen() ? "auto" : "100%"
            // Apply blur to the background
          }}
          flexDirection={'column'}
          justifyContent={'center'}
          alignContent={'center'}
          display={'flex'}
          position={isOriginalScreen() ? "none" : "absolute"}
          flex={isOriginalScreen() ? 0.4 : 1}
        >


          <img
            width={100}
            height={40}
            src={buzzlogo}
            style={{ objectFit: 'contain', position: 'absolute', top: 40,  left:40}}
          />


          <Box paddingY={8} position={'absolute'} alignSelf={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>


            <Typography
              marginLeft={5}
              marginRight={5}
              sx={{ fontSize: isMobileScreen ? 22 : 28, textAlign: 'center', fontFamily: 'poppins-bold' }}
            >Admin Dashboard Login</Typography>
            <Typography
              marginLeft={5}
              marginRight={5}
              maxWidth={isMobileScreen ? 260 : 300}
              sx={{ textAlign: 'center', fontSize: isMobileScreen ? 11 : 13, fontFamily: 'poppins-reg', color: '#ffffff50' }}
            >{errMsg}</Typography>


            <TextField
              value={Email} type="email"
              sx={{ width: isMobileScreen ? 280 : 340, marginTop: 5 }}
              onChange={HandleEmail}
              InputProps={{
                sx: {
                  fontFamily: "poppins-reg",
                }
              }}
              placeholder="Email address" />

            <TextField
              value={Password} type="password"
              sx={{ width: isMobileScreen ? 280 : 340, marginTop: 2 }}
              onChange={HandlePassword}
              InputProps={{
                sx: {
                  fontFamily: "poppins-reg",
                }
              }}
              placeholder="Password" />


            <Button

              sx={{
                bgcolor: "#706dea",
                fontFamily: 'poppins-mid',
                textTransform: 'none',
                width: isMobileScreen ? 280 : 340,
                marginTop: 5,
                paddingY: 1.5,
                fontSize: 13,
                borderRadius: 2,
                color: '#ffffff',
              }}
              onClick={OnSubmit}
            >{loading ? "Verifying..." : "Login and Continue"}
            </Button>


          </Box>


        </Box>

      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
