import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Box, color, useTheme } from "@mui/system";
import { tokens } from "../../Theme/theme";
import '../../assets/fonts/fonts.css'

const LineChart = ({ data, color, enableArea }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    console.log(data);
    if (!data) {
        return <Box></Box>;
    }
    return (
        // <Box>
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: '0',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            axisTop={null}
            curve="catmullRom"
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: enableArea ? "Month" : 'Date',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: enableArea ? 'Accounts Created' : 'Active Users',
                legendOffset: -40,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            theme={{
                tooltip: {
                    container: {
                        background: "#303030",
                        color: "#ffffff",
                    },
                },

                // background: "#ffff",
                fontSize:"9px",
                textColor:"#ffffff",
                fontFamily: 'poppins-reg',
                grid: {
                    line: {
                        stroke: "#ffffff10", // Set the color of the grid lines here
                        strokeWidth: 0.5,
                    },
                },
            }}
            colors={{ scheme: color }}
            pointSize={5}
            enableArea={enableArea}
            enablePointLabel={true}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={"skyblue"}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-left',
                    direction: 'column',
                    justify: false,
                    translateX: -5,
                    translateY: 46,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
        // </Box>
    );
};

export default LineChart;
