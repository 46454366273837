import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { Header } from "../global/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../Theme/theme";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import './CustomActiveUser.css'
import BASE_URL from "../../NetworkConfig/ApiConfig";

const ActiveUser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UsersState, setUsersState] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchedDate, setSearchedDate] = useState(new Date())

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')


  // const [loading, setloading] = useState(true);
  const [error, seterror] = useState("Loading.....");
  const FetchData = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/active/gettodayandyesterdayusers/${selectedDate.toISOString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setSearchedDate(selectedDate)
      setUsersState(data.todayUsers);
      console.log(data);
      console.log(UsersState);

      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchData();
  }, []);
  if (!UsersState) {
    return <Box>{error}</Box>;
  }
  const handleDateChange = (e) => {
    const { value } = e.target;
    setSelectedDate(new Date(value));
  };
  const HandleDateClick = () => {
    // setlocations(null);
    setUsersState(null);
    FetchData();
  };
  const HandleResetClick = () => {
    // setlocations(null);
    setUsersState(null);
    setSelectedDate(new Date());
    FetchData();
  };
  return (
    <Box m="20px">
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Header
          title={`Active Users`}
          subtitle={`List Of Users Who Opened The App ${format(searchedDate, "MMM dd, yyyy") == format(new Date(), "MMM dd, yyyy") ? "Today" : `On ${format(searchedDate, "MMMM dd, yyyy")}`} `}
        />
      </Box>
      <Box mb={3} display={"flex"} flexDirection={isMobileScreen ? "column" : "row"} justifyContent="flex-start" alignItems={ isMobileScreen ? "flex-start" : "center"}>
        <input
          type="date"
          name=""
          id=""
          value={selectedDate.toISOString().split("T")[0]}
          onChange={handleDateChange}
          style={{
            paddingLeft: "10px", paddingTop: '8px',
            paddingRight: "10px", paddingBottom: '8px',
            fontFamily: 'poppins-reg',
            backgroundColor: "#ffffff00",
            border: '1px solid #ffffff20',
            color: '#ffffff',
            borderRadius:'6px',
            outline: 'none',
            fontSize:"13px"
          }}
        />

        <Box sx={{flexDirection:'row', alignItems:'center', marginLeft: isMobileScreen ? "0px" : "10px", marginTop: isMobileScreen? "10px" : "0px"}}>
           <Button
          // startIcon={<Add />}
          style={{
            width: 'max-content',
            fontSize: "13px",
            paddingLeft: "15px", paddingTop: '7px',
            paddingRight: "15px", paddingBottom: '7px',
            backgroundColor: "#ffffff15",
            borderRadius: "6px",
            border: "1px solid #ffffff25",
            textAlign: 'center',
            fontFamily:'poppins-reg',
            textTransform:'none'
          }}
          variant="contained"
          onClick={HandleDateClick}
        >
          Search
        </Button>
        <Button
          // startIcon={<Add />}
          style={{
            width: 'max-content',
            fontSize: "13px",
            paddingLeft: "15px", paddingTop: '7px',
            paddingRight: "15px", paddingBottom: '7px',
            backgroundColor: "#ffffff15",
            borderRadius: "6px",
            border: "1px solid #ffffff25",
            marginLeft:"10px",
            textAlign: 'center',
            fontFamily:'poppins-reg',
            textTransform:'none'
          }}
          variant="contained"
          // component={Link}
          // to="/admin/addenabledlocation"
          onClick={HandleResetClick}
          //   disabled={false}
        >
         ⟳ Reset
        </Button>
        </Box>

       
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gap="20px"
        pb={"20px"}
      >
        {UsersState &&
          UsersState.map((user) => {
            return (
              <Box
                  // key={`${transaction.txId}-${i}`}
                  gridColumn= { isMobileScreen ? "span 12" : isTabScreen ? "span 6" : "span 3"}
                  gridRow="span 2"
                  display="flex"
                  alignItems="center"
                  px="15px"
                  py={"8px"}
                  component={Link}
                  backgroundColor={"#ffffff05"}
                  target={"_blank"}
                  borderRadius={"10px"}
                  to={{
                    pathname: "/admin/particularuserdetails",
                    search: `?userid=${user?.UserId?._id}`,
                    state: { fromDashboard: true },
                    // console.log(first)
                  }}

                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      alt="profile-user"
                      width="50px"
                      height="50px"
                      src={` ${user?.UserId?.image[0]}`}
                      style={{
                        cursor: "pointer", objectFit: 'cover',
                        borderRadius: "50%",
                        border: "5px solid #ffffff10"
                      }}
                    />
                  </Box>
                  <Box sx={{ ml: "10px", display: 'flex', flexDirection: 'column' }}>
                    <Typography color={"#ffffff"}
                      fontFamily={"poppins-reg"}
                      fontSize={"13px"}>{user?.UserId?.full_name}</Typography>

                    <Typography color={"#ffffff90"}
                      fontFamily={"poppins-reg"}
                      style={{
                        alignSelf: "flex-start",
                        border: "1px solid #ffffff10",
                        borderRadius: "4px",
                        paddingTop: "1px",
                        marginTop: "1px",
                        paddingBottom: "1px",
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                      fontSize={"10px"}>{user?.UserId?.buzz_name}</Typography>
                  </Box>
                </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default ActiveUser;
