import { Box, useTheme, Typography, Tooltip, IconButton } from "@mui/material";
import React from "react";
import Statbox from "../Dashborad/Statbox";
import { Header } from "../global/Header";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import { tokens } from "../../Theme/theme";
import { ArrowForwardOutlined, MarkChatUnread } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Chats, RightSwiped } from "../../data/mockdata";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import BASE_URL from "../../NetworkConfig/ApiConfig";

const ChatsAndMatches = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, seterror] = useState("Loading");

  // const [state, setstate] = useState(initialState);
  const [ChatsAndMatches, setChatsAndMatches] = useState(null);
  const FetchChatMatchesDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/chatsandmatches/details/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setChatsAndMatches(data.response);
      console.log(data);
      console.log(ChatsAndMatches);
      // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchChatMatchesDetails();
  }, []);
  if (!ChatsAndMatches) {
    return <Box>{error}</Box>;
  }
  const columnsForRightwipedto = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.5,
    //   // hide: true,
    // },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 0.3,
    },
    {
      field: "buzz_name",
      headerName: "Buzz Name",
      flex: 0.3,
    },
    {
      field: "RightSwipedtoCount",
      headerName: "Right Swipes",
      // flex: 0.5,
    },
    {
      field: "id",
      headerName: "VIEW DETAILS",
      flex: 0.1,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        return (
          <Tooltip title={"View Details"}>
            <Link
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${params.id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <IconButton>
                <ArrowForwardOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];
  const columnsForRightwipedby = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.5,
    //   // hide: true,
    // },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 0.3,
    },
    {
      field: "buzz_name",
      headerName: "Buzz Name",
      flex: 0.3,
    },
    {
      field: "count",
      headerName: "Right Swipes",
      // flex: 0.5,
    },
    {
      field: "id",
      headerName: "VIEW DETAILS",
      flex: 0.1,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        return (
          <Tooltip title={"View Details"}>
            <Link
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${params.id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <IconButton>
                <ArrowForwardOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];
  const chatscolums = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 0.2,
    // },
    {
      field: "FromUser",
      headerName: "FromUser",
      flex: 0.2,
    },
    {
      field: "ToUser",
      headerName: "ToUser",
      flex: 0.2,
    },

    {
      field: "count",
      headerName: "chats",
      // flex: 0.5,
    },
    {
      field: "said",
      headerName: "VIEW DETAILS",
      flex: 0.1,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        console.log(params);
        return (
          <Tooltip title={"View Details"}>
            <Link
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${params.row.FromUserId}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <IconButton>
                <ArrowForwardOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];
  const columns = [
    { field: "_id.FromUser", headerName: "From User", width: 150 },
    { field: "_id.ToUser", headerName: "To User", width: 150 },
    { field: "count", headerName: "Message Count", width: 150 },
  ];
  const generateLink = (params) => {
    console.log(params);
    const selectedRowId = params.id;
    // Generate the link using the selected row ID
    return `/particularuserdetails?userid=${selectedRowId}`;
  };
  return (
    <Box m="20px">
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Header
          title={"Chats & Matches"}
          subtitle={"Aggregated Data For Chats And Matches"}
        />
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows={"140px"}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={"span 6"}
          backgroundColor={colors.primary[400]}
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={ChatsAndMatches.totalMatches}
            subtitle="Total Matches"
            icon={
              <JoinInnerIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            reportSuffix={"No Data Available"}
          />
        </Box>
        <Box
          gridColumn={"span 6"}
          backgroundColor={colors.primary[400]}
          display="flex"
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={ChatsAndMatches.totalchats}
            subtitle="Total Messages"
            icon={
              <MarkChatUnread
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            reportSuffix={"No Data Available"}
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          mt={3}
          sx={{
           "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
              border: 'none',
              borderBottom: 'none',
              fontStyle: 'normal',
              textDecoration: 'none',
              fontWeight: 'normal',
              fontSize: '12px',
              color: '#ffffff',
              fontFamily: 'poppins-reg',
            },
            "& .name-column--cell": {
              color: "#ffffff10",
              border: 'none'
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg',
              fontSize: "13px",
              letterSpacing: '0.5px',
              color: '#ffffff',
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#ffffff05",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg'
            },
            "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
              fontFamily: 'poppins-reg'
            },
            "& .MuiCheckbox-root": {
              color: `#ffffff10 !important`,
              border: 'none'
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#ffffff !important`,
              paddingX: "13px",
              background: "#ffffff05",
              border: "1.5px solid #ffffff20",
              borderRadius: "5px",
              marginBottom: "10px",
              marginLeft: "auto", // Push the button to the end
            },
          }}
        >
          <Box display="flex" alignItems={"center"} >
          <Typography variant="h5" color={"#ffffff"} mb={1} ml={0.5} fontFamily={"poppins-mid"}>
          Individual Profile Right Swipes
          </Typography>
          </Box>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //   loading={isLoading || !data}
            density="comfortable"
            getRowId={(row) => row._id}
            rows={ChatsAndMatches.RightSwipedto || []}
            columns={columnsForRightwipedto}

            // onRowClick={(params) => (
            //   <Link
            //     target="_blank"
            //     to={`particularuserdetails?userid=${params.id}`}
            //   />
            // )}
          />
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          mt={3}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
              border: 'none',
              borderBottom: 'none',
              fontStyle: 'normal',
              textDecoration: 'none',
              fontWeight: 'normal',
              fontSize: '12px',
              color: '#ffffff',
              fontFamily: 'poppins-reg',
            },
            "& .name-column--cell": {
              color: "#ffffff10",
              border: 'none'
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg',
              fontSize: "13px",
              letterSpacing: '0.5px',
              color: '#ffffff',
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#ffffff05",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg'
            },
            "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
              fontFamily: 'poppins-reg'
            },
            "& .MuiCheckbox-root": {
              color: `#ffffff10 !important`,
              border: 'none'
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#ffffff !important`,
              paddingX: "13px",
              background: "#ffffff05",
              border: "1.5px solid #ffffff20",
              borderRadius: "5px",
              marginBottom: "10px",
              marginLeft: "auto", // Push the button to the end
            },
          }}
        >
          <Box display="flex" alignItems={"center"} >
            <Typography variant="h5" color={"#ffffff"} mb={1} ml={0.5} fontFamily={"poppins-mid"}>
             Individual Profile Right Swiped Received
            </Typography>
          </Box>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            density="comfortable"
            //   loading={isLoading || !data}
            getRowId={(row) => row.UserId}
            rows={ChatsAndMatches.RightSwipedby}
            columns={columnsForRightwipedby}
          />
        </Box>
        <Box
          mt={5}
          gridColumn="span 12"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
              border: 'none',
              borderBottom: 'none',
              fontStyle: 'normal',
              textDecoration: 'none',
              fontWeight: 'normal',
              fontSize: '12px',
              color: '#ffffff',
              fontFamily: 'poppins-reg',
            },
            "& .name-column--cell": {
              color: "#ffffff10",
              border: 'none'
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg',
              fontSize: "13px",
              letterSpacing: '0.5px',
              color: '#ffffff',
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#ffffff05",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg'
            },
            "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
              fontFamily: 'poppins-reg'
            },
            "& .MuiCheckbox-root": {
              color: `#ffffff10 !important`,
              border: 'none'
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#ffffff !important`,
              paddingX: "13px",
              background: "#ffffff05",
              border: "1.5px solid #ffffff20",
              borderRadius: "5px",
              marginBottom: "10px",
              marginLeft: "auto", // Push the button to the end
            },
          }}
        >
          <Box display="flex" alignItems={"center"} >
          <Typography variant="h5" color={"#ffffff"} mb={1} ml={0.5} fontFamily={"poppins-mid"}>
              Total Number of Messages Exchange Between
            </Typography>
          </Box>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            density="comfortable"
            //   loading={isLoading || !data}
            // getRowId={(row) =>
            //   // console.log(row["data"][0]["uniqueid"]);
            //   row["data"][0]["uniqueid"]
            // }
            rows={ChatsAndMatches.chats}
            columns={chatscolums}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatsAndMatches;
