import { Box, useTheme, Typography, Button } from "@mui/material";
import logo from "../../assets/buzz.png";
// import mark from "../../assets/mark.jpg";
import enabledlocation from "../../assets/enabledlocation.svg";
import male from "../../assets/maleinstant.svg";
import { width } from "@mui/system";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  GoogleMap,
  InfoWindow,
  InfoWindowF,
  Marker,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useMemo, useState, useEffect } from "react";
import './MapStyle.css'
import '../../assets/fonts/fonts.css'
import { Link } from "react-router-dom";
import { tokens } from "../../Theme/theme";
import BASE_URL from "../../NetworkConfig/ApiConfig";
const Convert_Time_Zone = (isostring) => {
  const isoString = isostring;
  const desiredFormat = "dd MMMM yyyy, hh:mm:ss a";

  const indianTimeZone = "Asia/Kolkata";
  const zonedTime = utcToZonedTime(isoString, indianTimeZone);

  const formattedDate = format(zonedTime, desiredFormat, {
    timeZone: indianTimeZone,
  });

  return formattedDate;
};
const UserOnMap = ({ id }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC-PugLitlASB_4vOx7RLi0aAwmDUh0er0",
  });
  if (!isLoaded) return <Box>Loading.....MAP</Box>;
  // console.log(locationdata);
  // if (!locationdata) {
  //   return <Box>User Has Not Yet Used Buzz 2.0</Box>;
  // }
  return <Map id={id} />;
};

function Map({ id }) {
  const [locations, setlocations] = useState(null);
  const [UserLastLocation, setUserLastLocation] = useState(null);

  const [center, setcenter] = useState({ lat: 20.5937, lng: 78.9629 });
  const [selectedDate, setSelectedDate] = useState(null);
  const Fetchlivedetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/live/livedetails/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setlocations(data.response);
      console.log(data);
      console.log(locations);
      // setloading(false);
    }
    const response2 = await fetch(`${BASE_URL}/user/onparticulardate/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        Dategiven: selectedDate,
      }),
    });
    const data2 = await response2.json();
    if (data2.success === true) {
      setUserLastLocation(data2.response);
      console.log(data2);
      console.log(UserLastLocation);
      // setloading(false);
    }
  };

  useEffect(() => {
    Fetchlivedetails();
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedMarker, setSelectedMarker] = useState(null);

  if (!locations) {
    return <Box>FETCHING LOCATIONS....</Box>;
  }
  if (!UserLastLocation) {
    return <Box>USER IS NOT YET USING BUZZ 2.0 hehe</Box>;
  }
  const handleDateChange = (e) => {
    const { value } = e.target;
    setSelectedDate(value);
  };
  const HandleDateClick = () => {
    setlocations(null);
    setUserLastLocation(null);
    Fetchlivedetails();
  };
  const HandleResetClick = () => {
    setlocations(null);
    setUserLastLocation(null);
    setSelectedDate(null);
    Fetchlivedetails();
  };

  const darkThemeStyles = [
    {
      elementType: "geometry",
      stylers: [{ color: "#212121" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#212121" }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#181818" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#1b1b1b" }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{ color: "#2c2c2c" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#8a8a8a" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: "#373737" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#3c3c3c" }],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [{ color: "#4e4e4e" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#000000" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#3d3d3d" }],
    },
  ];

  return (
    <Box sx={{ flexDirection: 'column', display: 'flex' }}>

      <GoogleMap
        onClick={() => setSelectedMarker(null)}
        center={center}
        zoom={4}
        options={{ styles: darkThemeStyles }}
        mapContainerStyle={{ height: "300px", width: "600px", borderRadius: "15px", backgroundColor: "#ffffff10", border: "5px solid #ffffff10", marginTop: "10px" }}
      >
        {locations.map((marker) => {
          return (
            <MarkerF
              icon={enabledlocation}
              key={marker._id}
              position={{
                lat: parseFloat(marker["LocationCoordinate"][0]),
                lng: parseFloat(marker["LocationCoordinate"][1]),
              }}
              onClick={() => {
                setSelectedMarker(marker);
                // setcenter(marker.position);
                console.log(marker);
              }}
            >
              {selectedMarker && selectedMarker._id === marker._id && (
                <InfoWindowF onCloseClick={() => setSelectedMarker(null)}>
                  <div>
                    <Box
                      display="flex"
                      alignItems={"center"}
                      justifyContent="space-between"
                      component={Link}
                      to={{
                        pathname: "/admin/enabledlocationdetails",
                        search: `?location=${marker._id}`,
                        state: { fromDashboard: true },
                        // console.log(first)
                      }}
                      target="_blank"
                    >
                      <img
                        width="60px"
                        height="60px"
                        src={marker.Logo}
                        style={{ cursor: "pointer", borderRadius: "50%" }}
                      />
                      <Box pl={3}>
                        <Typography variant="h4" color={colors.primary[500]}>
                          {marker.LocationName}
                        </Typography>
                        {/* <Typography variant="h6" color={colors.primary[500]}>
                        {marker.buzz_line}
                      </Typography> */}
                      </Box>
                    </Box>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          );
        })}
        {UserLastLocation?.map((marker) => {
          // console.log(marker);
          // return;
          // console.log(object)
          return (
            <MarkerF
              // icon={male}
              key={marker._id}
              position={{
                lat: parseFloat(marker["LocationCoordinates"][0]),
                lng: parseFloat(marker["LocationCoordinates"][1]),
              }}
              onClick={() => {
                setSelectedMarker(marker);
                // setcenter(marker.position);
                console.log(marker);
              }}
            >
              {selectedMarker && selectedMarker._id === marker._id && (
                <InfoWindowF onCloseClick={() => setSelectedMarker(null)}>
                  <div>
                    <Box
                      display="flex"
                      alignItems={"center"}
                      justifyContent="space-between"
                    // component={Link}
                    // to={{
                    //   pathname: "/admin/enabledlocationdetails",
                    //   search: `?location=${marker._id}`,
                    //   state: { fromDashboard: true },
                    //   // console.log(first)
                    // }}
                    // target="_blank"
                    >
                      <Box pl={3}>
                        <Typography variant="h4" color={colors.primary[500]}>
                          {Convert_Time_Zone(marker.createdAt)}
                        </Typography>
                        {/* <Typography variant="h6" color={colors.primary[500]}>
                        {marker.buzz_line}
                      </Typography> */}
                      </Box>
                    </Box>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          );
        })}

        <Box display={"flex"} sx={{
          position: 'absolute', bottom: 0, borderBottomLeftRadius: "5px", borderTopRightRadius: '20px', paddingRight:"10px", paddingTop:'4px',
          background: "linear-gradient(#ffffff20 0 0) padding-box, linear-gradient(#0b0b0f 0 0) padding-box, linear-gradient(45deg,#ffffff30, #00000000) border-box"

        }} >
          <Box
            display={"flex"}
            justifyContent="space-around"
            alignItems={"center"}
          >
            <input
              type="date"
              name=""
              id=""
              value={selectedDate}
              onChange={handleDateChange}
              style={{
                paddingLeft: "10px", paddingTop: '8px',
                paddingRight: "10px", paddingBottom: '8px',
                fontFamily: 'poppins-reg',
                backgroundColor: "#ffffff00",
                borderLeft: 'none',
                borderBottom: 'none',
                borderTop: 'none',
                borderRight: '1px solid #ffffff20',
                color: '#ffffff',
            
                outline: 'none'
              }}
            />

            <Button
              // startIcon={<Add />}
              style={{
                width: 'max-content',
                paddingLeft: "15px",
                fontSize: "11px",
                marginLeft:"8px",
                paddingRight: "15px",
                paddingTop: "2px",
                paddingBottom: "2px",
                backgroundColor: "#ffffff15",
                borderRadius: "50px",
                border: "1px solid #ffffff25",
                m: "8px",
                textAlign: 'center',
                fontFamily:'poppins-reg',
                textTransform:'none'
              }}
              variant="contained"
              // component={Link}
              // to="/admin/addenabledlocation"
              onClick={HandleDateClick}
            //   disabled={false}
            >
              Search
            </Button>
            <Button
              // startIcon={<Add />}
              style={{
                cursor: 'pointer',
                width: 'max-content',
                paddingLeft: "15px",
                fontSize: "11px",
                marginLeft:"8px",
                paddingRight: "15px",
                paddingTop: "2px",
                paddingBottom: "2px",
                backgroundColor: "#ffffff15",
                borderRadius: "50px",
                border: "1px solid #ffffff25",
                m: "8px",
                textAlign: 'center',
                fontFamily:'poppins-reg',
                textTransform:'none'
              }}
              variant="contained"
              // component={Link}
              // to="/admin/addenabledlocation"
              onClick={HandleResetClick}
            //   disabled={false}
            >
              ↻ Reset
            </Button>
          </Box>
          {/* <Button
          startIcon={<Add />}
          style={{
            borderRadius: 12,
            backgroundColor: colors.greenAccent[500],
            // padding: "18px 36px",
            fontSize: "18px",
            height: "34px",
          }}
          variant="contained"
          component={Link}
          to="/admin/addenabledlocation"

          //   disabled={false}
        >
          ADD ENABLED LOCATIONS
        </Button> */}
        </Box>
      </GoogleMap>

    </Box>
  );
}

export default UserOnMap;
