import { ExpandMoreOutlined, Save } from "@mui/icons-material";
import {
  Add,
  Camera,
  CameraAltOutlined,
  CropOriginal,
  Delete,
  DeleteForever,
  DeleteOutline,
  Update,
} from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";

import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import { useState } from "react";
import { useEffect } from "react";
import { statedistrict } from "../../../data/statedistrict";
import ModifyLocationdetails from "./ModifydataApi";
import SnackbarComponent from "./SnackbarComponent";

const SecondRow = ({ data }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [State, setState] = useState("");
  const [StateError, setStateError] = useState(false);
  const [District, setDistrict] = useState("");
  const [DistrictError, setDistrictError] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [ReferralCode, setReferralCode] = useState("");
  const [buttonloading, setbuttonloading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const ModifyData = async () => {
    try {
      setbuttonloading(true);
      if (
        StateError === true ||
        DistrictError === true
        //  LongitudeError === true
      ) {
        console.log(1);
        setbuttonloading(false);

        return false;
      }
      if (ReferralCode === "") {
        setReferralCode(null);
      }
      const response = await ModifyLocationdetails(
        data._id,
        localStorage.getItem("token"),
        {
          State: State,
          District: District,
          ReferralCode: ReferralCode,
        }
      );
      if (response.success === true) {
        setbuttonloading(false);
        setseverity("success");
        setAlertMessage("Data Saved Successfully");
        setShowSnackbar(true);
      }
      if (response.success === false) {
        setbuttonloading(false);
        setseverity("error");
        setAlertMessage("INTERNAL SERVER ERROR");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRefferalCodeChange = (e) => {
    const { value } = e.target;
    setReferralCode(value);
    // if (value.length < 1) {
    //   setReferralCodeError(true);
    //   return false;
    // }
    // setReferralCodeError(false);
    return true;
  };
  const handleStateChange = (event) => {
    setStateError(false);
    setState(event.target.value);
    setDistricts(
      statedistrict.find((state) => state.state === event.target.value)
        .districts
    );
  };
  const handleDistrictChange = (event) => {
    setDistrictError(false);
    setDistrict(event.target.value);
  };
  useEffect(() => {
    console.log(data);
    if (data) {
      if (data.ReferralCode === null) {
        setReferralCode("");
      } else {
        setReferralCode(data.ReferralCode);
      }
      setState(data.State);
      if (data.State) {
        setDistricts(
          statedistrict.find((state) => state.state === data.State).districts
        );
        if (setDistricts.length > 0) {
          setDistrict(data.District);
        }
      }
    }
  }, []);
  return (
    <Box>
      <Accordion sx={{ backgroundColor: colors.blueAccent[900] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          STATE,DISTRICT,REFERRAL CODE
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display={"grid"}
            gridTemplateColumns="repeat(12,1fr)"
            gridAutoRows={"80px"}
            gap="20px"
          >
            <Box gridColumn={"span 4"}>
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900] },
                  color: colors.grey[900],
                }}
                error={StateError}
              >
                <InputLabel
                  sx={{
                    color: colors.blueAccent[800],
                    paddingLeft: 1,
                    fontWeight: "bold",
                  }}
                  id="demo-simple-select-standard-label"
                >
                  Select State
                </InputLabel>
                <Select
                  labelStyle={{ color: "#ff0000" }}
                  sx={{
                    color: "white",
                    ".MuiSelect-select": {
                      color: colors.grey[900],
                      pl: 1,
                      fontWeight: "bold",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={State}
                  onChange={handleStateChange}
                >
                  {statedistrict.map((state) => (
                    <MenuItem key={state.code} value={state.state}>
                      {state.state}
                    </MenuItem>
                  ))}
                </Select>
                {StateError ? (
                  <FormHelperText>Error</FormHelperText>
                ) : undefined}
              </FormControl>
            </Box>
            <Box gridColumn={"span 4"}>
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900] },
                  color: colors.grey[900],
                }}
                error={DistrictError}
              >
                <InputLabel
                  sx={{
                    color: colors.blueAccent[800],
                    paddingLeft: 1,
                    fontWeight: "bold",
                  }}
                  id="demo-simple-select-standard-label"
                >
                  Select District
                </InputLabel>
                <Select
                  labelStyle={{ color: "#ff0000" }}
                  sx={{
                    color: "white",
                    ".MuiSelect-select": {
                      color: colors.grey[900],
                      pl: 1,
                      fontWeight: "bold",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  // value={}
                  value={District}
                  onChange={handleDistrictChange}
                  disabled={districts.length === 0}
                >
                  <MenuItem value="">
                    <em>PLEASE SELECT FROM THE FOLLOWING</em>
                  </MenuItem>
                  {districts.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
                {DistrictError ? (
                  <FormHelperText>Select District</FormHelperText>
                ) : undefined}
              </FormControl>
            </Box>
            <Box
              gridColumn={"span 4"}
              //   backgroundColor={colors.primary[400]}
              //   gridRow="span 3"
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <TextField
                // gridColumn="span 8"
                // gridRow="span 3"
                id=""
                label="REFFERAL CODE"
                // error={ReferralCodeError}
                // helperText={ReferralCodeError ? "Enter Refferal Code" : null}
                //   color={colors.greenAccent[200]}
                //   fullWidth
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  //   marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
                InputProps={{}}
                value={ReferralCode}
                onChange={handleRefferalCodeChange}
              />
            </Box>
          </Box>
          <Box pb={"10px"} display={"flex"} justifyContent="center">
            <Button
              disabled={buttonloading}
              startIcon={<Save />}
              style={{
                borderRadius: 12,
                backgroundColor: colors.greenAccent[500],
                // padding: "18px 36px",
                fontSize: "18px",
                height: "34px",
                marginBottom: "2px",
              }}
              variant="contained"
              component="label"
              onClick={ModifyData}
              //   disabled={false}
            >
              Save Changes
              {/* {buttonloading === true ? buttontext : "ADD THE LOCATION"} */}
              {/* <input hidden accept="image/*" multiple type="file" /> */}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </Box>
  );
};

export default SecondRow;
