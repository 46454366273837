import { Add, CameraAltOutlined } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { tokens } from "../../Theme/theme";
import { Header } from "../global/Header";
import { useState } from "react";
import * as EmailValidator from "email-validator";
import BASE_URL from "../../NetworkConfig/ApiConfig";

const AddAdmins = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [severity, setseverity] = useState("");
  const [AlertMessage, setAlertMessage] = useState("");
  const [buttonloading, setbuttonloading] = useState(false);
  const [buttontext, setbuttontext] = useState("");
  const [UserName, setUserName] = useState("");
  const [Role, setRole] = useState("MANAGER");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [UserNameError, setUserNameError] = useState(false);
  const [RoleError, setRoleError] = useState(false);
  const [PasswordError, setPasswordError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    if (EmailValidator.validate(value) === false) {
      setEmailError(true);
      return false;
    }
    setEmailError(false);
    return true;
  };
  const handleUserNameChange = (e) => {
    const { value } = e.target;
    setUserName(value);
    if (value.length < 1) {
      setUserNameError(true);
      return false;
    }
    setUserNameError(false);
    return true;
  };
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    if (value.length < 1) {
      setPasswordError(true);
      return false;
    }
    setPasswordError(false);
    return true;
  };
  const handleCPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);

    return true;
  };
  const handleRoleChange = (event) => {
    setRoleError(false);
    setRole(event.target.value);
  };
  const formdata = new FormData();
  const handleSubmit = async () => {
    try {
      setbuttonloading(true);
      if (EmailValidator.validate(Email) === false) {
        setEmailError(true);
        setbuttonloading(false);

        return false;
      }
      if (Password.length < 1) {
        setPasswordError(true);
        setbuttonloading(false);

        return false;
      }
      if (UserName.length < 1) {
        setUserNameError(true);
        setbuttonloading(false);

        return false;
      }
      if (Role.length < 1) {
        setbuttonloading(false);

        setRoleError(true);
        return false;
      }
      if (Password !== ConfirmPassword) {
        setbuttonloading(false);

        setseverity("warning");
        setAlertMessage("Please Enter Correct Password");
        handleClick();
        return false;
      }
      formdata.append("Email", Email);
      formdata.append("UserName", UserName);
      formdata.append("Role", Role);
      formdata.append("Password", Password);
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/admin/createadmin/`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          "auth-token": token,
        },
        body: formdata,
      });
      setbuttontext("Adding Data....");
      const json = await response.json();
      if (json.success === true) {
        setseverity("success");
        setbuttonloading(false);
        setAlertMessage(json.response);
        setEmail("");
        setUserName("");
        setPassword("");
        setConfirmPassword("");
        setRole("");
        setbuttonloading(false);

        handleClick();
        return true;
      }
      if (json.success === false) {
        setseverity("warning");
        setbuttonloading(false);
        setbuttonloading(false);

        setAlertMessage(json.response);
        handleClick();
        return false;
      }
    } catch (error) {
      setbuttonloading(false);
      console.log(error);
    }
  };
  return (
    <Box m={"20px"} sx={{flexDirection:'column', display:'flex'}}>
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={"Add New Admin/Manager"}
          subtitle={"Access To Use Buzz Admin Dashoard "}
        />
      </Box>


          <TextField
            id=""
            placeholder="Enter Name"
            InputLabelProps={{
              style: {
                outline: 'none',
                color: "#ffffff",
                paddingLeft: 7,
                fontFamily: 'poppins-reg',

              },
            }}

            style={{
              outline: 'none',
              border: '1px solid #ffffff15',
              borderRadius: '6px'
            }}
            sx={{
              outline: 'none',
              width: "450px",
              marginTop: 2,
              backgroundColor: "#ffffff10",
              input: { color: "#ffffff", fontFamily: 'poppins-reg', px: 2, py: 2 },
              // borderRadius: "10%",
            }}
            value={UserName}
            onChange={handleUserNameChange}
          />

          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            placeholder="Enter Email Address"
            type={"email"}

            //   color={colors.greenAccent[200]}
            //   fullWidth
            InputLabelProps={{
              style: {
                outline: 'none',
                color: "#ffffff",
                paddingLeft: 7,
                fontFamily: 'poppins-reg',

              },
            }}

            style={{
              outline: 'none',
              border: '1px solid #ffffff15',
              borderRadius: '6px'
            }}
            sx={{
              outline: 'none',
              width: "450px",
              marginTop: 2,
              backgroundColor: "#ffffff10",
              input: { color: "#ffffff", fontFamily: 'poppins-reg', px: 2, py: 2 },
              // borderRadius: "10%",
            }}
            value={Email}
            onChange={handleEmailChange}
          />
       
          <FormControl
            sx={{
              width: "450px",
              marginTop: 2,
              backgroundColor: "#ffffff10",

            }}
          >

            <Select
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  color: "#ffffff",
                  px: 2,
                  fontFamily: 'poppins-reg',
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
              
              value={Role}
              // inputProps={{ input: {  } }}color: colors.grey[900]
              onChange={handleRoleChange}
            >
              <MenuItem style={{ fontFamily: 'poppins-reg' }} value={"ADMIN"}>As Admin</MenuItem>
              <MenuItem style={{ fontFamily: 'poppins-reg' }} value={"MANAGER"}>As Manager</MenuItem>
            </Select>

          </FormControl>
       
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            type={'password'}
            placeholder="Enter Password"
            InputLabelProps={{
              style: {
                outline: 'none',
                color: "#ffffff",
                paddingLeft: 7,
                fontFamily: 'poppins-reg',

              },
            }}

            style={{
              outline: 'none',
              border: '1px solid #ffffff15',
              borderRadius: '6px'
            }}
            sx={{
              outline: 'none',
              width: "450px",
              marginTop: 2,
              backgroundColor: "#ffffff10",
              input: { color: "#ffffff", fontFamily: 'poppins-reg', px: 2, py: 2 },
              // borderRadius: "10%",
            }}
            value={Password}
            onChange={handlePasswordChange}
          />
      
          <TextField
            // gridColumn="span 8"
            // gridRow="span 3"
            id=""
            type={'password'}
            placeholder="Confirm Password"
            InputLabelProps={{
              style: {
                outline: 'none',
                color: "#ffffff",
                paddingLeft: 7,
                fontFamily: 'poppins-reg',

              },
            }}

            style={{
              outline: 'none',
              border: '1px solid #ffffff15',
              borderRadius: '6px'
            }}
            sx={{
              outline: 'none',
              width: "450px",
              marginTop: 2,
              backgroundColor: "#ffffff10",
              input: { color: "#ffffff", fontFamily: 'poppins-reg', px: 2, py: 2 },
              // borderRadius: "10%",
            }}
            value={ConfirmPassword}
            onChange={handleCPasswordChange}
          />
      

      <Box display={"flex"}>
        <img src="" alt="" />
        <Button
          onClick={handleSubmit}
          startIcon={<Add />}
          disabled={buttonloading}
          style={{
            borderRadius: 6,
            backgroundColor: "#706dea",
            border:'2px soild #ffffff20',
            // padding: "18px 36px",
            fontSize: "13px",
            height: "34px",
            marginTop:'20px',
            textTransform:'none',

            fontFamily:'poppins-reg'
          }}
          variant="contained"
          component="label"

        //   disabled={false}
        >
          {buttonloading ? "Giving Access" : "Create Admin"}
          {/* <input hidden accept="image/*" multiple type="file" /> */}
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {AlertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddAdmins;
