import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Header } from "./global/Header";
import Sidebar from "./global/Sidebar";
import Topbar from "./global/Topbar";
import { useState } from "react";
import SidebarMobile from "./global/SidebarMobile";
import BASE_URL from "../NetworkConfig/ApiConfig";

export const Admin = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [AdminState, setAdminState] = useState(null);
  // const [loading, setloading] = useState(true);
  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')
  const [error, seterror] = useState("Loading....");
  const [showTab, setShowTab] = useState(false)
  const FetchAdminDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/admin/getadmindetails/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();

    if (data.success === true) {
      setAdminState(data.response);
      localStorage.setItem("profile", data.response)
      // setloading(false);
      return;
    }
    if (data.success === false) {
      seterror(data.response);
      localStorage.clear()
      navigate("/", { replace: true });
      return;
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/", { replace: true });
    } else {
      FetchAdminDetails();
    }
  }, []);
  if (!AdminState) {
    return <Box>{error}</Box>;
  }
  return (
    <div className="app">
      {

        !isMobileScreen && !isTabScreen && (
          <Sidebar data={AdminState} />
        )
      }

      {
        (isMobileScreen || isTabScreen) && showTab && (
          <SidebarMobile data={AdminState} onClick={()=>{
            setShowTab(false)
          }} mobile={true} />
        )
      }
      <main className="content" style={{marginLeft: (!isMobileScreen && !isTabScreen) ? "320px" : "0px"}}>
        <Topbar data={AdminState} onClick={()=>{
          setShowTab(!showTab)
        }} />
        <Outlet />
      </main>
    </div>
  );
};