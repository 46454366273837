import { Box, useTheme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { tokens } from "../../Theme/theme";
import { Header } from "../global/Header";
import Statbox from "./Statbox";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import BarChart from "../Charts/BarChart";
import PieChart from "../Charts/PieChart";
import { useEffect } from "react";
import { useState } from "react";
import LineChart from "../Charts/LineChart";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../NetworkConfig/ApiConfig";



const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [DashboardState, setDashboardState] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [ActiveUsersCount, setActiveUsersCount] = useState(0);
  const [ActiveYesterdayCount, setActiveYesterdayCount] = useState(0)
  // const [loading, setloading] = useState(true);
  const [ActiveUsersChartData,setActiveUsersChartData] = useState(null)
  const [error, seterror] = useState("Loading....");
  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  const navigate = useNavigate()

  const FetchDashboardDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/dashboard/details/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setDashboardState(data.response);
      setUserCount(data.response[0]["totalUsers"])
      console.log(data);
      console.log(DashboardState);
      // setloading(false);
      return;
    }
    if (data.success === false) {
      alert("error")
      seterror(data.response);
      return;
    }
  };

  const calculatePercentageChange = (today, yesterday) => {
    if (yesterday === 0 && today == 0) return 0;
    if (yesterday === 0 && today > 0) return 100;
    if (today == 0) return -100;
    const percentageChange = ((today - yesterday) / yesterday) * 100;
    return percentageChange.toFixed(2); // Limit to 2 decimal places
  };

  const FetchData = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const Datenow = new Date();
    const response = await fetch(
      `${BASE_URL}/active/gettodayandyesterdayusers/${Datenow.toISOString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    console.log(data)
    if (data.success === true) {
      setActiveUsersCount(data.todayUsers.length);
      setActiveYesterdayCount(data.yesterdayUsers.length)
      console.log(data);
      // console.log(UsersState);

      return; // setloading(false);
    }
    if (data.success === false) {
      // seterror(data.response);
      return;
    }
  };

  const FetchActiveUsersGraphData = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const Datenow = new Date();
    const response = await fetch(
      `${BASE_URL}/active/getactiveusersdata/${Datenow.toISOString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    console.log(data)
    if (data.success === true) {
      setActiveUsersChartData(data.response.data)
      console.log(data);
      // console.log(UsersState);

      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
      return;
    }
  };
  useEffect(() => {
    FetchDashboardDetails();
    FetchData();
    FetchActiveUsersGraphData()
  }, []);
  if (!DashboardState) {
    return <Box>{error}</Box>;
  }
  return (
    <Box m="20px" sx={{
    }}>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Header
          title={"Dashboard"}
          subtitle={"Here is today's report and performances"}
        />
      </Box>

      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows={"140px"}
        pb={"20px"}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={ isMobileScreen ? "span 12" : isTabScreen ? "span 6" : "span 3"}
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={userCount}
            subtitle="Registered Users"
            icon={
              <DownloadDoneIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }

            onClick={()=> {
              navigate("/admin/users")
            }}
            users={true}
            report={`${DashboardState[0]["totalUsersThisMonth"]} registered this month`}
          />
        </Box>
        <Box
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          gridColumn={ isMobileScreen ? "span 12" : isTabScreen ? "span 6" : "span 3"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            // todayonboardusers
            title={DashboardState[0]["todayonboardusers"]}
            subtitle="Onboarded Today"
            onClick={()=> {

            }}
            icon={
              <DownloadIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            report={calculatePercentageChange(DashboardState[0]["todayonboardusers"], DashboardState[0]["yesterdayonboardusers"])}
            reportSuffix={calculatePercentageChange(DashboardState[0]["todayonboardusers"], DashboardState[0]["yesterdayonboardusers"]) > 0 
              ? " more than yesterday" : (calculatePercentageChange(DashboardState[0]["todayonboardusers"], DashboardState[0]["yesterdayonboardusers"]) == 0 
              ? "Same as yesterday" : " less than yesterday")
            }
            users={false}
          />
        </Box>
        <Box
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          gridColumn={ isMobileScreen ? "span 12" : isTabScreen ? "span 6" : "span 3"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={ActiveUsersCount}
            subtitle="Active Today"
            onClick={()=>{
              navigate("/admin/activeusers")
            }}
            icon={
              <PersonPinIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            users={false}
            report={calculatePercentageChange(ActiveUsersCount, ActiveYesterdayCount)}
            reportSuffix={calculatePercentageChange(ActiveUsersCount, ActiveYesterdayCount)  > 0
              ? " more than yesterday" : (calculatePercentageChange(ActiveUsersCount, ActiveYesterdayCount) == 0 ? "Same as yesterday" : " less than yesterday")
            }
          />
        </Box>
        <Box
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          gridColumn={ isMobileScreen ? "span 12" : isTabScreen ? "span 6" : "span 3"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={DashboardState[0]["EnabledLocations"]}
            subtitle="Enabled Locations"
            icon={
              <StorefrontIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            reportSuffix={""}
            users={false}
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn={ isMobileScreen ? "span 12" : "span 8"}
          gridRow="span 3"
          sx={{
            border: "2px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontFamily={"poppins-mid"}
                color={colors.grey[100]}
              >
                Last 10 days active users
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <LineChart
              // isDashboard={true}
              color={"set3"}
              data={ActiveUsersChartData}
            />
          </Box>
        </Box>
        <Box
          gridColumn={ isMobileScreen ? "span 12" : "span 4"}
          gridRow="span 3"
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#121319,#121319, #121319) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                GENDER CHART
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <PieChart data={DashboardState[0]["GenderChart"]} />
          </Box>
        </Box>

        <Box
          gridColumn={ isMobileScreen ? "span 12" : "span 4"}
          gridRow="span 3"
          sx={{
            border: "2px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontFamily={"poppins-mid"}
                color={colors.grey[100]}
              >
                Users By Age
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <BarChart
              // isDashboard={true}
              data={DashboardState[0]["Agedata"]}
            />
          </Box>
        </Box>

        <Box
          gridColumn={ isMobileScreen ? "span 12" : "span 8"}
          gridRow="span 3"
          sx={{
            border: "2px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontFamily={"poppins-mid"}
                color={colors.grey[100]}
              >
                Accounts Created
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <LineChart
              // isDashboard={true}
              data={DashboardState[0]["registrationsChart"].data}
              color={"paired"}
              enableArea={true}
            />
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default Dashboard;
