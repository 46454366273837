import { Box, useTheme, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../Theme/theme";
import ProgressCircle from "./ProgressCircle";
import HdrStrongRoundedIcon from '@mui/icons-material/HdrStrongRounded';
import '../../assets/fonts/fonts.css'
import {
  TrendingUpRounded,
  TrendingDownRounded
} from '@mui/icons-material';

const Statbox = ({ title, subtitle, icon, progress, report, reportSuffix, users, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box width={"100%"} m="0 30px" onClick={()=> {
      if(onClick){
        onClick()
      }}} >
      <Box display={"flex"} justifyContent="space-between">
        <Box>
          <Typography
            variant="h6"
            // fontWeight={"italic"}
            fontFamily={"poppins-reg"}
            sx={{ color: "white" }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="h2"
            mt={2}
            fontFamily={'poppins-bold'}
            sx={{ color: "#ffffff" }}
          >
            {title}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {
              !users && report < 0 && (
                <TrendingDownRounded style={{ color: "#ff2200" }} />
              )
            }
            {
              !users && report > 0 && (
                <TrendingUpRounded style={{ color: "#03fc0b" }} />
              )
            }
            {
              (report > 0 || report < 0) && (
                <Typography sx={{ marginLeft: '4px', marginRight:"4px" ,fontSize: "12px", fontFamily: 'poppins-mid', color: report < 0 ? "#ff2200" : "#03fc0b" }}>
                  {users ? `${report}` : (report > 0 ? `${report}%` : `${Math.abs(report)}%`)}
                </Typography>
              )
            }

            <Typography
              sx={{
                fontFamily: "poppins-reg",
                fontSize: "11px",
                color: "#ffffff60",
              }}
            >{ users ? `${report}` : `${reportSuffix}`}
            </Typography>
          </Box>

        </Box>
        <Box>
          <HdrStrongRoundedIcon color="#ffffff10" />
        </Box>
      </Box>

      <Box display={"flex"} justifyContent="space-between">

      </Box>
    </Box>
  );
};

export default Statbox;
