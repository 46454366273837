import {
  Add,
  ArrowForwardOutlined,
  Delete,
  Schedule,
} from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { format } from "date-fns";
import { mockAdmin } from "../../data/mockdata";
import { Link } from "react-router-dom";
import { ColorModeContext, tokens } from "../../Theme/theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Header } from "../global/Header";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BASE_URL from "../../NetworkConfig/ApiConfig";
function renderDate(date) {
  console.log(date);
  if (!date) {
    return "";
  }
  return format(new Date(date), "MM/dd/yyyy");
}
const AdminList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handledelteadmin = async (id) => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/admin/removeaccess/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      let filteredarray = AdminState.filter((Admin) => {
        return Admin._id !== id;
      });
      setAdminState(filteredarray);
      return true;
    } else if (data.success === false) {
      return false;
    }
  };
  const columns = [
    // {
    //   field: "_id",
    //   headerName: "id",
    //   flex: 0.3,
    // },

    {
      field: "UserName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "Role",
      headerName: "Role",
      flex: 1,
    },

    {
      field: "ids",
      headerName: "DELETE",
      flex: 0.3,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        return (
          <Tooltip title={"DELETE"}>
            <IconButton onClick={() => handledelteadmin(params.id)}>
              <Delete />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  const [AdminState, setAdminState] = useState(null);
  // const [loading, setloading] = useState(true);
  const [error, seterror] = useState("Loading.....");
  const FetchDashboardDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/admin/alladmins/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setAdminState(data.response);
      console.log(data);
      console.log(AdminState);

      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchDashboardDetails();
  }, []);
  if (!AdminState) {
    return <Box>{error}</Box>;
  }
  return (
    <Box m="20px">
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={"Buzz Admin and Managers"}
          subtitle={
            "List of people who have access to this admin dashboard"
          }
        />
        <Button
          startIcon={<Add />}
          style={{
            borderRadius: 6,
            backgroundColor: "#706dea",
            border:'2px soild #ffffff20',
            // padding: "18px 36px",
            fontSize: "13px",
            height: "34px",
            textTransform:'none',
            fontFamily:'poppins-reg'
          }}
          variant="contained"
          component={Link}
          to="/admin/addadmins"

          //   disabled={false}
        >
          ADD ADMINS
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
            border: 'none',
            borderBottom: 'none',
            fontStyle: 'normal',
            textDecoration: 'none',
            fontWeight: 'normal',
            fontSize: '12px',
            color: '#ffffff',
            fontFamily: 'poppins-reg',
          },
          "& .name-column--cell": {
            color: "#ffffff10",
            border: 'none'
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#ffffff10",
            fontFamily: 'poppins-reg',
            fontSize: "14px",
            letterSpacing: '0.5px',
            color: '#ffffff',
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff05",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#ffffff10",
            fontFamily: 'poppins-reg'
          },
          "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
            fontFamily: 'poppins-reg'
          },
          "& .MuiCheckbox-root": {
            color: `#ffffff10 !important`,
            border: 'none'
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `#ffffff !important`,
            paddingX: "13px",
            background: "#ffffff05",
            border: "1.5px solid #ffffff20",
            borderRadius: "5px",
            marginBottom: "10px",
            marginLeft: "auto", // Push the button to the end
          },
        }}
      >
        <DataGrid
          rows={AdminState}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
          disableSelectionOnClick={false}
          density="comfortable"
        />
      </Box>
    </Box>
  );
};

export default AdminList;
