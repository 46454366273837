import { IconButton, Switch, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../Theme/theme";
import { Header } from "../global/Header";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import { useEffect } from "react";
import { format } from "date-fns";
import {
  GoogleMap,
  InfoWindow,
  InfoWindowF,
  Marker,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import UsersOnMap from "./UserOnMap";
import LiveUsers from "../Live Users/LiveUsers";
import ParticularUserSendChirp from "../Chirps/ParticularUserSendChirp";
import ParticularUserReciveChirp from "../Chirps/ParticularUserReciveChirp";
import SendConnectionRequests from "../ConnectionRequests/SendConnectionRequests";
import ReceiveConnectionRequests from "../ConnectionRequests/ReceiveConnectionRequests";
import BASE_URL from "../../NetworkConfig/ApiConfig";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ParticularUserInfo = () => {
  let query = useQuery();
  console.log(query.get("userid"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UserState, setUserState] = useState(null);
  const [imageno, setimageno] = useState(0);
  const [currentTab, setCurrentTab] = useState(0)
  const [error, seterror] = useState("Loading.....");
  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')


  function printUserIdType(user) {
    // Regular expressions for email and phone number
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(user)) {
      return "Google";
    } else if (!isNaN(Number(user))) {
      return "Phone No.";
    } else {
      return "Facebook"; // It's neither an email nor a valid numeric value
    }
  }
  // const [loading, setloading] = useState(true);
  const FetchUserDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/user/userdetails/${query.get("userid")}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setUserState(data.response);
      console.log(data);
      console.log(UserState);
      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchUserDetails();
  }, []);
  if (!UserState) {
    return <Box>{error}</Box>;
  }
  const changeimageback = () => {
    if (imageno === 0) {
      setimageno(UserState["User"]["image"].length - 1);
      return true;
    }
    //  else if (imageno === 0) {
    //   setimageno(UserState["User"]["image"].length - 1);
    //   return true;
    // }
    setimageno(imageno - 1);
  };
  const changeimageforward = () => {
    if (imageno === UserState["User"]["image"].length - 1) {
      setimageno(0);
      console.log(UserState["User"]["ReferralCode"]);
      return true;
    }

    setimageno(imageno + 1);
  };
  return (
    <>
      <Box m={(isMobileScreen || isTabScreen) ? "0px" : "20px"} flexDirection={(isMobileScreen || isTabScreen) ? "column" : 'row'} display={"flex"} flexWrap={'wrap'} backgroundColor={(isMobileScreen || isTabScreen) ? "#00000000" :"#ffffff05"} sx={{ paddingX: "25px", paddingY: "25px", borderRadius: "20px" }} >

        {/* left container */}
        <Box sx={{
          flexDirection: 'column', flex: 1,
          borderRight: (isMobileScreen || isTabScreen) ? "none" : "1px solid #ffffff10", marginRight: (isMobileScreen || isTabScreen) ? "0px" : "10px"
        }}>

          <Box sx={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>

            <img
              width={"90px"}
              height={"90px"}
              src={UserState["User"]["image"][0]}
              style={{ borderRadius: "50%", border: "6px solid #aaaaaa10", objectFit: 'cover' }}
            />

            <Box sx={{ flexDirection: 'column', marginLeft: "15px" }} flex={1}>
              <Box sx={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: "center",
                alignContent: 'center',
                justifyItems: 'center',
                display: 'flex'
              }}><Typography style={{
                fontFamily: 'poppins-mid',
                fontSize: "20px"
              }}>{UserState["User"]["full_name"]}</Typography>
                <Typography style={{
                  borderRadius: "50px",
                  paddingLeft: '10px',
                  paddingRight: "10px",
                  backgroundColor: "#ffffff10",
                  fontFamily: 'poppins-reg',
                  fontSize: "13px",
                  marginLeft: "10px",
                  color: "#ffffff"
                }} >{UserState["User"]["buzz_name"]}</Typography>
              </Box>

              <Box sx={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: "flex-start",
                marginTop: "5px",
                display: 'flex'
              }}>

                <Typography style={{
                  borderRadius: "3px",
                  border: `1px solid ${UserState["User"]["Active"] ? "#07d10020" : "#ffffff20"}`,
                  paddingLeft: '6px',
                  paddingRight: "6px",
                  backgroundColor: UserState["User"]["Active"] ? "#07d10030" : "#ffffff30",
                  alignSelf: 'flex-start',
                  fontFamily: 'poppins-reg',
                  fontSize: "13px",
                  color: UserState["User"]["Active"] ? "#07d100" : "#aaaaaa"
                }} >{UserState["User"]["Active"] ? "• Active" : "Not Active"}</Typography>

                <Typography style={{
                  borderRadius: "3px",
                  border: "1px solid #ffffff30",
                  paddingLeft: '6px',
                  paddingRight: "6px",
                  alignSelf: 'flex-start',
                  fontFamily: 'poppins-reg',
                  fontSize: "13px",
                  marginLeft: '5px',
                  color: "#ffffff"
                }} >{UserState["User"]["gender"]}</Typography>
              </Box>
              <Typography style={{
                fontFamily: 'poppins-reg',
                fontSize: "13px",
                color: "#ffffff70",
                marginTop: "10px",
              }}>{`Buzz user ${UserState["User"]["createdAt"]
                ? "since " +
                format(new Date(UserState["User"]["createdAt"]), "MMM dd, yyyy")
                : `since ${format(new Date("March 7, 2023"), "MMM dd, yyyy")}`
                }`}</Typography>
            </Box>

            <Typography style={{
              borderRadius: "3px",
              border: "1px solid #ffffff10",
              paddingLeft: '6px',
              paddingRight: "6px",
              paddingBottom: "3px",
              paddingTop: "3px",
              alignSelf: 'flex-start',
              fontFamily: 'poppins-reg',
              fontSize: "13px",
              marginTop: "5px",
              marginLeft: '5px',
              marginRight: "20px",
              cursor: 'pointer',
              color: "#ffffff70"
            }} >❐ {UserState["User"]["_id"]}</Typography>

          </Box>

          <Box sx={{ maxWidth: "300px", marginTop: "20px", height: "1px", backgroundColor: "#ffffff10" }} />

          <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginTop: "20px", marginLeft: "5px" }} >About</Typography>
          <Typography style={{ fontFamily: "poppins-reg", fontSize: "16px", color: "#ffffff", marginLeft: "5px" }} >{UserState["User"]["bio"] == "" ? "NA" : UserState["User"]["bio"]}</Typography>
          <Box sx={{ maxWidth: "300px", marginTop: "25px", height: "1px", backgroundColor: "#ffffff10" }} />

          <Box
            display={"grid"}
            flex={1}
            gridTemplateColumns="repeat(12,1fr)"
            gridAutoRows={"auto"}
            paddingRight={"10px"}
            gap="20px"
          >

            <Box
              gridColumn="span 4"
              gridRow="span 2"
              overflow="auto"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginTop: "20px", marginLeft: "5px" }} >Referred By</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", marginLeft: "5px" }} >{UserState["User"]["ReferralCode"] == "" ? "NA" : UserState["User"]["ReferralCode"]}</Typography>
            </Box>

            <Box
              gridColumn="span 4"
              gridRow="span 2"
              overflow="auto"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginTop: "20px", }} >Account Type</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", }} >{printUserIdType(UserState["User"]["UserId"])}</Typography>
            </Box>

            <Box
              gridColumn="span 4"
              gridRow="span 2"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginTop: "20px", }} >User Login Id</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", wordBreak: 'break-all' }} >{UserState["User"]["UserId"]}</Typography>
            </Box>


            <Box
              gridColumn="span 4"
              gridRow="span 2"
              overflow="auto"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginLeft: "5px" }} >Birthday</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", marginLeft: "5px" }} >{UserState["User"]["date_of_birth"]}</Typography>
            </Box>

            <Box
              gridColumn="span 4"
              gridRow="span 2"
              overflow="auto"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", }} >Country</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", }} >{(UserState["User"]["country"] == "" || UserState["User"]["country"] == "_") ? "NA" : UserState["User"]["country"]}</Typography>
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              overflow="auto"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", }} >City</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", }} >{(UserState["User"]["city"] == "" || UserState["User"]["city"] == "_") ? "NA" : UserState["User"]["city"]}</Typography>
            </Box>

            <Box
              gridColumn="span 12"
              gridRow="span 2"
              overflow="auto"
            >
              <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginLeft: "5px" }} >Profession</Typography>
              <Typography style={{ fontFamily: "poppins-mid", fontSize: "16px", color: "#ffffff", marginLeft: "5px", wordBreak: 'break-all' }} >{UserState["User"]["profession"]}</Typography>
            </Box>
          </Box>

          <Box sx={{ maxWidth: "300px", marginTop: "25px", height: "1px", backgroundColor: "#ffffff10" }} />
          <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginLeft: '5px', marginTop: "20px" }} >Interest in {UserState["User"]["interests"].length} topics</Typography>
          <Box
            // backgroundColor={colors.primary[400]}
            alignItems={"center"}
            display={'flex'}
            // justifyContent="space-around"
            justifyContent={"flex-start"}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: UserState["User"]["interests"].length > 0 ? "10px" : "0px",
              marginLeft: '5px'
            }}
          >
            {UserState["User"]["interests"].map((text, index) => (
              <Typography style={{
                borderRadius: "3px",
                border: "1px solid #ffffff30",
                paddingLeft: '9px',
                backgroundColor: "#ffffff10",
                paddingRight: "9px",
                alignSelf: 'flex-start',
                fontFamily: 'poppins-reg',
                fontSize: "14px",
                marginRight: '5px',
                paddingTop: "3px",
                paddingBottom: "3px",
                color: "#ffffff"
              }} >{text}</Typography>

            ))}


          </Box>
          <Box sx={{ maxWidth: "300px", marginTop: "25px", height: "1px", backgroundColor: "#ffffff10" }} />

          <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginLeft: '5px', marginTop: "20px" }} >{UserState["User"]["image"].length} Photos Added</Typography>

          <Box
            // backgroundColor={colors.primary[400]}
            alignItems={"center"}
            display={'flex'}
            // justifyContent="space-around"
            justifyContent={"flex-start"}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginLeft: '5px'
            }}
            mb={2}
          >
            {UserState["User"]["image"].map((link, index) => (
              <img
                key={index}
                alt="profile-user"
                width="110px"
                height="150px"
                src={link}
                style={{
                  marginRight: "10px", marginTop: "10px", cursor: "pointer", borderRadius: "10px", padding: "5px", objectFit: "cover",
                  backgroundColor: "#ffffff10"
                }}
              />

            ))}

          </Box>
          <Typography style={{ fontFamily: "poppins-reg", fontSize: "14px", color: "#ffffff60", marginLeft: '5px', marginTop: "20px" }} >Buzzing Locations</Typography>

          <UsersOnMap id={query.get("userid")} />
        </Box>
        {/* right container */}
        <Box sx={{
          flexDirection: 'column', flex: 1,
          overflow: 'hidden',
          flexFlow: 'wrap'
        }}>


          <Typography style={{ fontFamily: 'poppins-mid', fontSize: "16px", marginBottom: "10px", color: "#aaaaaa", marginTop:(isMobileScreen || isTabScreen) ? "30px" : "0px" }} >Actions Made By {UserState["User"]["full_name"]}</Typography>

          <Box sx={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>


            <Typography
              onClick={() => setCurrentTab(0)}
              style={{
                borderRadius: "50px",
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 0 ? "#706dea" : "#aaaaaa20",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                paddingTop: "6px",
                marginTop: "10px",
                paddingBottom: "6px",
                cursor: 'pointer',
                color: currentTab == 0 ? "#ffffff" : "#aaaaaa"
              }} >Matched with</Typography>
            <Typography
              onClick={() => setCurrentTab(1)}
              style={{
                borderRadius: "50px",
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 1 ? "#706dea" : "#aaaaaa10",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                cursor: 'pointer',
                marginTop: "10px",
                paddingTop: "6px",
                paddingBottom: "6px",
                color: currentTab == 1 ? "#ffffff" : "#aaaaaa"
              }} >Right Slid To</Typography>
            <Typography
              onClick={() => setCurrentTab(2)}
              style={{
                borderRadius: "50px",
                cursor: 'pointer',
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 2 ? "#706dea" : "#aaaaaa10",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                marginTop: "10px",
                paddingTop: "6px",
                paddingBottom: "6px",
                color: currentTab == 2 ? "#ffffff" : "#aaaaaa"
              }} >Right Slid By</Typography>
            <Typography
              onClick={() => setCurrentTab(3)}
              style={{
                borderRadius: "50px",
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 3 ? "#706dea" : "#aaaaaa20",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                paddingTop: "6px",
                marginTop: "10px",
                paddingBottom: "6px",
                cursor: 'pointer',
                color: currentTab == 3 ? "#ffffff" : "#aaaaaa"
              }} >Chirp Sent</Typography>
            <Typography
              onClick={() => setCurrentTab(4)}
              style={{
                borderRadius: "50px",
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 4 ? "#706dea" : "#aaaaaa10",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                marginTop: "10px",
                cursor: 'pointer',
                paddingTop: "6px",
                paddingBottom: "6px",
                color: currentTab == 4 ? "#ffffff" : "#aaaaaa"
              }} >Chirp Received</Typography>
            <Typography
              onClick={() => setCurrentTab(5)}
              style={{
                borderRadius: "50px",
                cursor: 'pointer',
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 5 ? "#706dea" : "#aaaaaa10",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                marginTop: "10px",
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                paddingTop: "6px",
                paddingBottom: "6px",
                color: currentTab == 5 ? "#ffffff" : "#aaaaaa"
              }} >Chat Request Sent</Typography>

            <Typography
              onClick={() => setCurrentTab(6)}
              style={{
                borderRadius: "50px",
                cursor: 'pointer',
                paddingLeft: '12px',
                border: `1px solid #ffffff20`,
                backgroundColor: currentTab == 6 ? "#706dea" : "#aaaaaa10",
                paddingRight: "12px",
                alignSelf: 'flex-start',
                marginTop: "10px",
                fontFamily: 'poppins-reg',
                fontSize: "12px",
                marginRight: '10px',
                paddingTop: "6px",
                paddingBottom: "6px",
                color: currentTab == 6 ? "#ffffff" : "#aaaaaa"
              }} >Chat Request Received</Typography>
          </Box>

          {
            currentTab == 0 && (
              <Typography style={{ fontFamily: 'poppins-reg', fontSize: "12px", marginTop: "15px", marginLeft: '5px', color: "#aaaaaa" }} >
                {UserState["Matches"].length} Matches
              </Typography>
            )
          }
          {
            currentTab == 1 && (
              <Typography style={{ fontFamily: 'poppins-reg', fontSize: "12px", marginTop: "15px", marginLeft: '5px', color: "#aaaaaa" }} >
                {UserState["RightSwipedto"].length} Right Slid
              </Typography>
            )
          }
          {
            currentTab == 2 && (
              <Typography style={{ fontFamily: 'poppins-reg', fontSize: "12px", marginTop: "15px", marginLeft: '5px', color: "#aaaaaa" }} >
                {UserState["RightSwipedby"].length} Right Slid Received
              </Typography>
            )
          }


          <Box
            display={"grid"}
            gridTemplateColumns="repeat(12,1fr)"
            gap="10px"
            marginTop={"10px"}
            maxHeight={"970px"}
            marginRight={"10px"}
            overflow={'auto'}
          >
            {currentTab == 0 && UserState["Matches"].map((user) => {
              if (user["UserId"] === null || user["UserId"] === undefined) {
                return;
              }
              return (
                <Box
                  // key={`${transaction.txId}-${i}`}
                  gridColumn="span 6"
                  gridRow="span 2"
                  display="flex"
                  alignItems="center"
                  px="15px"
                  py={"8px"}
                  component={Link}
                  backgroundColor={"#ffffff05"}
                  target={"_blank"}
                  borderRadius={"10px"}
                  to={{
                    pathname: "/admin/particularuserdetails",
                    search: `?userid=${user["UserId"]["_id"]}`,
                    state: { fromDashboard: true },
                    // console.log(first)
                  }}

                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      alt="profile-user"
                      width="50px"
                      height="50px"
                      src={` ${user["UserId"]["image"][0]}`}
                      style={{
                        cursor: "pointer", objectFit: 'cover',
                        borderRadius: "50%",
                        border: "5px solid #ffffff10"
                      }}
                    />
                  </Box>
                  <Box sx={{ ml: "10px", display: 'flex', flexDirection: 'column' }}>
                    <Typography color={"#ffffff"}
                      fontFamily={"poppins-reg"}
                      fontSize={"13px"}>{user["UserId"]["full_name"]}</Typography>

                    <Typography color={"#ffffff90"}
                      fontFamily={"poppins-reg"}
                      style={{
                        alignSelf: "flex-start",
                        border: "1px solid #ffffff10",
                        borderRadius: "4px",
                        paddingTop: "1px",
                        marginTop: "1px",
                        paddingBottom: "1px",
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                      fontSize={"10px"}>{user["UserId"]["buzz_name"]}</Typography>
                  </Box>
                </Box>
              );
            })}


            {currentTab == 1 && UserState["RightSwipedto"].map((user) => {
              if (user === null || user === undefined) {
                return;
              }
              return (
                <Box
                  // key={`${transaction.txId}-${i}`}
                  gridColumn="span 6"
                  gridRow="span 2"
                  display="flex"
                  alignItems="center"
                  px="15px"
                  py={"8px"}
                  component={Link}
                  backgroundColor={"#ffffff05"}
                  target={"_blank"}
                  borderRadius={"10px"}
                  to={{
                    pathname: "/admin/particularuserdetails",
                    search: `?userid=${user["_id"]}`,
                    state: { fromDashboard: true },
                    // console.log(first)
                  }}

                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      alt="profile-user"
                      width="50px"
                      height="50px"
                      src={` ${user["image"][0]}`}
                      style={{
                        cursor: "pointer", objectFit: 'cover',
                        borderRadius: "50%",
                        border: "5px solid #ffffff10"
                      }}
                    />
                  </Box>
                  <Box sx={{ ml: "10px", display: 'flex', flexDirection: 'column' }}>
                    <Typography color={"#ffffff"}
                      fontFamily={"poppins-reg"}
                      fontSize={"13px"}>{user["full_name"]}</Typography>

                    <Typography color={"#ffffff90"}
                      fontFamily={"poppins-reg"}
                      style={{
                        alignSelf: "flex-start",
                        border: "1px solid #ffffff10",
                        borderRadius: "4px",
                        paddingTop: "1px",
                        marginTop: "1px",
                        paddingBottom: "1px",
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                      fontSize={"10px"}>{user["buzz_name"]}</Typography>
                  </Box>
                </Box>
              );
            })}

            {currentTab == 2 && UserState["RightSwipedby"].map((user) => {
              if (user["UserId"] === null || user["UserId"] === undefined) {
                return;
              }
              return (
                <Box
                  // key={`${transaction.txId}-${i}`}
                  gridColumn="span 6"
                  gridRow="span 2"
                  display="flex"
                  alignItems="center"
                  px="15px"
                  py={"8px"}
                  component={Link}
                  backgroundColor={"#ffffff05"}
                  target={"_blank"}
                  borderRadius={"10px"}
                  to={{
                    pathname: "/admin/particularuserdetails",
                    search: `?userid=${user["UserId"]["_id"]}`,
                    state: { fromDashboard: true },
                    // console.log(first)
                  }}

                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      alt="profile-user"
                      width="50px"
                      height="50px"
                      src={` ${user["UserId"]["image"][0]}`}
                      style={{
                        cursor: "pointer", objectFit: 'cover',
                        borderRadius: "50%",
                        border: "5px solid #ffffff10"
                      }}
                    />
                  </Box>
                  <Box sx={{ ml: "10px", display: 'flex', flexDirection: 'column' }}>
                    <Typography color={"#ffffff"}
                      fontFamily={"poppins-reg"}
                      fontSize={"13px"}>{user["UserId"]["full_name"]}</Typography>

                    <Typography color={"#ffffff90"}
                      fontFamily={"poppins-reg"}
                      style={{
                        alignSelf: "flex-start",
                        border: "1px solid #ffffff10",
                        borderRadius: "4px",
                        paddingTop: "1px",
                        marginTop: "1px",
                        paddingBottom: "1px",
                        paddingLeft: "6px",
                        paddingRight: "6px"
                      }}
                      fontSize={"10px"}>{user["UserId"]["buzz_name"]}</Typography>
                  </Box>
                </Box>
              );
            })}


            {currentTab == 3 && (
              <ParticularUserSendChirp id={query.get("userid")} />
            )}

            {currentTab == 4 && (
              <ParticularUserReciveChirp id={query.get("userid")} />
            )}

            {currentTab == 5 && (
              <SendConnectionRequests id={query.get("userid")} />
            )}

            {currentTab == 6 && (
              <ReceiveConnectionRequests id={query.get("userid")} />
            )}

          </Box>


        </Box>

      </Box>

    </>
  );
};

export default ParticularUserInfo;
