import { ColorModeContext, useMode } from "./Theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./components/global/Topbar";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashborad/Dashboard";
import Sidebar from "./components/global/Sidebar";
import Users from "./components/Users/Users";
import BarChart from "./components/Charts/BarChart";
import PieChart from "./components/Charts/PieChart";
import EnabledLocations from "./components/EnabledLocations/EnabledLocations";
import IndividualEnabledLocationDetails from "./components/EnabledLocations/IndividualEnabledLocationDetails";
import LiveUsers from "./components/Live Users/LiveUsers";
import AdminList from "./components/AdminRoles/AdminList";
import AddAdminModal from "./components/AdminRoles/AddAdminModal";
import ParticularUserInfo from "./components/Users/ParticularUserInfo";
import ChatsAndMatches from "./components/ChatsAndMatches/ChatsAndMatches";
import AddEnabledLocation from "./components/EnabledLocations/AddEnabledLocation";
import AddAdmins from "./components/AdminRoles/AddAdmins";
import Login from "./components/Login/Login";
import { Admin } from "./components/Admin";
import ActiveUser from "./components/ActiveUsers/ActiveUser";
import SendNotification from "./components/Notification/SendNotification";
import Chirps from "./components/Chirps/Chirps";
import IndividualChirps from "./components/Chirps/IndividualChirps";
import EditEnabledLocationDetails from "./components/EnabledLocations/EditEnabledLocation/EditEnabledLocationDetails";
import AddNewImages from "./components/EnabledLocations/EditEnabledLocation/AddNewImages";
import LiveCount from "./components/LiveCount/LiveCount";


function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="admin" element={<Admin />}>
            <Route path="users" element={<Users />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="enabledlocations" element={<EnabledLocations />} />
            <Route path="enabledlocationdetails" element={<IndividualEnabledLocationDetails />} />
            <Route path="liveusers" element={<LiveUsers />} />
            <Route path="adminlist" element={<AdminList />} />
            <Route path="particularuserdetails" element={<ParticularUserInfo />} />
            <Route path="chatsandmatches" element={<ChatsAndMatches />} />
            <Route path="addenabledlocation" element={<AddEnabledLocation />} />
            <Route path="addadmins" element={<AddAdmins />} />
            <Route path="activeusers" element={<ActiveUser />} />
            <Route path="notify" element={<SendNotification />} />
            <Route path="chirps" element={<Chirps />} />
            <Route path="particularchirpdetails" element={<IndividualChirps />} />
            <Route path="editenabledlocationdetails" element={<EditEnabledLocationDetails />} />
            <Route path="addnewimageforalocation" element={<AddNewImages />} />
            <Route path="livedata" element={<LiveCount />} />
          </Route>
        </Routes>

      </ThemeProvider>
    </ColorModeContext.Provider >
  );
}

export default App;
