import { useTheme } from "@emotion/react";
import React from "react";
import { tokens } from "../../../Theme/theme";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import { LocalSee } from "@mui/icons-material";
import SnackbarComponent from "./SnackbarComponent";
import ModifyLocationdetails from "./ModifydataApi";

const LogoChange = ({ data }) => {
  const preset_key = "marketing_admin";
  const cloud_name = "dx8qieggt";
  const theme = useTheme();
  const dataid = data._id;
  const colors = tokens(theme.palette.mode);
  const [Logo, setLogo] = useState(data.Logo);
  const [logoupload, setlogoupload] = useState(false);
  const [Logouploadtext, setLogouploadtext] = useState("REPLACE LOGO");
  const [disable, setdisable] = React.useState(false);
  const [buttonloading, setbuttonloading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const handleLogochange = async (imageupload) => {
    setlogoupload(true);
    setLogouploadtext("REPLACING....");
    setdisable(true);
    const data = new FormData();
    data.append("file", imageupload);
    data.append("upload_preset", preset_key);
    data.append("cloud_name", cloud_name);
    data.append("folder", "locations");
    // setLogo();
    console.log(preset_key);
    fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
      method: "POST",
      body: data,
    })
      .then((uploadImageToCloudinary) => {
        console.log(uploadImageToCloudinary);
        return uploadImageToCloudinary.json();
      })
      .then((uploadResponse) => {
        const GetImageUrl = uploadResponse.secure_url;
        console.log(GetImageUrl);
        // const files = e.target.files;
        console.log(GetImageUrl);
        if (GetImageUrl) {
          console.log(data._id);
          console.log(data);
          ModifyLocationdetails(dataid, localStorage.getItem("token"), {
            Logo: GetImageUrl,
          }).then((response) => {
            console.log(response);
            if (response.success === true) {
              setbuttonloading(false);
              //   showSnackbar(true);
              setseverity("success");
              setAlertMessage("Data Saved Successfully");
              setShowSnackbar(true);
              setLogo(GetImageUrl);
              setlogoupload(false);
              setLogouploadtext("REPLACING....");
              setdisable(false);
            }
            if (response.success === false) {
              setbuttonloading(false);
              setseverity("error");
              setAlertMessage("INTERNAL SERVER ERROR");
              setShowSnackbar(true);
              setlogoupload(false);
              setLogouploadtext("REPLACING....");
              setdisable(false);
            }
            return true;
          });
        }

        return true;
      })
      .catch((error) => {
        console.error(error);
        // Handle error
        setlogoupload(false);
        setLogouploadtext("UPLOAD ERROR");
        setdisable(false);
        return false;
      });
    // const base64 = await convertBase64(files[0]);
    // console.log(base64);
    // setLogo(base64);
  };
  return (
    <>
      <Box display={"flex"} justifyContent="center">
        <Button
          disabled={logoupload}
          startIcon={<LocalSee />}
          style={{
            borderRadius: 12,
            backgroundColor: colors.greenAccent[500],
            // padding: "18px 36px",
            fontSize: "18px",
            height: "34px",
          }}
          variant="contained"
          component="label"
        >
          Replace Logo
          <input
            type="file"
            hidden
            onChange={(e) => handleLogochange(e.target.files[0])}
          />
        </Button>
      </Box>
      <Box mt={2} display={"flex"} justifyContent="center">
        {Logo && (
          <img
            width="100px"
            height="100px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
            src={Logo}
            alt=""
          />
        )}
      </Box>
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </>
  );
};

export default LogoChange;
