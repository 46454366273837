import { ArrowForwardOutlined, Padding, Schedule } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useTheme, useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { mockDataTeam } from "../../data/mockdata";
import { Link } from "react-router-dom";
import { ColorModeContext, tokens } from "../../Theme/theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Header } from "../global/Header";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import '../../assets/fonts/fonts.css'
import BASE_URL from "../../NetworkConfig/ApiConfig";
function renderDate(date) {
  console.log(date);
  if (date === undefined || date === null || date === "") {
    console.log(date);
    return new Date();
  }
  console.log(date);
  return new Date(date);
}

function printUserIdType(user) {
  // Regular expressions for email and phone number
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (emailRegex.test(user)) {
    return "Google";
  } else if (!isNaN(Number(user))) {
    return "Phone No.";
  } else {
    return "Facebook"; // It's neither an email nor a valid numeric value
  }
}
const columns = [


  {
    field: "full_name",
    headerName: "Full Name",
    flex: 0.6,
    // description: "This column has a value getter and is not sortable.",
    renderCell: (params) => {
      // console.log();

      return (

        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginX: "5px" }}>
          <img
            width="35px"
            height="35px"
            style={{ cursor: "pointer", borderRadius: "50%", objectFit: 'cover', border: " 3px solid #ffffff10" }}
            src={`${params.row.image[0]}`}
            alt=""
          />
          <Box ml={1}>
            <Typography fontFamily={"poppins-reg"}>{params.row.full_name}</Typography>
          </Box>
        </Box>

      );
    },
  },
  {
    field: "_id",
    headerName: "User Id",
    flex: 0.5,

    // filterable: false,
    // renderCell: (params) => {
    //   console.log(params);
    //   return params.row.index + 1;
    // },
  },

  {
    field: "buzz_name",
    headerName: "Buzz Name",
    flex: 0.3,
  },
  {
    field: "gender",
    headerName: "Gender",
    flex: 0.3,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    width: 180,
    valueFormatter: (params) => {
      if (params.value) {
        const date = format(new Date(params.value), "MM/dd/yyyy");
        return date;
      } else {
        const date = format(new Date("March 7, 2023"), "MM/dd/yyyy");
        return date;
      }
    },
  },
  {
    field: "ReferralCode",
    headerName: "Referral Code",
    flex: 0.4,
    // type: "date",
    renderCell: (params) => {
      if (params.row.ReferralCode) {
        return params.row.ReferralCode;
      } else {
        return "none";
      }
    },
  },
  {
    field: "UserId",
    headerName: "Account Type",
    flex: 0.4,
    // type: "date",
    renderCell: (params) => {
      if (params.row.UserId) {
        return printUserIdType(params.row.UserId);
      } else {
        return "none";
      }
    },
  },
  {
    field: "id",
    headerName: "",
    flex: 0.3,
    // description: "This column has a value getter and is not sortable.",
    renderCell: (params) => {
      return (
        <Tooltip title={"View Details"}>
          <Link
            to={{
              pathname: "/admin/particularuserdetails",
              search: `?userid=${params.id}`,
              state: { fromDashboard: true },
              // console.log(first)
            }}
          >
            <IconButton>
              <ArrowForwardOutlined />
            </IconButton>
          </Link>
        </Tooltip>
      );
    },
  },
];

const columnsMobile = [
  {
    field: "buzz_name",
    headerName: "Buzz Name",
    width: 150,
    // description: "This column has a value getter and is not sortable.",
    renderCell: (params) => {
      // console.log();

      return (

        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginX: "5px" }}>
          <img
            width="35px"
            height="35px"
            style={{ cursor: "pointer", borderRadius: "50%", objectFit: 'cover', border: " 3px solid #ffffff10" }}
            src={`${params.row.image[0]}`}
            alt=""
          />
          <Box ml={1}>
            <Typography fontFamily={"poppins-reg"}>{params.row.buzz_name}</Typography>
          </Box>
        </Box>

      );
    },
  }, {
    field: "gender",
    headerName: "Gender",
    flex: 1
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "date",
    flex: 1,
    valueFormatter: (params) => {
      if (params.value) {
        const date = format(new Date(params.value), "MM/dd/yyyy");
        return date;
      } else {
        const date = format(new Date("March 7, 2023"), "MM/dd/yyyy");
        return date;
      }
    },
  },
  {
    field: "id",
    headerName: "",
    flex: 1,
    // description: "This column has a value getter and is not sortable.",
    renderCell: (params) => {
      return (
        <Tooltip title={"View Details"}>
          <Link
            to={{
              pathname: "/admin/particularuserdetails",
              search: `?userid=${params.id}`,
              state: { fromDashboard: true },
              // console.log(first)
            }}
          >
            <IconButton>
              <ArrowForwardOutlined />
            </IconButton>
          </Link>
        </Tooltip>
      );
    },
  },
];

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UsersState, setUsersState] = useState(null);
  // const [loading, setloading] = useState(true);
  const [error, seterror] = useState("Loading.....");

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  const FetchDashboardDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/user/details/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();
    if (data.success === true) {
      setUsersState(data.response);
      console.log(data);
      console.log(UsersState);

      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchDashboardDetails();
  }, []);
  if (!UsersState) {
    return <Box>{error}</Box>;
  }

  function printUserIdType(user) {
    // Regular expressions for email and phone number
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(user.UserId)) {
      return "google";
    } else if (!isNaN(Number(user.UserId))) {
      return "phone";
    } else {
      return "facebook";
    }
  }
  if (UsersState) {
    UsersState.forEach(printUserIdType);
  }
  return (
    <Box m="20px">
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={"Registered Users"}
          subtitle={"Users who choosed 'Buzz Way of Life'"}
        />
        {/* <Button
          startIcon={<Schedule />}
          style={{
            borderRadius: 12,
            backgroundColor: "rgb(120, 120, 211)",
            // padding: "18px 36px",
            fontSize: "18px",
            height: "34px",
          }}
          variant="contained"
          component={Link}
          to="/hospital/scheduleappointments"

          //   disabled={false}
        >
          Schedule Appointments
        </Button> */}
      </Box>
      <Box
        m="5px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
            border: 'none',
            borderBottom: 'none',
            fontStyle: 'normal',
            textDecoration: 'none',
            fontWeight: 'normal',
            fontSize: '12px',
            color: '#ffffff',
            fontFamily: 'poppins-reg',
          },
          "& .name-column--cell": {
            color: "#ffffff10",
            border: 'none'
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#ffffff10",
            fontFamily: 'poppins-reg',
            fontSize: "14px",
            letterSpacing: '0.5px',
            color: '#ffffff',
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#ffffff05",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#ffffff10",
            fontFamily: 'poppins-reg'
          },
          "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
            fontFamily: 'poppins-reg'
          },
          "& .MuiCheckbox-root": {
            color: `#ffffff10 !important`,
            border: 'none'
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `#ffffff !important`,
            paddingX: "13px",
            background: "#ffffff05",
            border: "1.5px solid #ffffff20",
            borderRadius: "5px",
            marginBottom: "10px",
            marginRight: "5px", // Push the button to the end
          },
          "& .MuiDataGrid-toolbarContainer": {
            justifyContent: 'flex-start',
          }
        }}
      >
        {
          isMobileScreen && (
            <DataGrid
              rows={UsersState}
              columns={columnsMobile}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row._id}
              disableSelectionOnClick={false}
              density="comfortable"
              columnBuffer={2}
              rowBuffer={2}
              rowThreshold={2}
              columnThreshold={2}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
            />
          )
        }

        {
          !isMobileScreen && (
            <DataGrid
              rows={UsersState}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row._id}
              disableSelectionOnClick={false}
              density="comfortable"
              columnBuffer={2}
              rowBuffer={2}
              rowThreshold={2}
              columnThreshold={2}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
            />
          )
        }

      </Box>
    </Box>
  );
};

export default Users;
