import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
  Typography,
  Menu,
  MenuItem
} from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useState } from "react";
import { tokens } from "../../Theme/theme";
import { useEffect } from "react";
import { Header } from "../global/Header";
import SendIcon from "@mui/icons-material/Send";
import {
  ArrowCircleRightRounded,
  RemoveRedEye,
  ReportOffSharp,
  ReportProblem,
  ViewAgendaSharp,
  ArrowRightAltRounded,
  SendRounded,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Statbox from "../Dashborad/Statbox";
import BASE_URL from "../../NetworkConfig/ApiConfig";

const convertUTCToIST = (utcDateTime) => {
  // Define the time zones
  const utcTimeZone = "UTC";
  const istTimeZone = "Asia/Kolkata";

  // Convert UTC time to IST time zone
  const utcDate = new Date(utcDateTime);
  const istDate = utcToZonedTime(utcDate, istTimeZone);

  // Format the IST date and time
  const formattedIST = format(istDate, "yyyy-MM-dd HH:mm:ss");

  return formattedIST;
};
const Chirps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Unmasked, setUnmasked] = useState(false);
  const [Reported, setReported] = useState(false);
  const [Chirps, setChirps] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [statics, setStatics] = useState(null)
  const [searchDate, setSearchDate] = useState(null)
  const [error, seterror] = useState("Loading.....");

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setFilter(value)
    handleClose();
  };
  // const [loading, setloading] = useState(true);
  const FetchChirpDetails = async () => {
    // setloading(true);

    setSearchDate(selectedDate)
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/chirps/getallchirps/?unmasked=${Unmasked}&reported=${Reported}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify({
          filterdate: selectedDate,
        }),
      }
    );
    const data = await response.json();
    if (data.success === true) {
      if(statics == null){
        setStatics(data.response)
      }
      setChirps(data.response);
      console.log(data);
      console.log(Chirps);
      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchChirpDetails();
  }, []);
  if (!Chirps) {
    return (
      <>
        <Box m={"20px"}>{error}</Box>
      </>
    );
  }
  const handleDateChange = (e) => {
    const { value } = e.target;
    setSelectedDate(value);
  };
  const HandleDateClick = () => {
    // setlocations(null);
    setChirps(null);
    FetchChirpDetails();
  };
  const HandleResetClick = () => {
    // setlocations(null);
    setChirps(null);
    setSelectedDate(null);
    FetchChirpDetails();
  };
  return (
    <>
      <Box m={"20px"}>
        <Box display={"flex"} justifyContent="space-between">
          <Header
            title={`Chirps Sent By Users`}
            subtitle={"Anonymous Chatting Between Buzz Users"}
          />
        </Box>

        <Box
          display={"grid"}
          gridTemplateColumns="repeat(12,1fr)"
          gridAutoRows={"140px"}
          gap="20px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn={"span 4"}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems={"center"}
            sx={{
              border: "1px solid transparent",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
            }}
            justifyContent="center"
          >
            <Statbox
              title={statics.all.length}
              subtitle="Total Chirps"
              users={false}
              report={0}
              reportSuffix={"No Data Available"}
            />
          </Box>
          <Box
            gridColumn={"span 4"}
            sx={{
              border: "1px solid transparent",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
            }}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Statbox
              title={statics.Unmasked.length}
              subtitle="Unmasked Chirps"
              users={false}
              report={0}
              reportSuffix={"No Data Available"}

            />
          </Box>
          <Box
            gridColumn={"span 4"}
            backgroundColor={colors.primary[400]}
            display="flex"
            sx={{
              border: "1px solid transparent",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
            }}
            alignItems={"center"}
            justifyContent="center"
          >
            <Statbox
              title={statics.Reported.length}
              subtitle="Reported Chirps"

              users={false}
              report={0}
              reportSuffix={"No Data Available"}
            />
          </Box>

        </Box>
        <Box
          mb={3}
          marginTop={4}
          display={"flex"}
          justifyContent="flex-start"
          alignItems={"center"}
        >
          <input
            type="date"
            name=""
            id=""
            value={selectedDate}
            onChange={handleDateChange}
            style={{
              paddingLeft: "10px", paddingTop: '8px',
              paddingRight: "10px", paddingBottom: '8px',
              fontFamily: 'poppins-reg',
              backgroundColor: "#ffffff00",
              border: '1px solid #ffffff20',
              color: '#ffffff',
              borderRadius: '6px',
              outline: 'none',
              fontSize: "13px"
            }}
          />

          <Button
            // startIcon={<Add />}
            style={{
              width: 'max-content',
              fontSize: "13px",
              paddingLeft: "15px", paddingTop: '7px',
              paddingRight: "15px", paddingBottom: '7px',
              backgroundColor: "#ffffff15",
              borderRadius: "6px",
              border: "1px solid #ffffff25",
              marginLeft: "10px",
              textAlign: 'center',
              fontFamily: 'poppins-reg',
              textTransform: 'none'
            }}
            variant="contained"
            // component={Link}
            // to="/admin/addenabledlocation"
            onClick={HandleDateClick}
          //   disabled={false}
          >
            Search
          </Button>
          <Button
            // startIcon={<Add />}
            style={{
              width: 'max-content',
              fontSize: "13px",
              paddingLeft: "15px", paddingTop: '7px',
              paddingRight: "15px", paddingBottom: '7px',
              backgroundColor: "#ffffff15",
              borderRadius: "6px",
              border: "1px solid #ffffff25",
              marginLeft: "10px",
              textAlign: 'center',
              fontFamily: 'poppins-reg',
              textTransform: 'none'
            }}
            variant="contained"
            // component={Link}
            // to="/admin/addenabledlocation"
            onClick={HandleResetClick}
          //   disabled={false}
          >
            ⟳ Reset
          </Button>

          <Box sx={{
            flex: 1,
            flexDirection: 'row-reverse',
            display: 'flex'
          }}>

            <Button
              // startIcon={<Add />}
              style={{
                width: 'max-content',
                fontSize: "13px",
                paddingLeft: "15px", paddingTop: '7px',
                paddingRight: "15px", paddingBottom: '7px',
                backgroundColor: "#ffffff15",
                borderRadius: "6px",
                border: "1px solid #ffffff25",
                marginLeft: "10px",
                textAlign: 'center',
                fontFamily: 'poppins-reg',
                textTransform: 'none'
              }}
              variant="contained"
              // component={Link}
              // to="/admin/addenabledlocation"
              onClick={handleClick}
            //   disabled={false}
            >
              {filter == 0 ? "Filter Chirps By ▾" : filter == 1 ? "Unmasked Chirps ▾" : "Reported Chirps ▾"}
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem style={{
                fontFamily:'poppins-reg',
                fontSize:"12px"
              }} onClick={() => handleMenuItemClick(0)}>None</MenuItem>
              <MenuItem style={{
                fontFamily:'poppins-reg',
                fontSize:"12px"
              }} onClick={() => handleMenuItemClick(1)}>Unmasked</MenuItem>
              <MenuItem style={{
                fontFamily:'poppins-reg',
                fontSize:'12px'
              }} onClick={() => handleMenuItemClick(2)}>Reported</MenuItem>
            </Menu>

          </Box>

        </Box>

        <Typography style={{
          fontFamily: 'poppins-reg',
          color: '#ffffff90',
          marginBottom: "20px",
          fontSize: '14px',
          marginLeft: '2px'
        }} >
          {(filter == 0 ? Chirps.all.length : filter == 1 ? Chirps.Unmasked.length : Chirps.Reported.length)} chirps was {filter == 0 ? "sent" : filter == 1 ? "unmasked": "reported"} {searchDate == null ? "till now" : `on ${format(new Date(searchDate), "MMM dd, yyyy")}`}</Typography>
        <Box
          display={"grid"}
          gridTemplateColumns="repeat(12,1fr)"
          gap="20px"
        >
          {/* ROW 1 */}
          {(filter == 0 ? Chirps.all : filter == 1 ? Chirps.Unmasked : Chirps.Reported).map((ChirpObject) => {
            return (
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                alignItems="center"
                flexDirection={'column'}
                px="15px"
                py={"8px"}
                backgroundColor={"#ffffff05"}
                borderRadius={"10px"}
              >
                <Box
                  display="flex"
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  <Box
                    component={Link}
                    style={{ textDecoration: "none", width: "40px", height: "40px", display: 'flex', flexDirection: 'column' }}
                    target="_blank"
                    to={{
                      pathname: "/admin/particularuserdetails",
                      search: `?userid=${ChirpObject.From._id}`,
                      state: { fromDashboard: true },
                      // console.log(first)
                    }}
                  >
                    <img
                      alt="profile-user"
                      width="40px"
                      height="40px"
                      src={`${ChirpObject.From.image[0]}`}
                      style={{ cursor: "pointer", borderRadius: "50%", border: "5px solid #ffffff10", objectFit: 'cover' }}
                    />

                  </Box>
                  <Box sx={{ flexDirection: 'column', marginLeft: "10px", }} >
                    <Box sx={{
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: "flex-start",
                      display: 'flex'
                    }}><Typography style={{
                      fontFamily: 'poppins-mid',
                      fontSize: "12px",
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: "110px"
                    }}>{ChirpObject.From.buzz_name}</Typography>

                    </Box>
                  </Box>
                  <Box sx={{
                    width: "20px", height: "20px", backgroundColor: "#1d1d1d", borderRadius: '50px',
                    justifyContent: 'center', marginX: '10px', display: 'flex', alignItems: 'center',
                  }}>
                    <SendRounded style={{ color: "#ffffff", fontSize: "11px" }} />
                  </Box>
                  <Box
                    component={Link}
                    style={{ textDecoration: "none", width: "40px", height: "40px", display: 'flex', flexDirection: 'column' }}
                    target="_blank"
                    to={{
                      pathname: "/admin/particularuserdetails",
                      search: `?userid=${ChirpObject.To._id}`,
                      state: { fromDashboard: true },
                      // console.log(first)
                    }}
                  >
                    <img
                      alt="profile-user"
                      width="40px"
                      height="40px"
                      src={`${ChirpObject.To.image[0]}`}
                      style={{ cursor: "pointer", borderRadius: "50%", border: "5px solid #ffffff10", objectFit: 'cover' }}
                    />

                  </Box>
                  <Box sx={{ flexDirection: 'column', marginLeft: "10px", flex: 1 }} >
                    <Box sx={{
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: "flex-start",
                      display: 'flex'
                    }}><Typography style={{
                      fontFamily: 'poppins-mid',
                      fontSize: "12px",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: "110px"
                    }}>{ChirpObject.To.buzz_name}</Typography>

                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems={"flex-start"}
                  flexDirection={'column'}
                >
                  <Box sx={{ marginTop: "10px", marginLeft: "5px", display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '100%' }}>
                    <Typography
                      style={{
                        fontFamily: 'poppins-reg', fontSize: "12px",
                        paddingTop: "3px",
                        flex: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingBottom: "3px",
                        borderRadius: "3px",
                      }}>
                      {ChirpObject.Chirp.Chirp}
                    </Typography>
                    {
                      ChirpObject.Chirp.ReactionReceived != "" && (
                        <Typography style={{
                          borderRadius: "50px",
                          fontFamily: 'poppins-reg',
                          marginTop: "2px",
                          fontSize: "10px",
                          width: "19px",
                          height: "19px",
                          textAlign: 'center',
                          marginLeft: "5px",
                          verticalAlign: 'middle',
                          background: "#ffffff20",
                          border: "1px solid #ffffff20",
                          borderRadius: "50%",
                          color: "#ffffff"
                        }} >{ChirpObject.Chirp.ReactionReceived}</Typography>
                      )
                    }


                  </Box>
                  <Box sx={{ marginTop: "5px", display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '3px' }}>


                    <Typography style={{
                      fontFamily: 'poppins-reg', fontSize: "10px",
                      wordBreak: 'break-word',
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      borderRadius: "3px",
                      backgroundColor: "#ffffff10",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginRight: "5px",
                      color: '#aaaaaa',
                    }}>
                      {ChirpObject.Comments.length} Comments
                    </Typography>



                    {
                      ChirpObject.Unmasked && (
                        <Typography style={{
                          fontFamily: 'poppins-reg', fontSize: "10px",
                          wordBreak: 'break-word',
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          borderRadius: "3px",
                          backgroundColor: "#ff880010",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          marginRight: "5px",

                          color: '#ff8800'
                        }}>
                          Unmasked
                        </Typography>
                      )
                    }

                    {
                      ChirpObject.Reported && (
                        <Typography style={{
                          fontFamily: 'poppins-reg', fontSize: "10px",
                          wordBreak: 'break-word',
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          borderRadius: "3px",
                          border: '1px solid #ff200060',
                          backgroundColor: "#ff200010",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          color: '#ff2000'
                        }}>
                          Reported
                        </Typography>

                      )
                    }


                  </Box>

                </Box>

                <Box
                  display="flex"
                  alignItems={"flex-end"}
                  flexDirection={'column-reverse'}
                  justifyContent="flex-end"
                  flex={1}
                  alignSelf={'flex-end'}
                  mt={"10px"}
                  mb={"5px"}
                >
                  <Button
                    // disabled={imageupload}
                    startIcon={<ArrowRightAltRounded />}
                    style={{
                      borderRadius: 50,
                      backgroundColor: "#ffffff10",
                      // padding: "18px 36px",
                      fontSize: "12px",
                      fontFamily: 'poppins-reg',
                      alignSelf: 'stretch',
                      textTransform: 'none'
                    }}
                    variant="contained"
                    component={Link}
                    to={{
                      pathname: "/admin/particularchirpdetails",
                      search: `?chirpid=${ChirpObject._id}`,
                      state: { fromDashboard: true },
                      // console.log(first)
                    }}
                  >
                    View Details
                  </Button>
                </Box>


              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default Chirps;
