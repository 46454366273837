import BASE_URL from "../../../NetworkConfig/ApiConfig";

const Deletelocationdetailsapi = async (id, token) => {
    // const token = localStorage.getItem("token");
    console.log(id)
    const response = await fetch(`${BASE_URL}/enabledlocation/deletealocation/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "auth-token": token,
        },

    });
    const json = await response.json();
    // if (json.success === true) {
    return json
    // }
}

export default Deletelocationdetailsapi 