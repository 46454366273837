import { ExpandMoreOutlined, Save } from "@mui/icons-material";
import {
  Add,
  Camera,
  CameraAltOutlined,
  CropOriginal,
  Delete,
  DeleteForever,
  DeleteOutline,
  Update,
} from "@mui/icons-material";
import * as EmailValidator from "email-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";

import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import { useState } from "react";
import { useEffect } from "react";
import ModifyLocationdetails from "./ModifydataApi";
import SnackbarComponent from "./SnackbarComponent";

const FirstRow = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [LocationName, setLocationName] = useState("");
  const [Lattitude, setLattitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [LocationNameError, setLocationNameError] = useState(false);
  const [LattitudeError, setLattitudeError] = useState(false);
  const [LongitudeError, setLongitudeError] = useState(false);
  const [buttonloading, setbuttonloading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const ModifyData = async () => {
    try {
      setbuttonloading(true);
      if (
        LocationNameError === true ||
        LattitudeError === true ||
        LongitudeError === true
      ) {
        console.log(1);
        setbuttonloading(false);

        return false;
      }
      const response = await ModifyLocationdetails(
        data._id,
        localStorage.getItem("token"),
        {
          LocationName: LocationName,
          LocationCoordinate: [Lattitude, Longitude],
        }
      );
      if (response.success === true) {
        setbuttonloading(false);
        setseverity("success");
        setAlertMessage("Data Saved Successfully");
        setShowSnackbar(true);
      }
      if (response.success === false) {
        setbuttonloading(false);
        setseverity("error");
        setAlertMessage("INTERNAL SERVER ERROR");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleLocationChange = (e) => {
    const { value } = e.target;
    setLocationName(value);
    if (value.length < 1) {
      setLocationNameError(true);
      return false;
    }
    setLocationNameError(false);
    return true;
  };
  const handleLattitudeChange = (e) => {
    const { value } = e.target;
    setLattitude(value);
    if (value.length < 1) {
      setLattitudeError(true);
      return false;
    }
    setLattitudeError(false);
    return true;
  };
  const handleLongitudeChange = (e) => {
    const { value } = e.target;
    setLongitude(value);
    if (value.length < 1) {
      setLongitudeError(true);
      return false;
    }
    setLongitudeError(false);
    return true;
  };
  if (!data) {
    <Box>NOT GETTING DATA</Box>;
  }
  useEffect(() => {
    console.log(data);
    if (data) {
      setLocationName(data.LocationName);
      setLattitude(data.LocationCoordinate[0]);
      setLongitude(data.LocationCoordinate[1]);
    }
  }, []);
  return (
    <Box>
      <Accordion sx={{ backgroundColor: colors.blueAccent[900] }}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          LOCATION NAME,LATTITUDE,LONGITUDE
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display={"grid"}
            gridTemplateColumns="repeat(12,1fr)"
            gridAutoRows={"80px"}
            gap="20px"
          >
            {/* ROW 1 */}
            <Box
              gridColumn={"span 4"}
              //   backgroundColor={colors.primary[400]}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <TextField
                // gridColumn="span 8"
                // gridRow="span 3"
                id=""
                error={LocationNameError}
                label="LOCATION NAME"
                //   color={colors.greenAccent[200]}
                //   fullWidth
                helperText={
                  LocationNameError ? "Enter Valid Location Name" : null
                }
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
                value={LocationName}
                onChange={handleLocationChange}
              />
            </Box>
            <Box
              gridColumn={"span 4"}
              //   backgroundColor={colors.primary[400]}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <TextField
                // gridColumn="span 8"
                // gridRow="span 3"
                id=""
                label="LATITUDE"
                error={LattitudeError}
                type="number"
                helperText={LattitudeError ? "Enter Lattitude" : null}
                //   color={colors.greenAccent[200]}
                //   fullWidth
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
                value={Lattitude}
                onChange={handleLattitudeChange}
              />
            </Box>
            <Box
              gridColumn={"span 4"}
              //   backgroundColor={colors.primary[400]}
              //   gridRow="span 3"
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <TextField
                // gridColumn="span 8"
                // gridRow="span 3"
                id=""
                error={LongitudeError}
                helperText={LongitudeError ? "Enter Longitude" : null}
                label="LONGITUDE"
                //   color={colors.greenAccent[200]}
                //   fullWidth
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: colors.blueAccent[800],
                    paddingLeft: 7,
                    fontWeight: "bold",
                  },
                }}
                sx={{
                  width: "100%",
                  marginTop: 2,
                  backgroundColor: colors.grey[300],
                  input: { color: colors.grey[900], fontWeight: "bold", pl: 1 },
                  // borderRadius: "10%",
                }}
                value={Longitude}
                onChange={handleLongitudeChange}
              />
            </Box>
          </Box>
          <Box pb={"10px"} display={"flex"} justifyContent="center">
            {/* <img src="" alt="" /> */}
            <Button
              disabled={buttonloading}
              startIcon={<Save />}
              style={{
                borderRadius: 12,
                backgroundColor: colors.greenAccent[500],
                // padding: "18px 36px",
                fontSize: "18px",
                height: "34px",
                marginBottom: "2px",
              }}
              variant="contained"
              component="label"
              onClick={ModifyData}
              //   disabled={false}
            >
              Save Changes
              {/* {buttonloading === true ? buttontext : "ADD THE LOCATION"} */}
              {/* <input hidden accept="image/*" multiple type="file" /> */}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </Box>
  );
};

export default FirstRow;
