import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Box, color, useTheme } from "@mui/system";
import { tokens } from "../../Theme/theme";
import '../../assets/fonts/fonts.css'

const BarChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log(data);
  if (!data) {
    return <Box>Loading....</Box>;
  }
  return (
    // <Box>
    <ResponsiveBar
      data={data}
      keys={["users"]}
      indexBy="age"
      borderRadius={5}
      theme={{
        tooltip: {
          container: {
            // background: colors.grey[500],
            color: colors.primary[500],
          },
        },

        // background: "#ffff",
        fontFamily: 'poppins-reg',
        textColor: colors.grey[400],
        axis: {
          legend: {
            text: {
              fontSize: "16px",
            },
          },
          domain: {
            line: {
              strokeWidth: "1px",
              stroke: "#ffffff20",
            },
          },
        },
        grid: {
          line: {
            stroke: colors.grey[800], // Set the color of the grid lines here
            strokeWidth: 1,
          },
        },
      }}

      margin={{ top: 50, right: 50, bottom: 60, left: 70 }}
      padding={0.3}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Age",
        legendPosition: "middle",
        legendOffset: 42,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Users",
        legendPosition: "middle",
        legendOffset: -45,
      }}
      colors={{ scheme: 'pastel2' }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"#000000"}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
    // </Box>
  );
};

export default BarChart;
