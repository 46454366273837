import React from "react";
import { tokens } from "../../Theme/theme";
import Statbox from "../Dashborad/Statbox";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import BarChart from "../Charts/BarChart";
import PieChart from "../Charts/PieChart";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import LineChart from "../Charts/LineChart";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  ArrowForwardOutlined,
  BrowseGallery,
  Edit,
  Schedule,
  Upload,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
  Typography,
  Switch,
  Button,
  useMediaQuery
} from "@mui/material";
import { format } from "date-fns";
import { mockDataTeam } from "../../data/mockdata";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Header } from "../global/Header";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useState } from "react";
import { useEffect } from "react";
import MeetUpComponent from "./MeetUpComponent";
import DeleteAlocation from "./EditEnabledLocation/DeleteAlocation";
import BASE_URL from "../../NetworkConfig/ApiConfig";
function renderDate(date) {
  // console.log(date);
  if (!date) {
    return "";
  }
  return format(new Date(date), "MM/dd/yyyy");
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const IndividualEnabledLocationDetails = () => {
  let query = useQuery();
  console.log(query.get("location"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [graphData, setgGraphData] = useState(null)

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "Sr no",
    //   flex: 0.5,

    //   // filterable: false,
    //   // renderCell: (params) => {
    //   //   console.log(params);
    //   //   return params.row.index + 1;
    //   // },
    // },

    {
      field: "image",
      headerName: "Photo",
      flex: 0.2,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        // console.log();

        return (
          <img
            width="30px"
            height="30px"
            style={{ cursor: "pointer", borderRadius: "50%", objectFit: 'cover' }}
            src={`${params.row.image[0]}`}
            alt=""
          />
        );
      },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 0.35,
    },
    {
      field: "buzz_name",
      headerName: "Buzz Name",
      flex: 0.35,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 0.3,
    },
    {
      field: "Active",
      headerName: "Active",
      flex: 0.3,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      width: 180,
      valueFormatter: (params) => {
        if (params.value) {
          const date = format(new Date(params.value), "MM/dd/yyyy");
          return date;
        } else {
          const date = format(new Date("March 7, 2023"), "MM/dd/yyyy");
          return date;
        }
      },
    },

    {
      field: "id",
      headerName: "",
      flex: 0.1,
      // description: "This column has a value getter and is not sortable.",
      renderCell: (params) => {
        return (
          <Tooltip title={"View Details"}>
            <Link
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${params.id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <IconButton>
                <ArrowForwardOutlined />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  const [isAdmin, setIsAdmin] = useState(false)
  const [IndividualEnabledLocationState, setIndividualEnabledLocationState] =
    useState(null);
  const [Users, setUsers] = useState(null);
  const [error, seterror] = useState("Loading......");
  // const [loading, setloading] = useState(true);

  useEffect(() => {
    const profile = localStorage.getItem("profile")

    if (profile.Role) {
      if (profile.Role == "ADMIN") {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    }else{
      FetchAdminDetails()
    }
  }, [])

  const FetchAdminDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/admin/getadmindetails/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    });
    const data = await response.json();

    if (data.success === true) {
      localStorage.setItem("profile", data.response)
      if (data.response.Role == "ADMIN") {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
      return;
    }
  };

  const FetchGraphData = async (referral_code) => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/enabledlocation/graphdata/${referral_code}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    console.log(data)
    if (data.success === true) {
      setgGraphData(data.response.data)
      console.log(data);
      // console.log(UsersState);

      return; // setloading(false);
    }
    if (data.success === false) {
      //seterror(data.response);
      return;
    }
  };

  const FetchEnabledLocationDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/enabledlocation/fetchparticularmain/${query.get("location")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIndividualEnabledLocationState(data.response);
      FetchGraphData(data.response["Details"]["ReferralCode"])
      setUsers(data.response.TotalUserOnLocation);
      console.log(data);
      console.log(IndividualEnabledLocationState);
      console.log(data.response.TotalUserOnLocation);
      // IndividualEnabledLocationDetails.TotalUserOnLocation;
      // setloading(false);
      return;
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchEnabledLocationDetails();
  }, []);
  if (!IndividualEnabledLocationState) {
    console.log(IndividualEnabledLocationDetails["TotalUsersCountonLocation"]);
    return <Box>{error}</Box>;
  }




  return (
    <Box m="20px">
      <Box
        display={"flex"}
        justifyContent="space-between"
        flexDirection={(isMobileScreen || isTabScreen) ? 'column' : 'row'}
        alignItems={(isMobileScreen || isTabScreen) ? "flex-start": "center"}
      >

        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: "30px" }}>

          <img
            alt="profile-user"
            width="80px"
            height="80px"
            style={{
              cursor: "pointer", borderRadius: "50%", objectFit: 'cover', border: "8px solid #ffffff07",
              marginRight: "10px"
            }}
            src={IndividualEnabledLocationState["Details"]["Logo"]}
          />
          <Header
            imgInc
            title={IndividualEnabledLocationState["Details"]["LocationName"]}
            subtitle={`Buzz Enabled Since ${format(
              new Date(IndividualEnabledLocationState["Details"]["createdAt"]),
              " dd/MM/yyyy, hh:mma"
            )}`}
          />
        </Box>

        <Box
          display={"flex"}
          flex={1}
          justifyContent="flex-end"
          alignItems={"center"}
          mb={2}
        >
          <Button
            startIcon={<Edit />}
            style={{
              borderRadius: 6,
              backgroundColor: "#706dea20",
              border: '2px soild #ffffff20',
              // padding: "18px 36px",
              fontSize: "13px",
              height: "34px",
              color: '#706dea',
              textTransform: 'none',
              fontFamily: 'poppins-reg'
            }}
            variant="contained"
            component={Link}
            to={`/admin/editenabledlocationdetails?location=${query.get(
              "location"
            )}`}

          //   disabled={false}
          >
            Edit {(isMobileScreen || isTabScreen) ? "" : "Details" }
          </Button>
          <Button
            startIcon={<CollectionsIcon />}
            style={{
              borderRadius: 6,
              backgroundColor: "#706dea20",
              border: '2px soild #ffffff20',
              // padding: "18px 36px",
              fontSize: "13px",
              color: '#706dea',

              height: "34px",
              textTransform: 'none',
              fontFamily: 'poppins-reg',
              marginLeft: "15px"
            }}
            variant="contained"
            component={Link}
            to={`/admin/addnewimageforalocation?location=${query.get(
              "location"
            )}`}

          //   disabled={false}
          >
            Add {(isMobileScreen || isTabScreen) ? "" : "New"} Images
          </Button>
          {/* <Button
          startIcon={<Upload />}
          style={{
            borderRadius: 12,
            backgroundColor: colors.greenAccent[500],
            // padding: "18px 36px",
            fontSize: "18px",
            height: "34px",
          }}
          variant="contained"
          component={Link}
          to="/admin/addenabledlocation"

          //   disabled={false}
        >
          UPLOAD NEW LOGO AND IMAGES
        </Button> */}

          {
            isAdmin && (
              <DeleteAlocation
                locationid={IndividualEnabledLocationState["Details"]["_id"]}
              />
            )
          }


        </Box>

      </Box>

      <Box
        display={"grid"}
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows={"140px"}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={(isMobileScreen) ? "span 12" : "span 5"}
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={IndividualEnabledLocationState["TotalUsersCountonLocation"]}
            subtitle="Total Users Acquired"
            icon={
              <StorefrontIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }

            reportSuffix={query.get("location") == "67177fcf29d9dd51e6953063" ? "out of 2500 commited" : ""}
          />
        </Box>

        <Box
          gridColumn={(isMobileScreen) ? "span 12" : "span 5"}
          sx={{
            border: "1px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(180deg,#ffffff20,#ffffff20, #121319) border-box"
          }}

          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Statbox
            title={IndividualEnabledLocationState["TodayOnboard"]}
            subtitle="Total Users Acquired Today"
            icon={
              <GroupAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
            users={false}
            report={0}
            reportSuffix={""}
          />
        </Box>

        {/* ROW 2 */}

        {
          query.get("location") != "67177fcf29d9dd51e6953063" && (
            <Box gridColumn="span 12">
              <MeetUpComponent
                showLabel={false}
                locationid={IndividualEnabledLocationState.Details._id}
              />
            </Box>
          )
        }

        {/*row 3*/}

        <Box
          gridColumn={(isMobileScreen|| isTabScreen) ? "span 12" : "span 8"}
          gridRow="span 3"
          sx={{
            border: "2px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontFamily={"poppins-mid"}
                color={colors.grey[100]}
              >
                Acquired users (last 10 days)
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <LineChart
              // isDashboard={true}
              color={"set3"}
              data={graphData}
            />
          </Box>
        </Box>

        <Box
          gridColumn={(isMobileScreen|| isTabScreen) ? "span 12" : "span 4"}
          gridRow="span 3"
          sx={{
            borderRadius: "10px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid #ffffff10`}
            sx={{ background: '#ffffff10' }}
            colors={colors.grey[100]}
            paddingX="15px" paddingY={"10px"}
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="200"
              fontFamily={"poppins-reg"}>
              Location Details
            </Typography>
          </Box>

          <Box p={'15px'} borderBottom={`1px solid #ffffff10`}
          >
            <Typography color={"#ffffff40"} fontFamily={"poppins-reg"} fontSize={"12px"} style={{ userSelect: true }}>Address</Typography>
            <Typography
              color={'white'}
              variant="h6"
              fontWeight="400"
              fontFamily={"poppins-reg"}
            >
              {`${IndividualEnabledLocationState["Details"]["Address"]}`}
            </Typography>
          </Box>

          <Box
            // key={`${transaction.txId}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid #ffffff10`}
            paddingX="15px"
            paddingY={"10px"}>
            <Box>
              <Typography color={"#ffffff40"} fontFamily={"poppins-reg"} fontSize={"12px"} style={{ userSelect: true }}>Coordinates</Typography>
              <Typography
                color={'white'}
                variant="h6"
                fontWeight="400"
                fontFamily={"poppins-reg"}
              >
                {`
                ${IndividualEnabledLocationState["Details"]["LocationCoordinate"][0]},
                ${IndividualEnabledLocationState["Details"]["LocationCoordinate"][1]}
                `}
              </Typography>

            </Box>
            <Box
              component={Link}
              style={{ textDecoration: 'none', fontFamily: 'poppins-reg', fontSize: '14px', border: "1px solid #aaaaaaa20" }}
              target={"_blank"}
              to={`https://www.google.com/maps/search/?api=1&query=${IndividualEnabledLocationState["Details"]["LocationCoordinate"][0]},${IndividualEnabledLocationState["Details"]["LocationCoordinate"][1]}`}
              color={"#706dea"}>
              Open in Map
            </Box>
          </Box>
          <Box
            // key={`${transaction.txId}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={'row'}
            borderBottom={`1px solid #ffffff10`}
            paddingX="15px" paddingY={"10px"}
          >
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography color={"#ffffff40"} fontFamily={"poppins-reg"} fontSize={"12px"} style={{ userSelect: true }}>State</Typography>
              <Typography
                color={'white'}
                variant="h6"
                fontWeight="400"
                fontFamily={"poppins-reg"}
              >
                {`${IndividualEnabledLocationState["Details"]["State"]}`}
              </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography color={"#ffffff40"} fontFamily={"poppins-reg"} fontSize={"12px"} style={{ userSelect: true }}>District</Typography>
              <Typography
                color={'white'}
                variant="h6"
                fontWeight="400"
                fontFamily={"poppins-reg"}
              >
                {`${IndividualEnabledLocationState["Details"]["District"]}`}
              </Typography>
            </Box>
          </Box>
          <Box p={'15px'} borderBottom={`1px solid #ffffff10`} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}
          >
            <Typography color={"#ffffff40"} fontFamily={"poppins-reg"} fontSize={"12px"} style={{ userSelect: true, }}>Referral Code</Typography>
            <Typography
              color={'white'}
              variant="h5"
              fontWeight="400"
              fontFamily={"poppins-reg"}
              style={{
                border: "1px solid #aaaaaa20", alignSelf: 'flex-start',
                paddingInline: "15px", paddingTop: "4px", paddingBottom: "4px",
                background: '#aaaaaa10', borderRadius: "4px"
              }}
            >
              {`${IndividualEnabledLocationState["Details"]["ReferralCode"]}`}
            </Typography>
          </Box>

          <Box p={'15px'} borderBottom={`1px solid #ffffff10`}
          >
            <Typography color={"#ffffff40"} fontFamily={"poppins-reg"} fontSize={"12px"} style={{ userSelect: true }}>Contact Mail</Typography>
            <Typography
              color={'white'}
              variant="h6"
              fontWeight="400"
              fontFamily={"poppins-reg"}
            >
              {`${IndividualEnabledLocationState["Details"]["LocationEmail"]}`}
            </Typography>
          </Box>

        </Box>

        <Box
          gridColumn={(isMobileScreen|| isTabScreen) ? "span 12" : "span 4"}
          gridRow="span 3"
          sx={{
            border: "2px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                GENDER CHART
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <PieChart
              // isDashboard={true}
              data={IndividualEnabledLocationState["Gender"]}
            />
          </Box>
        </Box>

        <Box
          gridColumn={(isMobileScreen|| isTabScreen) ? "span 12" : "span 8"}
          gridRow="span 3"
          sx={{
            border: "2px solid transparent",
            borderRadius: "20px",
            background:
              "linear-gradient(#121319 0 0) padding-box,linear-gradient(45deg,#ffffff20, #00000000) border-box"
          }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                AGE CHART
              </Typography>
            </Box>
          </Box>
          <Box height="400px" m="-20px 0 0 0">
            <BarChart
              data={IndividualEnabledLocationState["Age"]}

            // isDashboard={true}
            />
          </Box>
        </Box>




        {/* Row 3 */}


        <Box
          gridColumn="span 12"
          gridRow="span 3"
          m="10px 0 0 0"
          height="85vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell, .MuiDataGrid-cellContent": {
              border: 'none',
              borderBottom: 'none',
              fontStyle: 'normal',
              textDecoration: 'none',
              fontWeight: 'normal',
              fontSize: '12px',
              color: '#ffffff',
              fontFamily: 'poppins-reg',
            },
            "& .name-column--cell": {
              color: "#ffffff10",
              border: 'none'
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg',
              fontSize: "14px",
              letterSpacing: '0.5px',
              color: '#ffffff',
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#ffffff05",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#ffffff10",
              fontFamily: 'poppins-reg'
            },
            "& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-displayedRows": {
              fontFamily: 'poppins-reg'
            },
            "& .MuiCheckbox-root": {
              color: `#ffffff10 !important`,
              border: 'none'
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `#ffffff !important`,
              paddingX: "13px",
              background: "#ffffff05",
              border: "1.5px solid #ffffff20",
              borderRadius: "5px",
              marginBottom: "10px",
              marginRight: "5px", // Push the button to the end
            },
            "& .MuiDataGrid-toolbarContainer": {
              justifyContent: 'flex-start',
            }
          }}
        >
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            //   loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={Users || []}
            columns={columns}
            disableSelectionOnClick={false}
            density="comfortable"
          />
        </Box>


        {/* <Box
          gridColumn={"span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Box>
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={mockDataTeam}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                getRowId={(row) => row._id}
                disableSelectionOnClick={false}
                density="comfortable"
              />
            </Box> */}
        {/* </Box> */}
        {/* </Box> */}

      </Box>

    </Box>
  );
};

export default IndividualEnabledLocationDetails;
