import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { tokens } from "../../Theme/theme";
import '../../assets/fonts/fonts.css'

export const Header = ({ title, subtitle, imgInc = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:1200px)')

  return (
    <Box mb= { imgInc ? "0px" : "30px"}>
      <Typography
        variant={(isMobileScreen || isTabScreen) ? "h4" : "h3"}
        color={colors.grey[100]}
        fontFamily={"poppins-reg"}
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant={(isMobileScreen || isTabScreen) ? "h6" :"h5"} fontFamily={'poppins-reg'} color={colors.grey[500]}>
        {subtitle}
      </Typography>
    </Box>
  );
};
