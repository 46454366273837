import { Header } from "../global/Header";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
  useThemeProps,
  Typography,
  Badge,
  Divider,
} from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useState } from "react";
import { tokens } from "../../Theme/theme";
import SendIcon from "@mui/icons-material/Send";
import {
  ArrowCircleRightRounded,
  RemoveRedEye,
  ReportOffSharp,
  ReportProblem,
  ViewAgendaSharp,
  SendRounded
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Send } from "@mui/icons-material";
import BASE_URL from "../../NetworkConfig/ApiConfig";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const IndividualChirps = () => {
  let query = useQuery();
  console.log(query.get("chirpid"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, seterror] = useState("Loading.....");
  const [Chirp, setChirp] = useState(null);
  const [sender, setSender] = useState("")
  const FetchChirpDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/chirps/getaparticularchirp/${query.get("chirpid")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setChirp(data.response);
      setSender(data.response.From.full_name)
      console.log(data);
      console.log(Chirp);
      return; // setloading(false);
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  useEffect(() => {
    FetchChirpDetails();
  }, []);
  if (!Chirp) {
    return (
      <>
        <Box m={"20px"}>{error}</Box>
      </>
    );
  }
  return (
    <>
      <Box m={"20px"}>
        <Box display={"flex"} justifyContent="space-between">
          <Header
            title={`Chirp [${Chirp._id}]`}
            subtitle={`Chirp was sent on ${format(
              new Date(Chirp.createdAt),
              "MMMM dd, yyyy"
            )}`}
          />
        </Box>
        <Box>
          <Box
            display="flex"
            mt={"20px"}
            mb={"20px"}
            alignItems={"center"}
          >
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent="center"
              sx={{ textDecoration: 'none' }}
              component={Link}
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${Chirp.From._id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            //   underline="none"
            >
              <img
                alt="profile-user"
                width="70px"
                height="70px"
                src={`${Chirp.From.image[0]}`}
                style={{ cursor: "pointer", borderRadius: "50%", border: '5px solid #ffffff10', objectFit: 'cover' }}
              />
              <Box sx={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: "flex-start",
                display: 'flex',
                ml: '10px'
              }}>
                <Typography
                  style={{ textDecoration: "none", fontFamily: 'poppins-mid' }}
                  variant="h5"
                  color={"#ff8800"}
                >
                  {Chirp.From.full_name}
                </Typography>

                <Typography style={{
                  fontFamily: 'poppins-reg',
                  fontSize: "12px",
                  overflow: 'hidden',
                  color: '#ffffff90',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: "110px"
                }}>{Chirp.From.buzz_name}</Typography>

              </Box>
            </Box>
            <Box sx={{
              width: "25px", height: "25px", backgroundColor: "#1d1d1d", borderRadius: '50px',
              justifyContent: 'center', marginX: '50px', display: 'flex', alignItems: 'center',
            }}>
              <SendRounded style={{ color: "#ffffff", fontSize: "15px" }} />
            </Box>
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent="center"
              component={Link}
              sx={{ textDecoration: 'none' }}
              to={{
                pathname: "/admin/particularuserdetails",
                search: `?userid=${Chirp.To._id}`,
                state: { fromDashboard: true },
                // console.log(first)
              }}
            >
              <img
                alt="profile-user"
                width="70px"
                height="70px"
                src={`${Chirp.To.image[0]}`}
                style={{ cursor: "pointer", borderRadius: "50%", border: '5px solid #ffffff10', objectFit: 'cover' }}
              />
              <Box sx={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: "flex-start",
                display: 'flex',
                ml: '10px'
              }}>
                <Typography
                  style={{ textDecoration: "none", fontFamily: 'poppins-mid' }}
                  variant="h5"
                  color={"#ffffff"}
                >
                  {Chirp.To.full_name}
                </Typography>

                <Typography style={{
                  fontFamily: 'poppins-reg',
                  fontSize: "12px",
                  overflow: 'hidden',
                  color: '#ffffff90',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: "110px"
                }}>{Chirp.To.buzz_name}</Typography>

              </Box>

            </Box>
          </Box>
        </Box>
        <Box
          mt={"20px"}
          display="flex"
          alignItems={"center"}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '100%', alignItems: "center" }}>
            <Chip
              style={{ fontSize: "12px", borderRadius: '5px', backgroundColor: "#ffffff10", borderColor: "#ffffff10", paddingLeft: '3px', paddingRight: '3px' }}
              avatar={<Avatar style={{ width: "18px", height: "18px", marginRight: "2px" }} alt="Natacha" src={`${Chirp.From.image[0]}`} />}
              label={`${Chirp.Chirp.Chirp}`}
              variant="outlined"
              sx={{ fontFamily: 'poppins-reg' }}
            />

            {
              Chirp.Chirp.ReactionReceived != "" && (
                <Typography style={{
                  borderRadius: "50px",
                  fontFamily: 'poppins-reg',
                  fontSize: "10px",
                  width: "19px",
                  height: "19px",
                  textAlign: 'center',
                  marginLeft: "10px",
                  verticalAlign: 'middle',
                  background: "#ffffff20",
                  border: "1px solid #ffffff20",
                  borderRadius: "50%",
                  color: "#ffffff"
                }} >{Chirp.Chirp.ReactionReceived}</Typography>
              )
            }
          </Box>

        </Box>
        <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "10px" }}>
          {
            Chirp.Unmasked && (
              <Typography style={{
                fontFamily: 'poppins-reg', fontSize: "10px",
                wordBreak: 'break-word',
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "3px",
                backgroundColor: "#ff880010",
                paddingLeft: "8px",
                paddingRight: "8px",
                marginRight: "5px",

                color: '#ff8800'
              }}>
                Unmasked
              </Typography>
            )
          }

          {
            Chirp.Reported && (
              <Typography style={{
                fontFamily: 'poppins-reg', fontSize: "10px",
                wordBreak: 'break-word',
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "3px",
                border: '1px solid #ff200060',
                backgroundColor: "#ff200010",
                paddingLeft: "8px",
                paddingRight: "8px",
                color: '#ff2000'
              }}>
                Reported
              </Typography>

            )
          }
        </Box>

        <Divider sx={{ marginTop: "20px" }} light />

        <Typography style={{
          fontFamily: 'poppins-reg',
          color: '#ffffff90',
          marginBottom: "10px",
          fontSize: '12px',
          marginTop: "15px",
          marginLeft: '2px'
        }} >
          Comments ({Chirp.Comments.length})</Typography>

        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"start"}
          pb={"100px"}
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent="left"
            sx={
              {
                //   backgroundColor: colors.grey[500],
                //   borderRadius: "50%",
              }
            }
          >
            {Chirp.Comments.map((comment) => {
              return (
                <Box
                  display="flex"
                  my={1}
                  //   flexDirection={"column"}
                  sx={
                    {
                      // backgroundColor: colors.grey[500],
                    }
                  }
                  justifyContent="center"
                >

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '100%', alignItems: "center" }}>

                      <img
                        src={`${comment.SendBy.image[0]}`}
                        alt="profile-user"
                        width="18px"
                        height="18px"
                        style={{ cursor: "pointer", borderRadius: "50%", border: "3px solid #aaaaaa20" }}
                      />
                      <Typography style={{ fontFamily: 'poppins-reg', fontSize: "10px", color: (comment.SendBy.full_name == sender) ? "#ff8800" : "#ffffff", marginLeft: "3px" }}>{comment.SendBy.full_name}</Typography>
                      <Typography style={{ fontFamily: 'poppins-reg', fontSize: "10px", color: "#ffffff60", marginLeft: "10px" }}>{format(
                        new Date(comment.createdAt),
                        "MMMM dd, yyyy hh:mma"
                      )}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '100%', alignItems: "center", marginTop: "5px" }}>
                      <Typography
                        sx={{
                          mt: "2px",
                          maxWidth: "600px",
                          fontFamily: 'poppins-reg',
                          fontSize: '13px',
                          color: colors.grey[200],
                          borderRadius: "3px",
                          background: "#ffffff10",
                          px: "6px",
                          py: "4px"
                        }}
                      >
                        {comment.Comment}
                      </Typography>
                      {
                        comment.ReactionReceived != "" && (
                          <Typography style={{
                            borderRadius: "50px",
                            fontFamily: 'poppins-reg',
                            fontSize: "10px",
                            width: "19px",
                            height: "19px",
                            textAlign: 'center',
                            marginLeft: "10px",
                            verticalAlign: 'middle',
                            background: "#ffffff20",
                            border: "1px solid #ffffff20",
                            borderRadius: "50%",
                            color: "#ffffff"
                          }} >{comment.ReactionReceived}</Typography>
                        )
                      }

                    </Box>
                  </Box>

                </Box>
              );
            })}
          </Box>

          {/* <img src="" alt="" /> */}
        </Box>
      </Box>
    </>
  );
};

export default IndividualChirps;
