import React from "react";
import {
  CropOriginalOutlined,
  DeleteOutline,
  ExpandMoreOutlined,
  Save,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Header } from "../../global/Header";
import { format } from "date-fns";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../Theme/theme";
import FirstRow from "./FirstRow";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import SecondRow from "./SecondRow";
import ThirdRow from "./ThirdRow";
import FourthRow from "./FourthRow";
import LogoChange from "./LogoChange";
import FifthRow from "./FifthRow";
import AddNewImagesApi from "./AddNewImagesApi";
import SnackbarComponent from "./SnackbarComponent";
import BASE_URL from "../../../NetworkConfig/ApiConfig";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const AddNewImages = () => {
  let query = useQuery();
  const [Images, setImages] = React.useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setseverity] = useState("success");
  const [AlertMessage, setAlertMessage] = useState("");
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const preset_key = "marketing_admin";
  const cloud_name = "dx8qieggt";
  console.log(query.get("location"));
  const [buttonloading, setbuttonloading] = useState(false);
  const [IndividualEnabledLocationState, setIndividualEnabledLocationState] =
    useState(null);
  const [error, seterror] = useState("Loading......");
  const deleteFile = (imagename) => {
    setImages((current) => current.filter((im) => im.name !== imagename));
  };
  const FetchEnabledLocationDetails = async () => {
    // setloading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${BASE_URL}/enabledlocation/fetchparticularmain/${query.get("location")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIndividualEnabledLocationState(data.response.Details);
      //   setUsers(data.response.TotalUserOnLocation);
      console.log(data);
      console.log(IndividualEnabledLocationState);
      console.log(data.response.TotalUserOnLocation);
      // IndividualEnabledLocationDetails.TotalUserOnLocation;
      // setloading(false);
      return;
    }
    if (data.success === false) {
      seterror(data.response);
    }
  };
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const handleImageUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);

    const uploadedImages = uploadedFiles.map((file) => file);

    setImages((prevImages) => [...prevImages, ...uploadedImages]);
    // if (uploadedFiles.length > 0) {
    //   handleLogochange(Images[0]);
    // }
    console.log(Images);
  };
  useEffect(() => {
    FetchEnabledLocationDetails();
  }, []);
  if (!IndividualEnabledLocationState) {
    //   console.log(IndividualEnabledLocationDetails["TotalUsersCountonLocation"]);
    return <Box>{error}</Box>;
  }
  const uploadAllNewImages = async () => {
    try {
      setbuttonloading(true);
      const uploadPromises = Images.map(async (file) => {
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", preset_key);
        data.append("cloud_name", cloud_name);
        data.append("folder", "locations");

        return fetch(
          `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
          {
            method: "POST",
            body: data,
          }
        )
          .then((uploadImageToCloudinary) => uploadImageToCloudinary.json())
          .then((uploadResponse) => uploadResponse.secure_url)
          .catch((error) => {
            setbuttonloading(false);
            console.error(error);
            throw error;
          });
      });
      const uploadedImages = await Promise.all(uploadPromises);
      const response = await AddNewImagesApi(
        IndividualEnabledLocationState._id,
        localStorage.getItem("token"),
        { newimages: uploadedImages }
      );
      if (response.success === true) {
        setbuttonloading(false);

        //   setbuttonloading(false);
        setseverity("success");
        setAlertMessage(response.response);
        setShowSnackbar(true);
      }
      if (response.success === false) {
        setbuttonloading(false);

        //   setbuttonloading(false);
        setseverity("error");
        setAlertMessage(response.response);
        setShowSnackbar(true);
      }
    } catch (error) {}
  };
  return (
    <Box m={"20px"}>
      <Box display={"flex"} justifyContent="space-between">
        <Header
          title={IndividualEnabledLocationState.LocationName}
          subtitle={`This Location Was Last Updated on:- ${format(
            new Date(IndividualEnabledLocationState.updatedAt),
            "MM/dd/yyyy"
          )}`}
        />
      </Box>
      <Box mb={2} display={"flex"} justifyContent="center">
        <Box display={"flex"} justifyContent="center">
          <Button
            startIcon={<CropOriginalOutlined />}
            style={{
              borderRadius: 12,
              backgroundColor: colors.greenAccent[500],
              // padding: "18px 36px",
              fontSize: "18px",
              height: "34px",
            }}
            variant="contained"
            component="label"
          >
            Upload Images
            <input
              type="file"
              id="files"
              name="files"
              multiple
              hidden
              onChange={(e) => handleImageUpload(e)}
            />
          </Button>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent="space-evenly">
        {Images.length > 0 &&
          Images.map((item, index) => {
            return (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                key={index}
                mb={2}
              >
                <img
                  width="100px"
                  height="100px"
                  style={{ cursor: "pointer" }}
                  src={URL.createObjectURL(item)}
                  alt=""
                />
                <Button
                  startIcon={<DeleteOutline />}
                  style={{
                    borderRadius: 12,
                    backgroundColor: colors.redAccent[500],
                    // padding: "18px 36px",
                    fontSize: "18px",
                    marginTop: "10px",
                    height: "34px",
                  }}
                  variant="contained"
                  type="button"
                  onClick={() => deleteFile(item.name)}
                >
                  delete
                </Button>
              </Box>
            );
          })}
      </Box>
      {Images.length > 0 && (
        <Box pb={"10px"} display={"flex"} justifyContent={"center"}>
          {/* <img src="" alt="" /> */}
          <Button
            disabled={buttonloading}
            startIcon={<Save />}
            style={{
              borderRadius: 12,
              backgroundColor: colors.greenAccent[500],
              // padding: "18px 36px",
              fontSize: "18px",
              height: "34px",
              marginBottom: "2px",
            }}
            variant="contained"
            component="label"
            onClick={uploadAllNewImages}
            //   disabled={false}
          >
            Save
            {/* <input hidden accept="image/*" multiple type="file" /> */}
          </Button>
        </Box>
      )}
      {showSnackbar && (
        <SnackbarComponent
          open={true}
          severity={severity}
          AlertMessage={AlertMessage}
          onClose={handleSnackbarClose}
        />
      )}
    </Box>
  );
};

export default AddNewImages;
